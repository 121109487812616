import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
// import Button from "../../Components/Utils/Button";
// import Input from "../../Components/Utils/Input";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import Select from "../../Components/Utils/Select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../Http/BaseApi";
// import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import Input from "../../Components/Utils/Input";

export default function ProposalReport() {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [message, setMessage] = useState();

  // const { message } = useSelector((state) => state.message);
  //   const navigate = useNavigate();

  // let formSections = useSelector((state) => state.applicants.formSections);

  const initialValues = {
    from_date: null,
    to_date: null,
  };
  const validationSchema = Yup.object().shape({
    from_date: Yup.string().nullable().required("This field is required!"),
    to_date: Yup.string().nullable().required("This field is required!"),
  });

  const handleSearch = async (searchQuery) => {
    setLoading(true);
    console.log(searchQuery);
    await genReport(searchQuery);
    setLoading(false);
  };

  //   const dispatch = useDispatch();
  useEffect(() => {
    // if (academicSessions.length === 0) {
    //   dispatch(getAcademicSessionsAsync());
    // }
    getReports();
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  async function getReports() {
    setReports([]);
    setLoading(true);

    const response = await BaseApi().get(`reports/proposals`);
    setReports(response.data);
    setLoading(false);
  }
  async function genReport(searchQuery) {
    const response = await BaseApi().post(`reports/proposals`, searchQuery);
    setMessage(response.data);
  }

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5 overflow-x-scroll '>
            <PageTitle name='Proposal Reports' />
            <div className=''>{message && <Alert message={message} />}</div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
            >
              <Form>
                <div className=' p-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6 pt-6 gap-2'>
                  <Input
                    type='date'
                    name='from_date'
                    label='Submitted Date From *'
                  />
                  <Input
                    type='date'
                    name='to_date'
                    label='Submitted Date To *'
                  />
                  <div className='text-left mt-6  '>
                    <button
                      type='submit'
                      className='group  relative w-50  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      disabled={loading}
                    >
                      <span>Generate</span>
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>

            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            <table
              id='datatable'
              className='min-w-full mt-5 bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className='table-head'>#</th>

                  <th className='table-head'>File Name</th>
                  <th className='table-head'>Generated At</th>
                  <th className='table-head'>Action</th>
                </tr>
              </thead>
              <tbody>
                {reports?.length > 0 &&
                  reports.map((report, index) => {
                    return (
                      <>
                        <tr>
                          <td className='table-data'>{++index}</td>
                          <td className='table-data'>{report.name}</td>
                          <td className='table-data'>{report.time}</td>
                          <td className='table-data'>
                            <a className=' text-blue-600' href={report.link}>
                              Download
                            </a>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
            {reports?.length == 0 && (
              <div className='text-center my-10'>No Data</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
