import React from "react";
import { Link } from "react-router-dom";
import * as HIcons from "@heroicons/react/outline";

function SideBarLink(props) {
  const { ...icons } = HIcons;
  const TheIcon = icons[props.icon];

  // const SvgIcon = (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="24"
  //     height="24"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     stroke="#000000"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   >
  //     <path d="M9 18l6-6-6-6" />
  //   </svg>
  // );
  return (
    <Link
      to={props.to}
      className="flex items-center text-sm py-4 px-2 h-12 overflow-hidden
       text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-white
       hover:bg-gradient-to-r from-pink-500 to-purple-200 transition duration-300 ease-in-out"
      href="#!"
      data-mdb-ripple="true"
      data-mdb-ripple-color="primary"
    >
      <TheIcon className="h-4 w-4 mr-1" aria-hidden="true" />
      <span>{props.label}</span>
    </Link>
  );
}

export default SideBarLink;
