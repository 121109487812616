import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { PaperClipIcon } from "@heroicons/react/outline";
import BaseApi from "../../Http/BaseApi";
import moment from "moment";

export default function BudgetReappropriationIndex() {
  // console.log(proposalNature);
  const [errorMessage, setErrorMessage] = useState(false);
  const [budgetReappropriations, setBudgetReappropriations] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  useEffect(() => {
    if (budgetReappropriations.length == 0) {
      getBudgetReappropriations();
    }
  }, [dispatch]);

  const getBudgetReappropriations = async () => {
    setLoading(true);
    BaseApi()
      .get(`/acc-budget-reappropriations`)
      .then((response) => {
        console.log(response.data);
        setBudgetReappropriations(response.data);

        setLoading(false);
      })
      .catch((error) => {
        console.log("error is", error);
        setLoading(false);
      });
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;

    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`acc-budget-reappropriations/${payload.id}`, {})
        .then(() => {
          let heads = budgetReappropriations.filter((p) => p.id != payload.id);
          console.log(heads);
          setBudgetReappropriations(heads);
          // getBudgetBlocks();
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  // console.log(restructureArray(accPaymentHeads));
  return (
    <>
      <div className='container mx-auto'>
        <h1 className='mb-4 text-2xl font-bold'>
          Budget Reappropriation History
        </h1>
        {loading == true && (
          <>
            <div className='flex'>
              <span className='m-auto'>
                <ThreeDots
                  height='100'
                  width='100'
                  color='grey'
                  ariaLabel='loading'
                />
              </span>
            </div>
          </>
        )}

        {errorMessage && <p className='mb-4 text-red-500'>{errorMessage}</p>}

        <table id='datatable' className='min-w-full bg-white dark:bg-gray-800'>
          <thead>
            <tr>
              <th className={thClass + "w-16"}>#</th>

              <th className={thClass}>Budget Master</th>
              <th className={thClass}>From Cost Center</th>
              <th className={thClass}>From Head</th>
              <th className={thClass}>To Cost Center</th>
              <th className={thClass}>To Head</th>
              <th className={thClass}>Amount</th>
              <th className={thClass}>Resolution No.</th>
              <th className={thClass}>Meeting Date</th>
              <th className={thClass}>Meeting No.</th>
              <th className={thClass}>Document</th>

              <th className={thClass}>Action</th>
            </tr>
          </thead>
          <tbody>
            {budgetReappropriations.length > 0 &&
              budgetReappropriations.map((budget, i) => {
                return (
                  <tr key={budget.id} className='odd:bg-white even:bg-gray-50'>
                    <td className={tdClass + "w-32"}>{++i}</td>

                    <td className={tdClass}>
                      {budget.acc_budget_master?.name} (
                      {budget.academic_session?.label})
                    </td>
                    <td className={tdClass}>
                      {budget.from_acc_cost_center?.name}
                    </td>
                    <td className={tdClass}>
                      {budget.from_head?.code}-{budget.from_head?.name}
                    </td>
                    <td className={tdClass}>
                      {budget.to_acc_cost_center?.name}
                    </td>
                    <td className={tdClass}>
                      {budget.to_head?.code}-{budget.to_head?.name}
                    </td>

                    <td className={tdClass}>{budget.amount}</td>
                    <td className={tdClass}>{budget.resolution_no}</td>
                    <td className={tdClass}>
                      {moment(budget.meeting_date).format("DD/MM/Y")}
                    </td>
                    <td className={tdClass}>{budget.meeting_no}</td>
                    <td className={tdClass}>
                      {budget.file && (
                        <a
                          className='text-blue-700'
                          target='_blank'
                          href={budget.file_path}
                          rel='noreferrer'
                        >
                          <PaperClipIcon className='inline-block w-4' /> View
                          Document
                        </a>
                      )}
                    </td>

                    <td className={tdClass}>
                      {(deleteConfirm.state == 0 ||
                        deleteConfirm.itemId != budget.id) && (
                        <span
                          className='px-2 py-1 ml-5 font-light text-white rounded bg-gradient-to-r from-red-500 to-yellow-500 hover:bg-red-700'
                          onClick={() =>
                            setDeleteConfirm({
                              itemId: budget.id,
                              state: 1,
                            })
                          }
                        >
                          Delete
                        </span>
                      )}
                      {deleteConfirm.state == 1 &&
                        deleteConfirm.itemId == budget.id && (
                          <>
                            <p className='text-red-700'> Are You Sure? </p>

                            <button
                              className='outline-button-danger'
                              onClick={(event) => handleDelete(event, budget)}
                            >
                              Yes
                            </button>
                            <span className='mr-2'></span>
                            <button
                              className='outline-button-success'
                              onClick={() => setDeleteConfirm({})}
                            >
                              No
                            </button>
                          </>
                        )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
