import React, { useEffect, useState } from "react";
import BillIndex from "./BillIndex";
import CommittedExpendature from "./CommittedExpendatureIndex";
import PendingProposal from "./PendingProposalIndex.js";
import OrderIndex from "./OrderIndex";
import can from "../../../Middleware/Permission";

const ProposalIndex = ({ pageIndex }) => {
  if (can("Can Audit")) {
    pageIndex = 4;
  }
  if (can("Add Bills")) {
    pageIndex = 3;
  }
  const [page, setPage] = useState(pageIndex ?? 1);
  useEffect(() => {
    console.log(pageIndex);
  }, [pageIndex]);
  return (
    <>
      <div className='py-2'>
        <div className='bg-white shadow-md'>
          <nav className='flex justify-between'>
            <div className='flex'>
              {!can("Can Audit") && !can("Add Bills") && (
                <>
                  {" "}
                  <a
                    href='#'
                    onClick={() => {
                      setPage(1);
                    }}
                    className={`py-4 px-6 text-lg font-semibold text-white
                 hover:bg-green-500 hover:text-white focus:bg-green-500 focus:text-white
                 
                 ${page == 1 ? "bg-green-500 text-white" : "text-gray-800"}
                `}
                  >
                    Submitted Proposals
                  </a>
                  <a
                    href='#'
                    onClick={() => {
                      setPage(2);
                    }}
                    className={`py-4 px-6 text-lg font-semibold text-white hover:bg-yellow-500 hover:text-white focus:bg-yellow-500 focus:text-white
                ${page == 2 ? "bg-yellow-500 text-white" : "text-gray-800"}
                `}
                  >
                    Committed Expenditure
                  </a>
                </>
              )}
              <a
                href='#'
                onClick={() => {
                  setPage(3);
                }}
                className={`py-4 px-6 text-lg font-semibold text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white
                ${page == 3 ? "bg-blue-500 text-white" : "text-gray-800"}
                `}
              >
                Generated Orders
              </a>
              <a
                href='#'
                onClick={() => {
                  setPage(4);
                }}
                className={`py-4 px-6 text-lg font-semibold text-white hover:bg-purple-500 hover:text-white focus:bg-purple-500 focus:text-white
                ${page == 4 ? "bg-purple-500 text-white" : "text-gray-800"}
                `}
              >
                Bills
              </a>
            </div>
          </nav>
        </div>

        {page == 1 && (
          <PendingProposal
            tabChange={(pageNo) => {
              setPage(pageNo);
            }}
          />
        )}
        {page == 2 && (
          <CommittedExpendature
            tabChange={(pageNo) => {
              setPage(pageNo);
            }}
          />
        )}
        {page == 3 && (
          <OrderIndex
            tabChange={(pageNo) => {
              setPage(pageNo);
            }}
          />
        )}
        {page == 4 && (
          <BillIndex
            tabChange={(pageNo) => {
              setPage(pageNo);
            }}
          />
        )}
      </div>
    </>
  );
};

export default ProposalIndex;
