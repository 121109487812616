// import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
// import Alert from "../Utils/Alert";
import Header from "./Header";
import SideBar from "./SideBar";

const Layout = () => {
  // const { message } = useSelector((state) => state.message);

  return (
    <>
      <div className="min-h-ful">
        <Header />

        <main className="flex w-full h-screen">
          <SideBar />
          <section className="w-full p-2">
            {/* {message && <Alert message={message} />} */}
            <Outlet />
          </section>
        </main>
      </div>
    </>
  );
};

export default Layout;
