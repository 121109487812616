import { SearchIcon } from "@heroicons/react/outline";
import React from "react";

export default function SearchButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className='bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600'
    >
      <SearchIcon className='w-6' />
    </button>
  );
}
