import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import BaseApi from "../../../../Http/BaseApi";
import Input from "../../../../Components/Utils/Input";
import SearchableDropdownVendor from "../../../../Components/Utils/SearchableDropdownVendor";
import { PlusIcon } from "@heroicons/react/outline";
import VendorEntry from "../../Vendor/VendorEntry";
import Upload from "../../../../Components/Utils/Upload";
import ValidationErrors from "../../../../Components/Utils/ValidationErrors";
export default function GenerateOrder({
  selectedProposal,

  tabChange,
  handlePopupClose,
}) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const inputRefVendor = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [vendorPopup, setVendorPopup] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [deliveryDateType, setDeliveryDateType] = useState();
  const [deliveryDays, setDeliveryDays] = useState();
  const validationSchema = Yup.object().shape({
    manual_order_no: Yup.string().required("This field is required!"),
    vendor_id: Yup.string().required("This field is required!"),
    delivery_date: Yup.string().required("This field is required!"),
    order_date: Yup.string().required("This field is required!"),
    // order_file: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    setInitialValues({
      order_date: "",
      manual_order_no: "",
      vendor_id: "",
      delivery_date: "",
      order_file: "",

      proposal_id: selectedProposal?.id,
    });
  }, [dispatch]);

  const handleSubmit = async (payload) => {
    var form_data = new FormData();

    for (var key in payload) {
      console.log(payload[key]);
      form_data.append(key, payload[key]);
    }
    form_data.append("proposal_id", selectedProposal.id);
    BaseApi()
      .post(`/generate-order`, form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        tabChange(3);
        // BaseApi()
        //   .get(`/proposals?status=1`)
        //   .then(() => {
        //     // console.log('data is', response.data)
        //   })
        //   .catch((error) => {
        //     console.log("error is", error);
        //   });
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        setValidationErrors(error.response.data.errors);
      });
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div
            className={
              "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none" +
              (vendorPopup ? " hidden" : "")
            }
          >
            <div className='relative lg:w-2/6	 my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>
                    Generate Work Order{" "}
                  </h3>
                  <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative   p-4 flex-auto'>
                  <div className='my-1    text-sm '>
                    <div className='relative p-2 flex-auto'>
                      {errorMessage && (
                        <p className='text-red-500 mb-4'>{errorMessage}</p>
                      )}
                      <ValidationErrors
                        className='my-5'
                        errors={validationErrors}
                      />
                      <div>Proposal ID: {selectedProposal.proposal_id}</div>
                      <div>
                        Cost Center: {selectedProposal.acc_cost_center?.name}
                      </div>
                      <br />
                      <Input type='hidden' name='proposal_id' />
                      <div className='float-right '>
                        <button
                          className='flex items-center text-blue-500'
                          type='button'
                          onClick={() => {
                            setVendorPopup(true);
                          }}
                        >
                          Create new vendor <PlusIcon className='w-4 ml-1' />
                        </button>
                      </div>
                      {/* <CustomSelect
                        options={vendors}
                        name='vendor_id'
                        label='Select Vendor'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option?.name}`}
                        onChange={async (selectedOption) => {
                          // console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "vendor_id",
                            selectedOption.id
                          );
                        }}
                      /> */}
                      <SearchableDropdownVendor
                        reference={inputRefVendor}
                        name={`item_id`}
                        label='Select Vendor'
                        placeholder='Search...'
                        options={[]}
                        setSelectedOption={(selectedOption) => {
                          formikRef.current?.setFieldValue(
                            "vendor_id",
                            selectedOption.id
                          );
                        }}
                      />
                      <br />
                      <Input
                        label='Order Date'
                        type='date'
                        name='order_date'
                        placeholder='Order Date'
                      />{" "}
                      <br />
                      <Input
                        label='Enter Manual Order Number'
                        type='text'
                        name='manual_order_no'
                        placeholder='Enter Manual Order Number'
                      />{" "}
                      <br />
                      <label className='block text-sm font-medium text-gray-700'>
                        Delivery Deadline Type
                      </label>
                      <select
                        onChange={(e) => setDeliveryDateType(e.target.value)}
                        className={`rounded-none mt-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                      >
                        <option value=''>Select</option>
                        <option value='1'>In Date</option>
                        <option value='2'>In Days</option>
                      </select>
                      <br />
                      {deliveryDateType == 2 && (
                        <>
                          <label className='block text-sm font-medium text-gray-700'>
                            Enter Days
                          </label>
                          <input
                            onChange={(e) => {
                              setDeliveryDays(e.target.value);
                              formikRef.current?.setFieldValue(
                                "delivery_date",
                                new Date(
                                  new Date(
                                    formikRef.current?.values?.order_date
                                  ).getTime() +
                                    e.target.value * 24 * 60 * 60 * 1000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              );
                              // console.log(new Date(new Date().getTime()+e.target.value*24*60*60*1000)."
                            }}
                            className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm `}
                            type='number'
                            name='delivery_days'
                            placeholder='Enter Delivery in Days'
                          />
                        </>
                      )}
                      {(deliveryDateType == 1 ||
                        (deliveryDateType == 2 && deliveryDays > 0)) && (
                        <Input
                          label='Enter Delivery Date'
                          type='date'
                          name='delivery_date'
                          placeholder='Enter Delivery Date'
                        />
                      )}
                      <Upload
                        className='col-span-3'
                        label='Order Copy'
                        type='file'
                        name='order_file'
                        onChange={(event) => {
                          console.log("asd");
                          let myFile = event.currentTarget.files[0];
                          formikRef.current.setFieldValue("order_file", myFile);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handlePopupClose}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className='opacity-25 fixed inset-0 z-30 bg-black'></div>

      {vendorPopup && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative  lg:w-2/3	 my-6 mx-auto max-w-3xl'>
              {/*content*/}

              <div className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <button
                  className='absolute top-0 right-0 m-4'
                  onClick={() => {
                    setVendorPopup(false);
                  }}
                >
                  X
                </button>
                <VendorEntry setVendorPopup={setVendorPopup} />
              </div>
            </div>
          </div>

          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </>
  );
}
