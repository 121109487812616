import React, { useEffect, useState } from "react";

export default function PayrollPortal() {
  const [urlParams, setUrlParams] = useState({});
  const openPayrollPortal = () => {
    //  form submit to a url
    const user = JSON.parse(localStorage.getItem("userData"));
    // console.log(user);
    const token = user?.token;
    const staff_id = user?.user?.id;

    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", "https://payroll.dibru.work/loginById");
    // form.setAttribute("target", "_blank");
    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "token");
    hiddenField.setAttribute("value", token);
    // add another hidden field staff_id
    var hiddenField2 = document.createElement("input");
    hiddenField2.setAttribute("type", "hidden");
    hiddenField2.setAttribute("name", "staff_id");
    hiddenField2.setAttribute("value", staff_id);
    form.appendChild(hiddenField);
    form.appendChild(hiddenField2);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };
  useEffect(() => {
    // get query params from url
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const error = params.get("error");
    setUrlParams({ error });
  }, []);

  return (
    <div className='m-10 text-center'>
      <p className='p-10 m-10 text-red-600'>{urlParams?.error}</p>
      <button
        onClick={openPayrollPortal}
        className='px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700'
      >
        Open Payroll Portal
      </button>
    </div>
  );
}
