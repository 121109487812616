import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "../Middleware/ProtectedRoute";
import BudgetBlock from "../Pages/Accounts/BudgetBlock/BudgetBlock";
import AssignBudgetHeads from "../Pages/Accounts/BudgetHead/AssignBudgetHeads";
import BudgetHead from "../Pages/Accounts/BudgetHead/BudgetHead";
import BudgetMaster from "../Pages/Accounts/BudgetMaster/BudgetMaster";
import CostCenter from "../Pages/Accounts/CostCenter/CostCenter";
import ItemEntry from "../Pages/Accounts/Item/ItemEntry";
import MeasurementUnit from "../Pages/Accounts/MeasurementUnit/MeasurementUnit";
import CheckItemDelivery from "../Pages/Accounts/Proposal/CheckItemDelivery";
import ProposalEntry from "../Pages/Accounts/Proposal/ProposalEntry";
import ProposalIndex from "../Pages/Accounts/Proposal/ProposalIndex";
import ProposalView from "../Pages/Accounts/Proposal/ProposalView";
import StockRegister from "../Pages/Accounts/Proposal/StockRegister";
import StockRegisterView from "../Pages/Accounts/Proposal/StockRegisterView";
import VendorEntry from "../Pages/Accounts/Vendor/VendorEntry";
import StaffChangePassword from "../Pages/Auth/Staff/ChangePassword";
import BudgetProposalEntry from "../Pages/Budgets/BudgetProposalEntry";
import BudgetProposals from "../Pages/Budgets/BudgetProposals";
import BudgetProposalsHeadWise from "../Pages/Budgets/BudgetProposalsHeadWise";
import BudgetProposalSubmit from "../Pages/Budgets/BudgetProposalSubmit";
import BudgetSummary from "../Pages/Budgets/BudgetSummary";
import BudgetByCenter from "../Pages/Budgets/PrepareBudget/BudgetByCenter";
import BudgetCostCenterView from "../Pages/Budgets/PrepareBudget/BudgetCostCenterView";
import BudgetEdit from "../Pages/Budgets/PrepareBudget/BudgetEdit";
import BudgetProposalByCenter from "../Pages/Budgets/PrepareBudget/BudgetProposalByCenter";
import BudgetSummaryPrepared from "../Pages/Budgets/PrepareBudget/BudgetSummaryPrepared";
import PreparedBudgets from "../Pages/Budgets/PrepareBudget/PreparedBudgets";
import PreparedBudgetHeadWise from "../Pages/Budgets/PrepareBudget/PreparedBudgetsHeadWise";
import StaffDashboard from "../Pages/Dashboard/StaffDashboard";
import ProposalReport from "../Pages/Reports/ProposalReport";
import FundPositionReport from "../Pages/Reports/FundPositionReport";
import BudgetReappropriation from "../Pages/Budgets/BudgetReappropriation.js";
import BudgetReappropriationIndex from "../Pages/Budgets/BudgetReappropriationIndex.js";
import VoucherPortal from "../Pages/Accounts/VoucherPortal.js";
import PayrollPortal from "../Pages/Accounts/PayrollPortal.js";

export default [
  <Route key='staff' element={<ProtectedRoute />}>
    <Route path='dashboard' element={<StaffDashboard />} />
    <Route
      path='budget-proposal-entry/:type'
      element={<BudgetProposalEntry />}
    />{" "}
    <Route path='budget-proposal-submit' element={<BudgetProposalSubmit />} />
    <Route path='budget-heads' element={<BudgetHead />} />
    <Route path='cost-centers' element={<CostCenter />} />
    <Route path='budget-master' element={<BudgetMaster />} />
    <Route path='assign-budget-heads/:type' element={<AssignBudgetHeads />} />
    <Route path='budget-summary' element={<BudgetSummary />} />
    <Route
      path='budget-proposals/:type/:acc_budget_form_id'
      element={<BudgetProposals />}
    />
    <Route
      path='budget-proposals/:type/:acc_budget_form_id/:head_id'
      element={<BudgetProposalsHeadWise />}
    />
    <Route
      path='budget-edit/:type/:costCenter_id/:accBudgetFormId'
      element={<BudgetEdit />}
    />{" "}
    <Route
      path='budget-proposal-submit/:costCenter_id'
      element={<BudgetProposalSubmit />}
    />
    <Route path='prepare-budget' element={<BudgetCostCenterView />} />
    <Route
      path='budget-reappropriations'
      element={<BudgetReappropriationIndex />}
    />
    <Route
      path='budget-reappropriations/create'
      element={<BudgetReappropriation />}
    />
    <Route
      path='budget-proposal-by-center/:costCenter_id/:accBudgetFormId'
      element={<BudgetProposalByCenter />}
    />
    <Route
      path='budget-by-center/:costCenter_id/:accBudgetFormId'
      element={<BudgetByCenter />}
    />
    <Route path='budget-summary-prepared' element={<BudgetSummaryPrepared />} />
    <Route
      path='prepared-budgets/:type/:acc_budget_form_id'
      element={<PreparedBudgets />}
    />
    <Route
      path='prepared-budgets/:type/:acc_budget_form_id/:head_id'
      element={<PreparedBudgetHeadWise />}
    />
    <Route path='fund-position-report' element={<FundPositionReport />} />
    {/* Proposals */}
    <Route path='proposals' element={<ProposalIndex />} />
    <Route path='proposals/:id' element={<ProposalView />} />
    <Route path='proposals/create' element={<ProposalEntry />} />
    <Route path='proposal-report' element={<ProposalReport />} />
    <Route path='check-delivery' element={<CheckItemDelivery />} />
    <Route path='check-delivery/:id' element={<CheckItemDelivery />} />
    <Route path='stock-registers' element={<StockRegister />} />
    <Route path='stock-registers/:id' element={<StockRegisterView />} />
    <Route path='change-password' element={<StaffChangePassword />} />
    {/* masters */}
    <Route path='measurement-units' element={<MeasurementUnit />} />
    <Route path='items/create' element={<ItemEntry />} />
    <Route path='vendors/create' element={<VendorEntry />} />
    <Route path='budget-blocks' element={<BudgetBlock />} />
    <Route path='voucher-portal' element={<VoucherPortal />} />
    <Route path='payroll-portal' element={<PayrollPortal />} />
  </Route>,
];
