import React from "react";
import { Route } from "react-router-dom";
import ProtectedRoute from "../Middleware/ProtectedRoute";
import DepartmentChangePassword from "../Pages/Auth/CostCenter/ChangePassword";
import DepartmentDashboard from "../Pages/Dashboard/CostCenterDashboard";

export default [
  <Route key="department" element={<ProtectedRoute />}>
    <Route path="dashboard" element={<DepartmentDashboard />} />

    <Route path="change-password" element={<DepartmentChangePassword />} />
  </Route>,
];
