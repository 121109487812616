import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";

import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import {
  getBudgetMastersAsync,
  getBudgetSuperMastersAsync,
  getCostCentersAsync,
} from "../../Redux/BudgetSlice";
// import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import Input from "../../Components/Utils/Input";
import CustomSelect from "../../Components/Utils/CustomSelect";
import BaseApi from "../../Http/BaseApi";
import Upload from "../../Components/Utils/Upload";

// import Input from "../../Components/Utils/Input";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";

export default function BudgetReappropriation() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [openBudgets, setOpenBudgets] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);
  const academicSessions = [...master.academicSessions];
  const costCenters = [...budget.costCenters];

  const budgetMasters = [...budget.budgetMasters];
  const budgetSuperMasters = [...budget.budgetSuperMasters];

  const [accFromBudgets, setAccFromBudgets] = useState([]);
  const [accToBudgets, setAccToBudgets] = useState([]);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    getOpenBudgets();
    if (costCenters.length == 0) {
      dispatch(getCostCentersAsync());
    }

    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }

    if (budgetMasters.length === 0) {
      dispatch(getBudgetMastersAsync());
    }

    setInitialValues({
      acc_budget_form_id: "",
      acc_budget_master_id: "",
      academic_session_id: "",
      entries: [
        // Array to hold multiple rows
        {
          from_acc_cost_center_id: "",
          from_head_id: "",
          to_acc_cost_center_id: "",
          to_head_id: "",
          amount: "",
        },
      ],
      resolution_no: "",
      meeting_date: "",
      meeting_no: "",
      file: "",
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  // Function to toggle preview
  const handlePreview = () => {
    setIsPreview(!isPreview);
  };

  const addNewRow = () => {
    if (formikRef.current) {
      // Get the current values from the Formik form
      const formValues = formikRef.current.values;

      // Create the new entry
      const newEntry = { from_head_id: "", to_head_id: "", amount: "" };

      // Update the entries array with a new empty row
      const updatedEntries = [...formValues.entries, newEntry];

      // Update the Formik form's values
      formikRef.current.setValues({
        ...formValues,
        entries: updatedEntries,
      });

      // Update the initialValues state
      setInitialValues((currentValues) => ({
        ...currentValues,
        entries: updatedEntries,
      }));
    }
  };

  const getOpenBudgets = async () => {
    await BaseApi()
      .get(`get-open-budgets?from_admin=1`)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          // Assuming academic_session_id is a numeric value
          return b.academic_session_id - a.academic_session_id;
        });

        setOpenBudgets(sortedData);
      })
      .catch(() => {});
  };

  const entrySchema = Yup.object().shape({
    from_head_id: Yup.string().required("From head ID is required"),
    to_head_id: Yup.string().required("To head ID is required"),
    amount: Yup.string().required("Amount is required"),
  });

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
    acc_budget_master_id: Yup.string().required("This field is required!"),
    acc_budget_form_id: Yup.string().required("This field is required!"),
    resolution_no: Yup.string().required("This field is required!"),
    meeting_date: Yup.string().required("This field is required!"),
    meeting_no: Yup.string().required("This field is required!"),
    entries: Yup.array()
      .of(entrySchema)
      .min(1, "At least one entry is required"),
  });

  const callFromAccHeads = async (acc_budget_form_id, acc_cost_center_id) => {
    BaseApi()
      .get(
        `/acc-budgets-for-reappropriations?acc_budget_form_id=${acc_budget_form_id}&acc_cost_center_id=${acc_cost_center_id}`
      )
      .then((response) => {
        console.log(response.data);
        setAccFromBudgets(response.data);
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };

  const callToAccHeads = async (acc_budget_form_id, acc_cost_center_id) => {
    BaseApi()
      .get(
        `/acc-budgets-for-reappropriations?acc_budget_form_id=${acc_budget_form_id}&acc_cost_center_id=${acc_cost_center_id}`
      )
      .then((response) => {
        console.log(response.data);
        setAccToBudgets(response.data);
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };

  // function restructureArray(arr, level = 0) {
  //   let result = [];
  //   for (let i = 0; i < arr.length; i++) {
  //     let item = arr[i];
  //     let indent = " ".repeat(level * 4); // adjust the whitespace here as needed
  //     let newItem = {
  //       id: item.id,
  //       name: indent + (item.code ?? "") + "-" + item.name,
  //     };
  //     result.push(newItem);
  //     if (item.children && item.children.length > 0) {
  //       let children = restructureArray(item.children, level + 1);
  //       result = result.concat(children);
  //     }
  //   }
  //   return result;
  // }
  const handleSubmit = async (payload, { resetForm }) => {
    console.log(payload);
    var form_data = new FormData();

    for (var key in payload) {
      console.log(key);
      if (key === "entries") {
        // append nested object
        for (let iKay in payload[key]) {
          for (let iKay1 in payload[key][iKay]) {
            form_data.append(
              `entries[${iKay}][${iKay1}]`,
              payload[key][iKay][iKay1]
            );
          }
        }
      } else {
        form_data.append(
          key,

          payload[key]
        );
      }
    }

    await BaseApi()
      .post(`acc-budget-reappropriations`, form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((item) => {
        console.log(item);
        setSuccess(true);
        resetForm();
        setInitialValues({
          acc_budget_form_id: "",
          acc_budget_master_id: "",
          academic_session_id: "",
          entries: [
            // Array to hold multiple rows
            {
              from_acc_cost_center_id: "",
              from_head_id: "",
              to_acc_cost_center_id: "",
              to_head_id: "",
              amount: "",
            },
          ],
          resolution_no: "",
          meeting_date: "",
          meeting_no: "",
          file: "",
        });
        setIsPreview(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setSuccess(false);
        setMessage(message);
      });
  };
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name={"budget reappropriation"} />

            {message && <Alert message={message} />}
            {success && (
              <p className='mb-4 text-green-500'>Data Saved successfully!</p>
            )}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='grid grid-cols-4 gap-4'>
                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Financial Year'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `  ${option.label}  `}
                    onChange={(selectedOption) => {
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption?.id
                      );
                      formikRef.current?.setFieldValue(
                        "acc_budget_form_id",
                        ""
                      );

                      // console.log(selectedOption.id);
                      setInitialValues({
                        ...initialValues,
                        academic_session_id: selectedOption?.id,
                        acc_budget_form_id: "",
                      });
                    }}
                  />
                  <div>
                    {initialValues?.academic_session_id > 0 && (
                      <CustomSelect
                        key={initialValues?.academic_session_id}
                        options={openBudgets.filter(
                          (ob) =>
                            ob.academic_session_id ==
                            initialValues?.academic_session_id
                        )}
                        name='acc_budget_form_id'
                        label='Select Budget Master'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) =>
                          `${option.budget_master?.name} - ${option.academic_session?.label}  `
                        }
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "acc_budget_form_id",
                            selectedOption?.id
                          );
                          formikRef.current?.setFieldValue(
                            "acc_budget_master_id",
                            selectedOption?.budget_master?.id
                          );
                        }}
                      />
                    )}
                  </div>

                  <div className='flex items-center justify-end'>
                    <Button
                      type='button'
                      classNames='float-left bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                      value={"Add New Row"}
                      onClick={addNewRow}
                      icon={"PlusIcon"}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-5 gap-4'>
                  {initialValues?.entries?.map((entry, index) => (
                    <React.Fragment key={index}>
                      <CustomSelect
                        options={costCenters}
                        name={`entries[${index}].from_acc_cost_center_id`}
                        label='Select Cost/Revenue Center'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option?.name}`}
                        onChange={async (selectedOption) => {
                          // console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            `entries[${index}].from_acc_cost_center_id`,
                            selectedOption?.id
                          );

                          callFromAccHeads(
                            formikRef.current?.values?.acc_budget_form_id,
                            selectedOption?.id
                          );
                        }}
                      />

                      <CustomSelect
                        options={accFromBudgets}
                        name={`entries[${index}].from_head_id`}
                        label='From Head'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) =>
                          (option.acc_payment_head?.code
                            ? option.acc_payment_head?.code
                            : "") +
                          "-" +
                          option.acc_payment_head?.name +
                          " Rs. " +
                          (option.receipt_amount > 0
                            ? option.receipt_amount
                            : option.payment_amount ?? "")
                        }
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            `entries[${index}].from_head_id`,
                            selectedOption?.id
                          );
                        }}
                      />
                      <CustomSelect
                        options={costCenters}
                        name={`entries[${index}].to_acc_cost_center_id`}
                        label='Select Cost/Revenue Center'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option?.name}`}
                        onChange={async (selectedOption) => {
                          // console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            `entries[${index}].to_acc_cost_center_id`,
                            selectedOption?.id
                          );

                          callToAccHeads(
                            formikRef.current?.values?.acc_budget_form_id,
                            selectedOption?.id
                          );
                        }}
                      />
                      <CustomSelect
                        options={accToBudgets}
                        name={`entries[${index}].to_head_id`}
                        label='To Head'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) =>
                          (option.acc_payment_head?.code
                            ? option.acc_payment_head?.code
                            : "") +
                          "-" +
                          option.acc_payment_head?.name +
                          " Rs. " +
                          (option.receipt_amount > 0
                            ? option.receipt_amount
                            : option.payment_amount ?? "")
                        }
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            `entries[${index}].to_head_id`,
                            selectedOption?.id
                          );
                        }}
                      />
                      <Input
                        type='text'
                        name={`entries[${index}].amount`}
                        label='Reappropriation Amount'
                      />
                    </React.Fragment>
                  ))}
                  <div></div>
                  <div className='flex justify-start'>
                    {" "}
                    <Button
                      type={"button"}
                      // className='px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700'
                      value={isPreview ? "Edit" : "Preview"}
                      onClick={handlePreview}
                      icon={"SaveIcon"}
                    />
                  </div>

                  {/* Show Preview and Additional Fields */}
                  {isPreview && (
                    <>
                      <div className='fixed inset-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
                        <div className='relative max-w-4xl mx-auto my-6 bg-white w-800'>
                          {/* Render the preview content here based on form values */}
                          <div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'>
                            <div className='flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200'>
                              <h3 className='text-3xl font-semibold'>
                                Preview
                              </h3>
                              {message && <Alert message={message} />}
                            </div>
                            <div className='p-6'>
                              <p>
                                <strong>Academic Session:</strong>
                                {
                                  academicSessions.find(
                                    (ac) =>
                                      ac.id ==
                                      formikRef.current?.values
                                        .academic_session_id
                                  )?.label
                                }
                              </p>
                              <p>
                                <strong>Budget Master:</strong>{" "}
                                {
                                  openBudgets.find(
                                    (ob) =>
                                      ob.id ==
                                      formikRef.current?.values
                                        .acc_budget_form_id
                                  )?.budget_master?.name
                                }
                              </p>
                              <table className='min-w-full bg-white dark:bg-gray-800'>
                                <thead>
                                  <tr>
                                    <th className={thClass}>From</th>
                                    <th className={thClass}>To</th>
                                    <th className={thClass}>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formikRef.current?.values.entries.map(
                                    (entry, index) => (
                                      <tr key={index}>
                                        <td className={tdClass}>
                                          {
                                            costCenters.find(
                                              (ab) =>
                                                ab.id ==
                                                entry?.from_acc_cost_center_id
                                            )?.name
                                          }
                                          -
                                          {
                                            accFromBudgets.find(
                                              (ab) =>
                                                ab.id == entry?.from_head_id
                                            )?.acc_payment_head?.name
                                          }
                                        </td>
                                        <td className={tdClass}>
                                          {
                                            costCenters.find(
                                              (ab) =>
                                                ab.id ==
                                                entry?.to_acc_cost_center_id
                                            )?.name
                                          }
                                          -
                                          {
                                            accToBudgets.find(
                                              (ab) => ab.id == entry?.to_head_id
                                            )?.acc_payment_head?.name
                                          }
                                        </td>
                                        <td className={tdClass}>
                                          {" "}
                                          {entry.amount}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>

                              <div className='grid grid-cols-4 gap-4 mt-6'>
                                <Input
                                  type='text'
                                  name='resolution_no'
                                  label='Resolution No'
                                />
                                <Input
                                  type='date'
                                  name='meeting_date'
                                  label='Meeting Date'
                                />
                                <Input
                                  type='text'
                                  name='meeting_no'
                                  label='Meeting No'
                                />
                                <Upload
                                  label='Document(if any)'
                                  type='file'
                                  name='file'
                                  onChange={(event) => {
                                    let myFile = event.currentTarget.files[0];
                                    formikRef.current.setFieldValue(
                                      "file",
                                      myFile
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='flex items-center justify-between p-6 border-t border-solid rounded-b border-slate-200'>
                            <button
                              className='px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none'
                              type='button'
                              onClick={() => setIsPreview(false)}
                            >
                              Close
                            </button>
                            <Button
                              value='Submit'
                              icon={"SaveIcon"}
                              type='submit'
                            />
                          </div>

                          {/* Submit Button */}
                        </div>
                      </div>
                      <div className='fixed inset-0 z-40 bg-black opacity-25'></div>
                    </>
                  )}
                </div>
              </Form>
            </Formik>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
