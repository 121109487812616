import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authSlice from "./AuthSlice";
import messageSlice from "./MessageSlice";
import budgetSlice from "./BudgetSlice";
import masterSlice from "./MasterSlice";
import OldStudentSlice from "./OldStudentSlice";
import StudentSlice from "./StudentSlice";
import ApplicantSlice from "./ApplicantSlice";

const combinedReducer = combineReducers({
  auth: authSlice.reducer,
  master: masterSlice,
  budget: budgetSlice,
  oldStudents: OldStudentSlice,
  students: StudentSlice,
  applicants: ApplicantSlice,
  message: messageSlice.reducer,
});
const rootReducer = (state, action) => {
  if (action.type === "auth/logout/fulfilled") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default configureStore({
  reducer: rootReducer,
});
