import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";

// import Button from "../../Components/Utils/Button";
// import { useNavigate } from "react-router-dom";
import BaseApi from "../../../Http/BaseApi";

import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Pagination from "../../../Components/Utils/Pagination";
import Badge from "../../../Components/Utils/Badge";

export default function StockRegister() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const authData = useSelector((state) => state.auth);
  // const [staff, setStaff] = useState([]);

  // const [selectedIds, setSelectedIds] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);

  // const master = useSelector((state) => state.master);
  // const staffLeaveType = [...master.staffLeaveTypes];

  useEffect(() => {
    if (stocks.length === 0) {
      callStocks(null, "");
    }
  }, [dispatch]);

  const callStocks = async (url, searchTerm) => {
    setLoading(true);
    await BaseApi()
      .get(url ?? `/stock-registers?search_term=${searchTerm}`)
      .then((response) => {
        setStocks(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
    setLoading(false);
  };
  // let thClass =
  //   " text-gray-600   border p-2  lg:whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass = "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900";

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5   '>
            <PageTitle name='Stock Register' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <input
              className='border my-5 w-64 border-gray-400 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              type='text'
              placeholder='Search by stock entry no.'
              onChange={(e) => {
                // if (e.target.value.length > 2) {
                callStocks(null, e.target.value);
                // }
              }}
            />
            <table
              id='datatable'
              className='min-w-full   bg-white dark:bg-gray-800'
            >
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Receiving Date
                  </th>

                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Proposal ID
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Stock Entry No.
                  </th>

                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Remarks
                  </th>

                  <th scope='col' className='relative px-6 py-3'>
                    <span className='sr-only'>Action</span>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {stocks.data?.length > 0 &&
                  stocks.data?.map((stock) => {
                    return (
                      <tr
                        key={stock.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass}>{stock.receiving_date}</td>
                        <td className={tdClass}>
                          {stock.proposal.proposal_id}
                        </td>
                        <td className={tdClass}>{stock.stockentry_no}</td>
                        <td className={tdClass}>
                          {stock.status == 0 && (
                            <Badge color='red' status='Partial' />
                          )}
                          {stock.status == 1 && (
                            <Badge color='red' status='Final' />
                          )}
                        </td>
                        <td className={tdClass}>{stock.remarks}</td>

                        <td className={tdClass}>
                          <Link
                            to={`/stock-registers/${stock.id}`}
                            className='text-indigo-600 hover:text-indigo-900'
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {stocks.total > stocks.per_page && (
              <div className='m-5'>
                <Pagination
                  links={stocks?.links}
                  onClick={(url) => {
                    callStocks(url, "");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
