import { ErrorMessage, Field } from "formik";
import React from "react";

export default function Input(props) {
  let classNames = props.readOnly ? "bg-gray-100" : "";

  return (
    <>
      <div className="">
        {props.label ? (
          <label
            htmlFor={props.id}
            className="block text-sm font-medium text-gray-700"
          >
            {props.label}
          </label>
        ) : (
          <span></span>
        )}
        <Field
          readOnly={props.readOnly ?? false}
          step=".01"
          id={props.id}
          type={props.type ?? "text"}
          name={props.name}
          // value={props.value}
          // onChange={props.onChange}
          placeholder={props.placeholder}
          className={`appearance-none mt-2 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
        />
        <ErrorMessage
          name={props.name}
          component="div"
          className="text-red-500 py-2"
        />
      </div>
    </>
  );
}
