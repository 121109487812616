import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
import { postLoginAsync } from "../../../Redux/AuthSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
// import messageSlice from "../../../Redux/MessageSlice";

export default function CostCenterLogin() {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(message);
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    if (isLoggedIn) {
      console.log("sd");
      navigate("/dashboard");
      window.location.reload();
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
    panel: "costcenter",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue, { resetForm }) => {
    setLoading(true);
    dispatch(postLoginAsync({ payload: formValue }))
      .unwrap()
      .then(() => {
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        resetForm();
        setLoading(false);
      });
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8  mt-10">
        <div className="max-w-md w-full space-y-8 shadow-2xl p-10 rounded-lg">
          <div>
            <img
              className="mx-auto h-12 w-auto rounded-full"
              src="https://erp.dibru.work/images/dibru.png"
              alt="Logo"
            />

            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-600">
              Cost Center Panel
            </h2>
            <h2 className="mt-1 text-center text-xl font-medium text-gray-600">
              Login to continue...
            </h2>
          </div>
          {message && <Alert message={message} />}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="  -space-y-px">
                <div className="">
                  <Field
                    name="email"
                    type="text"
                    placeholder="Enter your registered email"
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 py-2"
                  />
                </div>

                <div className="">
                  <Field
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500 py-2"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between my-10">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>

                {/* <button
                type="button"
                
                onClick={login}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button> */}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
