import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "../../../Components/Common/PageTitle";
import BaseApi from "../../../Http/BaseApi";
// import Input from "../../../Components/Utils/Input";
import * as Yup from "yup";
import Input from "../../../Components/Utils/Input";
import can from "../../../Middleware/Permission";
import Badge from "../../../Components/Utils/Badge";
import SearchButton from "../../../Components/Utils/SearchButton";

const BillIndex = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [active, setActive] = useState("");
  const dispatch = useDispatch();
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBill, setSelectedBill] = useState();
  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");

  const handleClick = (status) => {
    setActive(status);
    formikRef.current?.setFieldValue(
      "audit_status",
      status == "Recomended for Payment" ? 1 : 0
    );
  };
  const audit = (e, bill) => {
    e.target.disabled = true;
    setPopupOpen(true);
    console.log(bill);
    setSelectedBill(bill);
    formikRef.current?.setFieldValue("bill_id", bill.id);
    e.target.disabled = false;
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  useEffect(() => {
    if (bills.length === 0) {
      callBills(null);
    }

    setInitialValues({
      audit_status: "",
      audit_remarks: "",

      bill_id: selectedBill?.id,
    });
  }, [dispatch]);

  const callBills = (url) => {
    setLoading(true);
    BaseApi()
      .get(url ?? `/bills?search_term=${searchTerm}`)
      .then((response) => {
        setBills(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
    setLoading(false);
  };
  const validationSchema = Yup.object().shape({
    audit_status: Yup.string().required("This field is required!"),
    audit_remarks: Yup.string().when("audit_status", {
      is: (type) => type != "1",
      then: Yup.string().required("This field is required!"),
    }),
    // acc_budget_master_id: Yup.string().required("This field is required!"),
    // acc_payment_head_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    console.log(payload);
    BaseApi()
      .post(`/audit-status`, { ...payload, bill_id: selectedBill.id })
      .then(() => {
        BaseApi()
          .get(`/bills`)
          .then((response) => {
            setPopupOpen(false);
            setBills(response.data);
            // console.log('data is', response.data)
          })
          .catch((error) => {
            console.log("error is", error);
          });
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };
  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5   '>
            <PageTitle name='List of Bills' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <div className='flex items-center gap-2 mb-3'>
              {" "}
              <input
                className='border border-gray-400 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                type='number'
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder='Search by proposal number'
              />
              <SearchButton
                onClick={() => {
                  callBills(null);
                }}
              />
            </div>

            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Bill Date
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Bill Number
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Order Number
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Vendor
                  </th>

                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Amount
                  </th>

                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Audit
                  </th>

                  <th scope='col' className='relative px-6 py-3'>
                    <span className='sr-only'>View</span>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {bills?.data?.length > 0 &&
                  bills?.data?.map((bill) => (
                    <tr key={bill.id}>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {bill.bill_date} <br />
                          <br />
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {bill.bill_no}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {bill.proposal?.order_id}
                        </div>
                      </td>

                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {bill.proposal?.vendor?.name}
                        </div>
                        <div className='text-sm text-gray-500'>
                          {bill.proposal?.vendor?.email}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {parseFloat(bill.amount)?.toFixed(2)}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-yellow-600'>
                          {bill.type == 0 && (
                            <Badge color='red' status='Partial Bill' />
                          )}
                          {bill.type == 1 && (
                            <Badge color='green' status='Final Bill' />
                          )}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-yellow-600'>
                          {bill.audit_status == 0 && (
                            <>
                              Raised Query <br />
                              <small className='text-gray-800'>
                                {" "}
                                Remarks: {bill.audit_remarks}
                              </small>{" "}
                            </>
                          )}
                          {bill.audit_status == 1 && "Recommended For Payment"}

                          {can("Can Audit") && bill.audit_status != 1 && (
                            <>
                              {" "}
                              <br /> <br />
                              <a
                                onClick={(e) => audit(e, bill)}
                                className='  border-2 p-1 border-indigo-700 text-indigo-600 hover:text-indigo-900'
                              >
                                Audit
                              </a>
                            </>
                          )}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                        <Link
                          target={"_blank"}
                          to={`/proposals/${bill.proposal?.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          View Order
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {popupOpen && (
        <>
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative lg:w-2/6	 my-6 mx-auto max-w-3xl'>
                  {/*content*/}
                  <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                    {/*header*/}
                    <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                      <h3 className='text-3xl font-semibold'>Bill Audit</h3>
                      <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className='relative   p-4 flex-auto'>
                      <div className='my-1    text-sm '>
                        <div className='relative p-2 flex-auto'>
                          <div>
                            System W/O Number: {selectedBill.proposal?.order_id}
                          </div>
                          <div>
                            Manual W/O Number:
                            {selectedBill.proposal?.manual_order_no}
                          </div>
                          <div>
                            Vendor: {selectedBill.proposal?.vendor.name}
                          </div>
                          <div>Bill Date: {selectedBill.bill_no}</div>
                          <br />

                          <div className='flex justify-center items-center space-x-6'>
                            <button
                              type='button'
                              className={`text-white rounded-full py-2 px-6 ${
                                active === "Recomended for Payment"
                                  ? "bg-green-500"
                                  : "bg-gray-400 hover:bg-green-500"
                              }`}
                              onClick={() =>
                                handleClick("Recomended for Payment")
                              }
                            >
                              Recomended for Payment
                            </button>
                            <button
                              type='button'
                              className={`text-white rounded-full py-2 px-6 ${
                                active === "Raise Query"
                                  ? "bg-yellow-500"
                                  : "bg-gray-400 hover:bg-yellow-500"
                              }`}
                              onClick={() => handleClick("Raise Query")}
                            >
                              Raise Query
                            </button>

                            {/* <button
                          className={`text-white rounded-full py-2 px-6 ${
                            active === "Inactive"
                              ? "bg-red-500"
                              : "bg-gray-400 hover:bg-red-500"
                          }`}
                          onClick={() => handleClick("Inactive")}
                        >
                          Inactive
                        </button> */}
                          </div>
                          <ErrorMessage
                            name='audit_status'
                            component='div'
                            className='text-red-500 text-center py-2'
                          />
                          <div className='mt-4'>
                            <span className='text-lg font-semibold'>{`Selected status: ${active}`}</span>
                          </div>
                          {active == "Raise Query" && (
                            <Input
                              label='Remarks'
                              type='text'
                              name='audit_remarks'
                              placeholder='Remarks'
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                      <button
                        className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='button'
                        onClick={handlePopupClose}
                      >
                        Close
                      </button>
                      <button
                        className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='submit'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </>
  );
};

export default BillIndex;
