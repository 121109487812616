import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
import {
  addCostCenterAsync,
  deleteCostCenterAsync,
  getCostCentersAsync,
  updateCostCenterAsync,
} from "../../../Redux/BudgetSlice";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import BaseApi from "../../../Http/BaseApi";
import tableToCSV from "../../../Components/Utils/TableToCSV";
// import { useNavigate } from "react-router-dom";

export default function CostCenter() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const formikRef = useRef();

  const [nodalPersonName, setNodalPersonName] = useState();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const { message } = useSelector((state) => state.message);
  const [staff, setStaff] = useState([]);

  const budget = useSelector((state) => state.budget);
  const costCenters = [...budget.costCenters];

  const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    getStaffAsync();

    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    setInitialValues({
      id: null,
      name: "",
      cost_center_code: "",
      revenue_center_code: "",
      staff_id: "",
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (costCenters.length === 0) {
      dispatch(getCostCentersAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const getStaffAsync = async () => {
    await BaseApi()
      .get(`staff`, { params: { permission: "Submit Budget" } })
      .then((data) => {
        setStaff(data.data);
      });
  };
  const onEdit = (event, costCenter) => {
    event.preventDefault();
    setInitialValues({
      id: costCenter.id,

      name: costCenter.name,
      cost_center_code: costCenter.cost_center_code,
      revenue_center_code: costCenter.revenue_center_code,
      staff_id: costCenter.staff_id,
    });
    setNodalPersonName(staff.find((s) => s.id == costCenter.staff_id)?.name);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFormRole("edit");
  };
  const onCancelEdit = (event) => {
    event.preventDefault();
    setInitialValues({
      id: null,
      name: "",
      cost_center_code: "",
      revenue_center_code: "",
      staff_id: "",
    });
    setNodalPersonName();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFormRole("add");
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    cost_center_code: Yup.string().required("This field is required!"),
    revenue_center_code: Yup.string().required("This field is required!"),
    staff_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await dispatch(updateCostCenterAsync(payload)).unwrap();
    } else {
      await dispatch(addCostCenterAsync(payload)).unwrap();
    }

    setLoading(false);
    setFormRole("add");
    resetForm();
    setInitialValues({
      id: null,
      name: "",
      cost_center_code: "",
      revenue_center_code: "",
      staff_id: "",
    });
    setNodalPersonName();
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await dispatch(deleteCostCenterAsync(payload));
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item != value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await dispatch(bulkDeleteAccPaymentHeadsAsync({ selectedIds })).unwrap();
  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  console.log(message);
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5   '>
            <PageTitle name='Cost Centers' />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=' py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8'>
                  <Input
                    label={
                      formRole === "add"
                        ? "Create New Cost Center "
                        : "Edit Cost Center"
                    }
                    name='name'
                    placeholder='Enter Name'
                  />
                  <Input
                    label='Cost Center Code'
                    name='cost_center_code'
                    placeholder='Enter Cost Center Code'
                  />
                  <Input
                    label='Revenue Center Code'
                    name='revenue_center_code'
                    placeholder='Enter Revenue Center Code'
                  />

                  <CustomSelect
                    key={initialValues?.staff_id}
                    defaultInputValue={nodalPersonName}
                    options={staff}
                    name='staff_id'
                    label='Nodal Person'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.designation?.name}) `
                    }
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "staff_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <div className='text-left mt-5'>
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <Button
                        value={loading ? "Updating..." : "Update"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    )}

                    {formRole === "edit" && (
                      <button
                        type='button'
                        className='ml-5   text-red-600'
                        onClick={onCancelEdit}
                      >
                        Cancel Edit X
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>

            <br />
            <Button
              classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              value={"Download CSV"}
              onClick={() => tableToCSV("datatable")}
              icon={"DownloadIcon"}
            />

            <table
              id='datatable'
              className='min-w-full   bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Name</th>
                  <th className={thClass}>Cost Center Code</th>
                  <th className={thClass}>Revenue Center Code</th>
                  <th className={thClass}>Nodal Person</th>
                  <th className={thClass}>Contact No.</th>
                  <th className={thClass}>Action</th>
                </tr>
              </thead>
              <tbody>
                {costCenters.length > 0 &&
                  costCenters.map((center, i) => {
                    return (
                      <tr
                        key={center.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass + "w-32"}>{++i}</td>
                        <td className={tdClass}>{center.name}</td>
                        <td className={tdClass}>{center.cost_center_code}</td>
                        <td className={tdClass}>
                          {center.revenue_center_code}
                        </td>
                        <td className={tdClass}>
                          {center.staff?.name} -{" "}
                          {center.staff?.designation?.name}
                        </td>
                        <td className={tdClass}>{center.staff?.phone} </td>

                        <td className={tdClass}>
                          {/* <span
                            className="
                            bg-gradient-to-r from-pink-500 to-yellow-500 cursor-pointer  hover:bg-blue-700 text-white font-light py-1 px-2 rounded"
                            onClick={() => {
                              navigate(`/budget-proposal-submit/${center.id}`);
                            }}
                          >
                            View Budget
                          </span> */}

                          <span
                            className='
                            bg-gradient-to-r from-blue-500 to-yellow-500 ml-5 hover:bg-blue-700 text-white font-light py-1 px-2 rounded'
                            onClick={(event) => onEdit(event, center)}
                          >
                            Edit
                          </span>
                          {(deleteConfirm.state == 0 ||
                            deleteConfirm.itemId != center.id) && (
                            <span
                              className='ml-5 bg-gradient-to-r from-red-500 to-yellow-500 hover:bg-red-700 text-white font-light py-1 px-2 rounded'
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: center.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </span>
                          )}
                          {deleteConfirm.state == 1 &&
                            deleteConfirm.itemId == center.id && (
                              <>
                                <p className='text-red-700'> Are You Sure? </p>

                                <button
                                  className='outline-button-danger'
                                  onClick={(event) =>
                                    handleDelete(event, center)
                                  }
                                >
                                  Yes
                                </button>
                                <span className='mr-2'></span>
                                <button
                                  className='outline-button-success'
                                  onClick={() => setDeleteConfirm({})}
                                >
                                  No
                                </button>
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
