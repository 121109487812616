import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import BaseApi from "../../../../Http/BaseApi";
import { useDispatch, useSelector } from "react-redux";
import { getBudgetSuperMastersAsync } from "../../../../Redux/BudgetSlice";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import Alert from "../../../../Components/Utils/Alert";

export default function AcceptProposal({
  id,
  onCompleteAction,
  handlePopupClose,
}) {
  //   const [selectedBM, setSelectedBM] = useState({});
  const [accPaymentHeads, setAccPaymentHeads] = useState([]);
  // const printRef = useRef();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const budget = useSelector((state) => state.budget);

  const budgetSuperMasters = [...budget.budgetSuperMasters];

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    proposal_id: Yup.string().required("This field is required!"),
    acc_budget_master_id: Yup.string().required("This field is required!"),
    acc_payment_head_id: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }

    setInitialValues({
      acc_payment_head_id: "",
      acc_budget_master_id: "",
      proposal_id: id,
    });
  }, [id]);

  const callAccHeads = async (acc_budget_master_id) => {
    BaseApi()
      .get(
        `/acc-payment-heads-with-amount?acc_budget_master_id=${acc_budget_master_id}`
      )
      .then((response) => {
        console.log(response.data);
        setAccPaymentHeads(response.data);
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };

  const acceptProposal = async (payload) => {
    BaseApi()
      .post(`/accept-proposal`, payload)
      .then((response) => {
        console.log(response.data);
        handlePopupClose();
        onCompleteAction();
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
  };
  return (
    <>
      {" "}
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={acceptProposal}
      >
        <Form>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative lg:w-2/4	 my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>Accept Proposal </h3>
                  <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative   p-4 flex-auto'>
                  <div className='my-1    text-sm '>
                    {errorMessage && <Alert message={errorMessage} />}

                    <p className='text-center font-semibold'>
                      Assign head to this proposal
                    </p>

                    <div className='relative p-6 flex-auto'>
                      <CustomSelect
                        options={budgetSuperMasters}
                        name='acc_budget_master_id'
                        label='Select Budget Master'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option.name} `}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "acc_budget_master_id",
                            selectedOption.id
                          );
                          //   setSelectedBM(selectedOption);
                          callAccHeads(selectedOption.id);
                        }}
                      />

                      <CustomSelect
                        options={accPaymentHeads}
                        name='acc_payment_head_id'
                        label='Select Payment Head'
                        isSearchable={true}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) =>
                          (option.code ? option.code : "") +
                          "-" +
                          option.name +
                          (option.balance
                            ? " (Rs. " + option.balance + ")"
                            : "")
                        }
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          formikRef.current?.setFieldValue(
                            "acc_payment_head_id",
                            selectedOption.id
                          );
                        }}
                      />

                      {/* <Select
                        name='acc_payment_head_id'
                        label='Select Parent Head'
                      >
                        <option value=''>None</option>
                        {accPaymentHeads
                          ?.filter(
                            (ah) => ah.acc_budget_master_id == selectedBM.id
                          )
                          .map((head) => {
                            return (
                              <>
                                <option value={head.id}>{head.name}</option>
                                {head.children?.map((child) => {
                                  return (
                                    <>
                                      <option value={child.id}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
                                      </option>
                                      {child.children?.map((grandchild) => {
                                        return (
                                          <>
                                            <option value={grandchild.id}>
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {grandchild.name}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}
                      </Select> */}

                      <p className='mt-10 text-blue-500'>
                        Assiging head will convert this proposal to Committed
                        Expendature
                      </p>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handlePopupClose}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
}
