import React from "react";

function ValidationErrors({ errors, className }) {
  if (!Object.keys(errors).length) {
    return null;
  }

  return (
    <div
      className={`validation-errors bg-red-100 text-red-600 px-2 py-1 ${className}`}
    >
      <ul>
        {Object.entries(errors).map(([field, messages]) =>
          messages.map((message) => (
            <li key={`${field}-${message}`}>{message}</li>
          ))
        )}
      </ul>
    </div>
  );
}

export default ValidationErrors;
