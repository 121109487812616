import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

import {
  addAccPaymentHeadAsync,
  deleteAccPaymentHeadAsync,
  getAccPaymentHeadsAsync,
  getBudgetSuperMastersAsync,
  updateAccPaymentHeadAsync,
} from "../../../Redux/BudgetSlice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
import Select from "../../../Components/Utils/Select";
import CustomSelect from "../../../Components/Utils/CustomSelect";

export default function BudgetHead() {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedBM, setSelectedBM] = useState({});
  // const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const { message } = useSelector((state) => state.message);

  const budget = useSelector((state) => state.budget);
  const accPaymentHeads = [...budget.accPaymentHeads];
  const budgetSuperMasters = [...budget.budgetSuperMasters];

  const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    setInitialValues({
      id: null,
      acc_payment_head_id: "",
      acc_budget_master_id: "",
      name: "",
      code: "",
      type: "",
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (accPaymentHeads.length === 0) {
      dispatch(getAccPaymentHeadsAsync());
    }
    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const onEdit = (event, paymentHead) => {
    event.preventDefault();
    setInitialValues({
      id: paymentHead.id,
      acc_payment_head_id: paymentHead.acc_payment_head_id,
      acc_budget_master_id: paymentHead.acc_budget_master_id,
      name: paymentHead.name,
      code: paymentHead.code,
      type: paymentHead.type,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFormRole("edit");
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    acc_budget_master_id: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await dispatch(updateAccPaymentHeadAsync(payload)).unwrap();
    } else {
      await dispatch(addAccPaymentHeadAsync(payload)).unwrap();
    }

    setLoading(false);
    setFormRole("add");
    resetForm();
    setInitialValues({
      id: null,
      acc_payment_head_id: "",
      acc_budget_master_id: "",
      name: "",
      code: "",
      type: "",
    });
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await dispatch(deleteAccPaymentHeadAsync(payload));
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item != value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await dispatch(bulkDeleteAccPaymentHeadsAsync({ selectedIds })).unwrap();
  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  console.log(message);
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle name="Master Budget Heads" />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  <Input
                    label={
                      formRole === "add"
                        ? "Create New Master Head"
                        : "Edit Master Head"
                    }
                    name="name"
                    placeholder="Enter Head Name"
                  />
                  <Input
                    label={formRole === "add" ? "Code" : "Code"}
                    name="code"
                    placeholder="Enter Code"
                  />

                  <Select name="type" label="Select Type">
                    <option value="">Select</option>
                    <option value="1">Receipt</option>
                    <option value="2">Payment</option>
                  </Select>

                  <CustomSelect
                    options={budgetSuperMasters}
                    name="acc_budget_master_id"
                    label="Select Budget Master"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "acc_budget_master_id",
                        selectedOption.id
                      );
                      setSelectedBM(selectedOption);
                    }}
                  />

                  <Select name="acc_payment_head_id" label="Select Parent Head">
                    <option value="">None</option>
                    {accPaymentHeads
                      ?.filter((ah) => ah.acc_budget_master_id == selectedBM.id)
                      .map((head) => {
                        return (
                          <>
                            <option value={head.id}>{head.name}</option>
                            {head.children?.map((child) => {
                              return (
                                <>
                                  <option value={child.id}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
                                  </option>
                                  {child.children?.map((grandchild) => {
                                    return (
                                      <>
                                        <option value={grandchild.id}>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {grandchild.name}
                                        </option>
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                  </Select>
                  <div className="text-left mt-5">
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <Button
                        value={loading ? "Updating..." : "Update"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </Formik>

            <br />
            <table
              id="datatable"
              className="min-w-full   bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>
                    {budgetSuperMasters.map((bm) => {
                      return (
                        <>
                          <button
                            type="button"
                            onClick={() => setSelectedBM(bm)}
                            className={`
        rounded
        active
        
        px-6
        py-2
        border-2 border-blue-600
        text-blue-600
        font-medium
        text-xs
        leading-tight
        uppercase
        
        focus:outline-none focus:ring-0
        transition
        duration-150
        ease-in-out
        mr-2
       ${bm.id == selectedBM.id ? " bg-gray-200" : ""}`}
                          >
                            {bm.name}
                          </button>
                        </>
                      );
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {accPaymentHeads.length > 0 &&
                  accPaymentHeads
                    ?.filter((ah) => ah.acc_budget_master_id == selectedBM?.id)
                    ?.map((head, i) => {
                      return (
                        <tr
                          key={head.id}
                          className="odd:bg-white even:bg-gray-50"
                        >
                          <td className={tdClass + "w-32"}>{++i}</td>
                          <td className={tdClass}>
                            {head.code} - {head.name}{" "}
                            <span
                              className=" ml-10
                             bg-blue-200  hover:bg-blue-700  text-white font-bold py-1 px-2 rounded"
                              onClick={(event) => onEdit(event, head)}
                            >
                              Edit
                            </span>
                            {(deleteConfirm.state == 0 ||
                              deleteConfirm.itemId != head.id) && (
                              <span
                                className="ml-5 bg-red-200 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                onClick={() =>
                                  setDeleteConfirm({
                                    itemId: head.id,
                                    state: 1,
                                  })
                                }
                              >
                                Delete
                              </span>
                            )}
                            {deleteConfirm.state == 1 &&
                              deleteConfirm.itemId == head.id && (
                                <>
                                  <p className="text-red-700">
                                    {" "}
                                    Are You Sure?{" "}
                                  </p>

                                  <button
                                    className="outline-button-danger"
                                    onClick={(event) =>
                                      handleDelete(event, head)
                                    }
                                  >
                                    Yes
                                  </button>
                                  <span className="mr-2"></span>
                                  <button
                                    className="outline-button-success"
                                    onClick={() => setDeleteConfirm({})}
                                  >
                                    No
                                  </button>
                                </>
                              )}
                            <ul className="pl-10">
                              {head.children?.map((child) => {
                                return (
                                  <>
                                    <li className="m-4">
                                      {child.code} - {child.name}{" "}
                                      <span
                                        className=" 
                                      ml-10
                                      bg-blue-200  hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                        onClick={(event) =>
                                          onEdit(event, child)
                                        }
                                      >
                                        Edit
                                      </span>
                                      {(deleteConfirm.state == 0 ||
                                        deleteConfirm.itemId != child.id) && (
                                        <span
                                          className="ml-5 bg-red-200 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                          onClick={() =>
                                            setDeleteConfirm({
                                              itemId: child.id,
                                              state: 1,
                                            })
                                          }
                                        >
                                          Delete
                                        </span>
                                      )}
                                      {deleteConfirm.state == 1 &&
                                        deleteConfirm.itemId == child.id && (
                                          <>
                                            <p className="text-red-700">
                                              {" "}
                                              Are You Sure?{" "}
                                            </p>

                                            <button
                                              className="outline-button-danger"
                                              onClick={(event) =>
                                                handleDelete(event, child)
                                              }
                                            >
                                              Yes
                                            </button>
                                            <span className="mr-2"></span>
                                            <button
                                              className="outline-button-success"
                                              onClick={() =>
                                                setDeleteConfirm({})
                                              }
                                            >
                                              No
                                            </button>
                                          </>
                                        )}
                                      <ul className="pl-10">
                                        {child.children?.map((grandchild) => {
                                          return (
                                            <>
                                              <li className="m-4">
                                                {grandchild.code} -{" "}
                                                {grandchild.name}
                                                <span
                                                  className=" 
                                                ml-10
                                                bg-blue-200  hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                                  onClick={(event) =>
                                                    onEdit(event, grandchild)
                                                  }
                                                >
                                                  Edit
                                                </span>
                                                {(deleteConfirm.state == 0 ||
                                                  deleteConfirm.itemId !=
                                                    grandchild.id) && (
                                                  <span
                                                    className="ml-5 bg-red-200 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                                    onClick={() =>
                                                      setDeleteConfirm({
                                                        itemId: grandchild.id,
                                                        state: 1,
                                                      })
                                                    }
                                                  >
                                                    Delete
                                                  </span>
                                                )}
                                                {deleteConfirm.state == 1 &&
                                                  deleteConfirm.itemId ==
                                                    grandchild.id && (
                                                    <>
                                                      <p className="text-red-700">
                                                        {" "}
                                                        Are You Sure?{" "}
                                                      </p>

                                                      <button
                                                        className="outline-button-danger"
                                                        onClick={(event) =>
                                                          handleDelete(
                                                            event,
                                                            grandchild
                                                          )
                                                        }
                                                      >
                                                        Yes
                                                      </button>
                                                      <span className="mr-2"></span>
                                                      <button
                                                        className="outline-button-success"
                                                        onClick={() =>
                                                          setDeleteConfirm({})
                                                        }
                                                      >
                                                        No
                                                      </button>
                                                    </>
                                                  )}
                                              </li>
                                            </>
                                          );
                                        })}
                                      </ul>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
