import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Input from "../../../Components/Utils/Input";
import BaseApi from "../../../Http/BaseApi";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Components/Utils/Button";
import { getBudgetSuperMastersAsync } from "../../../Redux/BudgetSlice";

export default function BudgetBlock() {
  // console.log(proposalNature);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const formikRef = useRef();
  const [budgetBlocks, setBudgetBlocks] = useState([]);
  const [accPaymentHeads, setAccPaymentHeads] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const budget = useSelector((state) => state.budget);
  const budgetSuperMasters = [...budget.budgetSuperMasters];
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const initialValues = {
    amount: "",
    acc_budget_master_id: "",
    acc_payment_head_id: "",
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("This field is required!"),
    acc_budget_master_id: Yup.string().required("This field is required!"),
    acc_payment_head_id: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    if (budgetBlocks.length == 0) {
      getBudgetBlocks();
    }
    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }
  }, [dispatch]);

  const getBudgetBlocks = async () => {
    setLoading(true);
    BaseApi()
      .get(`/acc-budget-blocks`)
      .then((response) => {
        console.log(response.data);
        setBudgetBlocks(response.data);

        setLoading(false);
      })
      .catch((error) => {
        console.log("error is", error);
        setLoading(false);
      });
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;

    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`acc-budget-blocks/${payload.id}`, {})
        .then(() => {
          let heads = budgetBlocks.filter((p) => p.id != payload.id);
          console.log(heads);
          setBudgetBlocks(heads);
          // getBudgetBlocks();
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  const callAccHeads = async (acc_budget_master_id) => {
    setLoading(true);
    BaseApi()
      .get(
        `/acc-payment-heads?type=2&acc_budget_master_id=${acc_budget_master_id}`
      )
      .then((response) => {
        console.log(response.data);
        setAccPaymentHeads(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error is", error);
        setLoading(false);
      });
  };
  const handleSubmit = async (payload, { resetForm }) => {
    await BaseApi()
      .post(`acc-budget-blocks`, payload)
      .then((item) => {
        console.log(item);
        setSuccess(true);
        resetForm();
        let heads = budgetBlocks;
        heads.push(item.data);
        setBudgetBlocks(heads);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
      });
  };

  function restructureArray(arr, level = 0) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      let indent = " ".repeat(level * 4); // adjust the whitespace here as needed
      let newItem = { id: item.id, name: indent + item.name };
      result.push(newItem);
      if (item.children && item.children.length > 0) {
        let children = restructureArray(item.children, level + 1);
        result = result.concat(children);
      }
    }
    return result;
  }

  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  // console.log(restructureArray(accPaymentHeads));
  return (
    <>
      <div className='container mx-auto'>
        <h1 className='text-2xl font-bold mb-4'>Block Previous Amounts</h1>
        {loading == true && (
          <>
            <div className='flex'>
              <span className='m-auto'>
                <ThreeDots
                  height='100'
                  width='100'
                  color='grey'
                  ariaLabel='loading'
                />
              </span>
            </div>
          </>
        )}
        {success && (
          <p className='text-green-500 mb-4'>Data Saved successfully!</p>
        )}
        {errorMessage && <p className='text-red-500 mb-4'>{errorMessage}</p>}
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className='space-y-4'>
              <div className='grid grid-cols-3 gap-4'>
                <CustomSelect
                  options={budgetSuperMasters}
                  name='acc_budget_master_id'
                  label='Select Budget Master'
                  isSearchable={true}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name} `}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "acc_budget_master_id",
                      selectedOption.id
                    );
                    //   setSelectedBM(selectedOption);
                    callAccHeads(selectedOption.id);
                  }}
                />
                <CustomSelect
                  options={restructureArray(accPaymentHeads)}
                  formatOptionLabel={(option) => {
                    return <pre>{option.name}</pre>;
                  }}
                  name='acc_payment_head_id'
                  label='Select Payment Head'
                  isSearchable={true}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.name}   `}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "acc_payment_head_id",
                      selectedOption.id
                    );
                  }}
                />
                <div className='flex flex-col'>
                  <Input
                    label='Enter Amount'
                    placeholder='Enter Amount'
                    type='number'
                    name='amount'
                    className='border rounded-md p-2'
                  />
                </div>
              </div>

              <div className='flex justify-center'>
                {" "}
                <Button
                  type={"submit"}
                  // className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  disabled={isSubmitting}
                  value={"Submit"}
                  icon={"SaveIcon"}
                />
              </div>
            </Form>
          )}
        </Formik>
        <br />
        <table
          id='datatable'
          className='min-w-full   bg-white dark:bg-gray-800'
        >
          <thead>
            <tr>
              <th className={thClass + "w-16"}>#</th>
              <th className={thClass}>Budget Type</th>
              <th className={thClass}>Payment Head</th>
              <th className={thClass}>Ledger Code</th>
              <th className={thClass}>Amount</th>

              <th className={thClass}>Action</th>
            </tr>
          </thead>
          <tbody>
            {budgetBlocks.length > 0 &&
              budgetBlocks.map((budget, i) => {
                return (
                  <tr key={budget.id} className='odd:bg-white even:bg-gray-50'>
                    <td className={tdClass + "w-32"}>{++i}</td>
                    <td className={tdClass}>
                      {budget.acc_budget_master?.name}
                    </td>
                    <td className={tdClass}>
                      {" "}
                      {budget.acc_payment_head?.name}
                    </td>
                    <td className={tdClass}>{budget.acc_payment_head?.code}</td>
                    <td className={tdClass}>{budget.amount}</td>

                    <td className={tdClass}>
                      {(deleteConfirm.state == 0 ||
                        deleteConfirm.itemId != budget.id) && (
                        <span
                          className='ml-5 bg-gradient-to-r from-red-500 to-yellow-500 hover:bg-red-700 text-white font-light py-1 px-2 rounded'
                          onClick={() =>
                            setDeleteConfirm({
                              itemId: budget.id,
                              state: 1,
                            })
                          }
                        >
                          Delete
                        </span>
                      )}
                      {deleteConfirm.state == 1 &&
                        deleteConfirm.itemId == budget.id && (
                          <>
                            <p className='text-red-700'> Are You Sure? </p>

                            <button
                              className='outline-button-danger'
                              onClick={(event) => handleDelete(event, budget)}
                            >
                              Yes
                            </button>
                            <span className='mr-2'></span>
                            <button
                              className='outline-button-success'
                              onClick={() => setDeleteConfirm({})}
                            >
                              No
                            </button>
                          </>
                        )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
