import React from "react";
// import { useSelector } from "react-redux";
import can from "../../Middleware/Permission";

// import { useSelector } from "react-redux";
import SideBarLink from "./SideBarLink";
function SideBar() {
  // const authData = useSelector((state) => state.auth);
  // const authData = useSelector((state) => state.auth);

  const staffMenu = [
    { name: "Dashboard", link: "/dashboard", icon: "DesktopComputerIcon" },
    // { name: "List of Students", link: "/old-students" },
    // { name: "Verified Students", link: "/admitted-students" },
  ];

  if (can("Submit Budget")) {
    staffMenu.push({
      name: "Budget Receipt Entry",
      link: "/budget-proposal-entry/receipt",
      icon: "CurrencyRupeeIcon",
    });
    staffMenu.push({
      name: "Budget Payment Entry",
      link: "/budget-proposal-entry/payment",
      icon: "CurrencyRupeeIcon",
    });
    staffMenu.push({
      name: "Budget Final Submit",
      link: "/budget-proposal-submit",
      icon: "CurrencyRupeeIcon",
    });
  }

  if (can("Account Masters")) {
    staffMenu.push({
      name: "Budget Summary (Proposals)",
      link: "/budget-summary",
      icon: "CurrencyRupeeIcon",
    });
    staffMenu.push({
      name: "Prepare Budget",
      link: "/prepare-budget",
      icon: "CurrencyRupeeIcon",
    });

    staffMenu.push({
      name: "Budget Summary (Prepared)",
      link: "/budget-summary-prepared",
      icon: "CurrencyRupeeIcon",
    });

    staffMenu.push({
      name: "Budget Heads",
      link: "/budget-heads",
      icon: "CurrencyRupeeIcon",
    });
    staffMenu.push({
      name: "Budget Master",
      link: "/budget-master",
      icon: "CurrencyRupeeIcon",
    });
    staffMenu.push({
      name: "Cost Centers",
      link: "/cost-centers",
      icon: "CurrencyRupeeIcon",
    });

    staffMenu.push({
      name: "Assign Heads (Receipt)",
      link: "/assign-budget-heads/receipt",
      icon: "CurrencyRupeeIcon",
    });
    staffMenu.push({
      name: "Assign Heads (Payment)",
      link: "/assign-budget-heads/payment",
      icon: "CurrencyRupeeIcon",
    });

    staffMenu.push({
      name: "Block Previous Budgets",
      link: "/budget-blocks",
      icon: "CurrencyRupeeIcon",
    });

    staffMenu.push({
      name: "Measurement Units",
      link: "/measurement-units",
      icon: "CheckCircleIcon",
    });
    // staffMenu.push({
    //   name: "Vendor Create",
    //   link: "/vendors/create",
    //   icon: "CurrencyRupeeIcon",
    // });
    // staffMenu.push({
    //   name: "Items Create",
    //   link: "/items/create",
    //   icon: "CurrencyRupeeIcon",
    // });
  }

  if (can("Account Proposal Entry")) {
    staffMenu.push({
      name: "Submit Proposal",
      link: "/proposals/create",
      icon: "CheckCircleIcon",
    });
    staffMenu.push({
      name: "View Proposals",
      link: "/proposals",
      icon: "CheckCircleIcon",
    });
  }

  if (can("View Proposals")) {
    staffMenu.push({
      name: "View Proposals",
      link: "/proposals",
      icon: "CheckCircleIcon",
    });
  }

  if (can("Can Audit")) {
    staffMenu.push({
      name: "View Proposals",
      link: "/proposals",
      icon: "CheckCircleIcon",
    });
  }
  if (can("Add Bills")) {
    staffMenu.push({
      name: "View Proposals",
      link: "/proposals",
      icon: "CheckCircleIcon",
    });
  }
  if (can("Stock In-charge")) {
    staffMenu.push({
      name: "Receive Item",
      link: "/check-delivery",
      icon: "CheckCircleIcon",
    });
    staffMenu.push({
      name: "Stock Registers",
      link: "/stock-registers",
      icon: "CheckCircleIcon",
    });
  }
  if (can("Account Masters")) {
    staffMenu.push({
      name: "Proposal Report",
      link: "/proposal-report",
      icon: "CheckCircleIcon",
    });
    staffMenu.push({
      name: "Fund Position Report",
      link: "/fund-position-report",
      icon: "CheckCircleIcon",
    });

    staffMenu.push({
      name: "Budget Reappropriation",
      link: "/budget-reappropriations/create",
      icon: "CheckCircleIcon",
    });
    staffMenu.push({
      name: "Budget Reappropriation History",
      link: "/budget-reappropriations",
      icon: "CheckCircleIcon",
    });
  }

  if (can("Voucher Entry") || can("Voucher View All")) {
    staffMenu.push({
      name: "Voucher Entry Portal",
      link: "/voucher-portal",
      icon: "CurrencyRupeeIcon",
    });
  }
  if (can("Salary Process")) {
    staffMenu.push({
      name: "Payroll Portal",
      link: "/payroll-portal",
      icon: "CurrencyRupeeIcon",
    });
  }

  // if (authData.panel != "department" && can("Fee Collection")) {
  //   partnerMenu.push({
  //     name: "Fee Collection By Hostel",
  //     link: "/hostel-fee-collection",
  //     icon: "CurrencyRupeeIcon",
  //   });
  // }

  return (
    <aside className='hidden h-full shadow-md w-60 bg-gray sm:block '>
      <ul className='relative px-1'>
        <li className='relative'>
          <hr />

          {staffMenu.length > 0 &&
            staffMenu.map((item, index) => (
              <SideBarLink
                key={index}
                to={item.link}
                icon={item.icon}
                label={item.name}
              />
            ))}
        </li>
      </ul>
    </aside>
  );
}

export default SideBar;
