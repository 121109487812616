import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Input from "../../../Components/Utils/Input";
import BaseApi from "../../../Http/BaseApi";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../Components/Utils/Button";
import ValidationErrors from "../../../Components/Utils/ValidationErrors";
import CustomSelect from "../../../Components/Utils/CustomSelect";

export default function VendorEntry({ setVendorPopup }) {
  const { proposalNature } = useParams();
  console.log(proposalNature);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const formikRef = useRef();
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    nature: "",
    person_name: "",
    address: "",
    pin: "",
    phone: "",
    pan: "",
    gst: "",
    account_no: "",
    bank_name: "",
    ifsc: "",
    email: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    nature: Yup.string().required("This field is required!"),
    person_name: Yup.string().required("This field is required!"),
    address: Yup.string().required("This field is required!"),
    pin: Yup.string().required("This field is required!"),

    phone: Yup.string().required("This field is required!"),
    pan: Yup.string().required("This field is required!"),
    account_no: Yup.string().required("This field is required!"),
    bank_name: Yup.string().required("This field is required!"),
    ifsc: Yup.string().required("This field is required!"),
    email: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    // if (itemCategories.length == 0) {
    //   getItemCategories();
    // }
  }, [dispatch]);

  const handleSubmit = async (payload) => {
    await BaseApi()
      .post(`vendors`, payload)
      .then((vendor) => {
        console.log(vendor);
        setSuccess(true);
        setVendorPopup(false);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        setValidationErrors(error.response.data.errors);
      });
  };

  return (
    <>
      <div className='container mx-auto'>
        <h1 className='text-2xl font-bold mb-4'>Create a new Vendor</h1>

        {success && (
          <p className='text-green-500 mb-4'>Vendor created successfully!</p>
        )}
        {errorMessage && <p className='text-red-500 mb-4'>{errorMessage}</p>}
        <ValidationErrors className='my-5' errors={validationErrors} />
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className='space-y-4'>
              <div className='grid grid-cols-3 gap-2'>
                <Input
                  label='Name of the Business'
                  placeholder='Enter Name of the Business'
                  type='text'
                  name='name'
                />
                <CustomSelect
                  options={[
                    { name: "Proprietorship" },
                    { name: "Partnership Firm" },
                    { name: "Company" },
                    { name: "Other" },
                  ]}
                  name='nature'
                  label='Select Nature of Business'
                  isSearchable={false}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option?.name}`}
                  onChange={async (selectedOption) => {
                    // console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "nature",
                      selectedOption.name
                    );
                  }}
                />
                <div className='col-span-1'>
                  <Input
                    label='Name of Proprietor/Partner/Director'
                    placeholder='Enter Name of Proprietor/Partner/Director'
                    type='text'
                    name='person_name'
                  />
                </div>
                <Input
                  label='Registered Address'
                  placeholder='Enter Registered Address'
                  type='text'
                  name='address'
                />
                <Input
                  label='Area PIN'
                  placeholder='Enter Area PIN'
                  type='text'
                  name='pin'
                />
                <Input
                  label='Enter Mobile No.'
                  placeholder='Enter Mobile No.'
                  type='text'
                  name='phone'
                />
                <Input
                  label='Enter Email'
                  placeholder='Enter Email'
                  type='email'
                  name='email'
                />
                <Input
                  label='Enter PAN'
                  placeholder='Enter PAN'
                  type='text'
                  name='pan'
                />
                <Input
                  label='Enter GST'
                  placeholder='Enter GST'
                  type='text'
                  name='gst'
                />
                <Input
                  label='Enter Bank Account No.'
                  placeholder='Enter Bank Account No.'
                  type='text'
                  name='account_no'
                />
                <Input
                  label='Enter Bank Name and Branch'
                  placeholder='Enter Bank Name and Branch'
                  type='text'
                  name='bank_name'
                />
                <Input
                  label='Enter IFSC'
                  placeholder='Enter IFSC'
                  type='text'
                  name='ifsc'
                />
              </div>

              <div className='flex justify-center'>
                {" "}
                <Button
                  type={"submit"}
                  // className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  disabled={isSubmitting}
                  value={"Create Vendor"}
                  icon={"SaveIcon"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
