import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import BaseApi from "../../../../Http/BaseApi";
import Input from "../../../../Components/Utils/Input";
import ValidationErrors from "../../../../Components/Utils/ValidationErrors";
export default function DateExtendModal({
  selectedProposal,

  handlePopupClose,
}) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [errorMessage, setErrorMessage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [initialValues, setInitialValues] = useState();
  const validationSchema = Yup.object().shape({
    order_last_date: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    setInitialValues({
      order_last_date: selectedProposal?.order_last_date,
      proposal_id: selectedProposal?.id,
    });
  }, [dispatch]);

  const handleSubmit = async (payload) => {
    BaseApi()
      .post(`/extend-order-last-date`, payload, {})
      .then((response) => {
        handlePopupClose({ updatedProposal: response.data });
      })
      .catch((error) => {
        console.log("error is", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        setValidationErrors(error.response.data.errors);
      });
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div
            className={
              "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none"
            }
          >
            <div className='relative lg:w-2/6	 my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>
                    Update Order Last Date
                  </h3>
                  <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative   p-4 flex-auto'>
                  <div className='my-1    text-sm '>
                    <div className='relative p-2 flex-auto'>
                      {errorMessage && (
                        <p className='text-red-500 mb-4'>{errorMessage}</p>
                      )}
                      <ValidationErrors
                        className='my-5'
                        errors={validationErrors}
                      />
                      <div>Proposal ID: {selectedProposal.proposal_id}</div>
                      <div>
                        Cost Center: {selectedProposal.acc_cost_center?.name}
                      </div>
                      <br />
                      <Input type='hidden' name='proposal_id' />
                      <Input
                        label='Order Last Date'
                        type='date'
                        name='order_last_date'
                        placeholder='Order Last DateDate'
                      />{" "}
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handlePopupClose}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className='opacity-25 fixed inset-0 z-30 bg-black'></div>
    </>
  );
}
