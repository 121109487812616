import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "../Http/BaseApi";
import budgetSlice from "./BudgetSlice";
// import messageSlice from "./MessageSlice";
// import messageSlice from "./MessageSlice";

export const getAccPaymentHeadsAsync = createAsyncThunk(
  "master/getAccPaymentHeadsAsync",
  async () => {
    const response = await BaseApi().get(`acc-payment-heads`);
    // console.log(response.data);
    return response.data;
  }
);

export const addAccPaymentHeadAsync = createAsyncThunk(
  "master/addAccPaymentHeadAsyncAcc",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("acc-payment-heads", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateAccPaymentHeadAsync = createAsyncThunk(
  "master/updateAccPaymentHeadAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `acc-payment-heads/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteAccPaymentHeadAsync = createAsyncThunk(
  "master/deleteAccPaymentHeadAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`acc-payment-heads/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeleteAccPaymentHeadsAsync = createAsyncThunk(
  "master/bulkDeleteAccPaymentHeadsAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`acc-payment-heads/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCostCentersAsync = createAsyncThunk(
  "master/getCostCentersAsync",
  async () => {
    console.log("master cost");
    const response = await BaseApi().get(`cost-centers`);
    // console.log(response.data);
    return response.data;
  }
);

export const addCostCenterAsync = createAsyncThunk(
  "master/addCostCenterAsyncAcc",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("cost-centers", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCostCenterAsync = createAsyncThunk(
  "master/updateCostCenterAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `cost-centers/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteCostCenterAsync = createAsyncThunk(
  "master/deleteCostCenterAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`cost-centers/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

////////////////////////////////////////////////////////////////////////

export const getBudgetSuperMastersAsync = createAsyncThunk(
  "master/getBudgetSuperMastersAsync",
  async () => {
    console.log("master cost");
    const response = await BaseApi().get(`budget-super-masters`);
    // console.log(response.data);
    return response.data;
  }
);

/////////

export const getBudgetMastersAsync = createAsyncThunk(
  "master/getBudgetMastersAsync",
  async () => {
    console.log("master cost");
    const response = await BaseApi().get(`budget-masters`);
    // console.log(response.data);
    return response.data;
  }
);

export const addBudgetMasterAsync = createAsyncThunk(
  "master/addBudgetMasterAsyncAcc",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("budget-masters", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateBudgetMasterAsync = createAsyncThunk(
  "master/updateBudgetMasterAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `budget-masters/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteBudgetMasterAsync = createAsyncThunk(
  "master/deleteBudgetMasterAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`budget-masters/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(budgetSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = {
  costCenters: [],
  accPaymentHeads: [],
  budgetSuperMasters: [],
  budgetMasters: [],

  errorMessage: "",
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {},
  extraReducers: {
    [getAccPaymentHeadsAsync.fulfilled]: (state, action) => {
      return { ...state, accPaymentHeads: action.payload };
    },

    [addAccPaymentHeadAsync.fulfilled]: (state, action) => {
      return { ...state, accPaymentHeads: action.payload };
    },

    [updateAccPaymentHeadAsync.fulfilled]: (state, action) => {
      return { ...state, accPaymentHeads: action.payload };
    },

    [deleteAccPaymentHeadAsync.fulfilled]: (state, action) => {
      return { ...state, accPaymentHeads: action.payload };
    },
    [bulkDeleteAccPaymentHeadsAsync.fulfilled]: (state, action) => {
      let accPaymentHeads = state.accPaymentHeads.filter((head) => {
        return !action.payload.selectedIds.includes(head.id.toString());
      });

      return { ...state, accPaymentHeads: accPaymentHeads };
    },

    [getCostCentersAsync.fulfilled]: (state, action) => {
      return { ...state, costCenters: action.payload };
    },

    [addCostCenterAsync.fulfilled]: (state, action) => {
      state.costCenters.push(action.payload);
    },

    [updateCostCenterAsync.fulfilled]: (state, action) => {
      const index = state.costCenters.findIndex(
        (head) => head.id === action.payload.id
      );
      state.costCenters[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deleteCostCenterAsync.fulfilled]: (state, action) => {
      let costCenters = state.costCenters.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, costCenters: costCenters };
    },

    [getBudgetSuperMastersAsync.fulfilled]: (state, action) => {
      return { ...state, budgetSuperMasters: action.payload };
    },
    [getBudgetMastersAsync.fulfilled]: (state, action) => {
      return { ...state, budgetMasters: action.payload };
    },

    [addBudgetMasterAsync.fulfilled]: (state, action) => {
      state.budgetMasters.push(action.payload);
    },

    [updateBudgetMasterAsync.fulfilled]: (state, action) => {
      const index = state.budgetMasters.findIndex(
        (head) => head.id === action.payload.id
      );
      state.budgetMasters[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deleteBudgetMasterAsync.fulfilled]: (state, action) => {
      let budgetMasters = state.budgetMasters.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, budgetMasters: budgetMasters };
    },
  },
});

export default masterSlice.reducer;
