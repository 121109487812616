import "./App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CostCenterLogin from "./Pages/Auth/CostCenter/Login";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "./Components/Common/Layout";
import NoPage from "./Pages/NoPage";
// import axios from "axios";
import LandingPage from "./Pages/LandingPage";
// import Register from "./Pages/Auth/Department/Register";
import CostCenterRoute from "./Routes/CostCenterRoute";
import StaffRoute from "./Routes/StaffRoute";
import { useSelector } from "react-redux";
import StaffLogin from "./Pages/Auth/Staff/Login";
import MaintenancePage from "./Pages/MaintenancePage";
import ForgetPassword from "./Pages/Auth/Staff/ForgetPassword";
// import can from "./Middleware/Permission";
// axios.defaults.baseURL = "http://localhost:8000/api/";
toast.configure();
// this is test change for git
function App() {
  const authData = useSelector((state) => state.auth);
  const maintenance = false;
  const superadmin = JSON.parse(localStorage.getItem("superadmin"));

  return (
    <>
      <BrowserRouter>
        <Routes>
          {maintenance == true && superadmin != true && (
            <Route path='*' element={<MaintenancePage />} />
          )}
          {((maintenance == true && superadmin == true) ||
            maintenance == false) && (
            <>
              <Route index element={<LandingPage />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route path='/costcenter-login' element={<CostCenterLogin />} />
              <Route path='/staff-login' element={<StaffLogin />} />
              <Route path='/forgot-password' element={<ForgetPassword />} />
              {authData.panel == "costcenter" ? CostCenterRoute : null}
              {authData.panel == "staff" ? StaffRoute : null}
              <Route path='*' element={<NoPage />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
