import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "../../../Components/Common/PageTitle";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Input from "../../../Components/Utils/Input";
// import Input from "../../../Components/Utils/Input";
import * as Yup from "yup";
import BaseApi from "../../../Http/BaseApi";
import { ThreeDots } from "react-loader-spinner";
import Badge from "../../../Components/Utils/Badge";
import can from "../../../Middleware/Permission";
import Pagination from "../../../Components/Utils/Pagination";
import Alert from "../../../Components/Utils/Alert";
import ValidationErrors from "../../../Components/Utils/ValidationErrors";
import SearchButton from "../../../Components/Utils/SearchButton";
// import can from "../../../Middleware/Permission";

const OrderIndex = ({ tabChange }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState();
  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();
  // const navigate = useNavigate();

  const addBill = (e, proposal) => {
    setPopupOpen(true);
    setSelectedProposal(proposal);
    formikRef.current?.setFieldValue("proposal_id", proposal.id);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  const validationSchema = Yup.object().shape({
    // proposal_id: Yup.number().required("This field is required!"),
    bill_date: Yup.string().required("This field is required!"),
    bill_no: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
    amount: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    if (proposals.length === 0) {
      callOrders(null);
    }

    setInitialValues({
      bill_date: "",
      bill_no: "",
      type: "",
      amount: "",
      proposal_id: selectedProposal?.id,
    });
  }, [dispatch]);

  const callOrders = (url) => {
    setLoading(true);
    BaseApi()
      .get(url ?? `/proposals?type=order&search_term=${searchTerm}`)
      .then((response) => {
        setProposals(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
    setLoading(false);
  };
  const handleSubmit = async (payload) => {
    BaseApi()
      .post(`/generate-bill`, { ...payload, proposal_id: selectedProposal.id })
      .then(() => {
        setPopupOpen(false);
        tabChange(4);

        // BaseApi()
        //   .get(`/proposals?type=order`)
        //   .then((response) => {
        //     setProposals(response.data);
        //     // console.log('data is', response.data)
        //   })
        //   .catch((error) => {
        //     console.log("error is", error);
        //   });
        // console.log('data is', response.data)
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        setValidationErrors(error.response.data.errors);
      });
  };
  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5   '>
            <PageTitle name='List of Orders' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <div className='flex items-center gap-2 mb-3'>
              {" "}
              <input
                className='border border-gray-400 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                type='number'
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder='Order No/proposal No.'
              />
              <SearchButton
                onClick={() => {
                  callOrders(null);
                }}
              />
            </div>

            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Order Date
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Manual Order Number
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    System Order Number
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Cost Center
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Vendor
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>

                  <th scope='col' className='relative px-6 py-3'>
                    <span className='sr-only'>View</span>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {proposals.data?.length > 0 &&
                  proposals.data?.map((proposal) => (
                    <tr key={proposal.id}>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {moment(proposal.order_date).format("Do MMM YYYY")}{" "}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.manual_order_no}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.order_id}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.acc_cost_center?.name}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.vendor?.name}
                        </div>
                        <div className='text-sm text-gray-500'>
                          {proposal.vendor?.email}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm font-medium text-yellow-600'>
                          {" "}
                          {proposal.status == 2 && (
                            <Badge color='red' status='Ordered' />
                          )}
                          {proposal.status == 3 && (
                            <Badge color='orange' status='Partialy Receieved' />
                          )}
                          {proposal.status == 4 && (
                            <Badge color='green' status='Receieved' />
                          )}
                          {/* {propoasl.status == 5 && "Bill Added"} */}
                          {proposal.status >= 3 &&
                            proposal.status <= 4 &&
                            can("Add Bills") &&
                            proposal.stock_registers?.some(
                              (sr) => sr.bill_id == null
                            ) && (
                              <>
                                <br /> <br />
                                <a
                                  onClick={(e) => {
                                    addBill(e, proposal);
                                  }}
                                  to={`/proposals/${proposal.id}`}
                                  className='  border-2 p-1 border-indigo-700 text-indigo-600 hover:text-indigo-900'
                                >
                                  Enter Bill Details
                                </a>
                              </>
                            )}
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                        <Link
                          target={"_blank"}
                          to={`/proposals/${proposal.id}`}
                          className='text-indigo-600 hover:text-indigo-900'
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {proposals.total > proposals.per_page && (
              <div className='m-5'>
                <Pagination
                  links={proposals?.links}
                  onClick={(url) => {
                    callOrders(url);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {popupOpen && (
        <>
          {" "}
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                <div className='relative lg:w-2/6	 my-6 mx-auto max-w-3xl'>
                  {/*content*/}
                  <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                    {/*header*/}
                    <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                      <h3 className='text-3xl font-semibold'>
                        Enter Bill Details
                      </h3>
                      <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className='relative   p-4 flex-auto'>
                      <div className='my-1    text-sm '>
                        <div className='relative p-2 flex-auto'>
                          {errorMessage && <Alert message={errorMessage} />}
                          <ValidationErrors
                            className='my-5'
                            errors={validationErrors}
                          />
                          <div>
                            System W/O Number: {selectedProposal.order_id}
                          </div>
                          <div>
                            Manual W/O Number:{" "}
                            {selectedProposal.manual_order_no}
                          </div>
                          <div>Vendor: {selectedProposal.vendor?.name}</div>
                          <br />
                          <Input
                            label='Bill Date'
                            type='date'
                            name='bill_date'
                            placeholder='Bill Date'
                          />{" "}
                          <br />
                          <Input
                            label='Bill Number'
                            type='text'
                            name='bill_no'
                            placeholder='Bill Number'
                          />
                          <br />
                          <Input
                            label='Bill Amount'
                            type='number'
                            name='amount'
                            placeholder='Bill Amount'
                          />
                          <br />
                          <CustomSelect
                            options={[
                              { label: "Partial", value: "0" },
                              { label: "Final", value: "1" },
                            ]}
                            name='type'
                            label='Bill Type'
                            getOptionValue={(option) => `${option}`}
                            getOptionLabel={(option) => `${option.label} `}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              formikRef.current?.setFieldValue(
                                "type",
                                selectedOption.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                      <button
                        className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='button'
                        onClick={handlePopupClose}
                      >
                        Close
                      </button>
                      <button
                        className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='submit'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </>
  );
};

export default OrderIndex;
