import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";

import { useParams } from "react-router-dom";
import messageSlice from "../../../Redux/MessageSlice";
import { getAcademicSessionsAsync } from "../../../Redux/MasterSlice";
import {
  getAccPaymentHeadsAsync,
  getBudgetMastersAsync,
} from "../../../Redux/BudgetSlice";
import BaseApi from "../../../Http/BaseApi";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
import BudgetDetailsRowPrepared from "../Partials/BudgetDetailsRowPrepared";
import Button from "../../../Components/Utils/Button";
import tableToCSV from "../../../Components/Utils/TableToCSV";

// import Input from "../../Components/Utils/Input";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";

export default function PreparedBudgets() {
  const { type } = useParams();
  const { acc_budget_form_id } = useParams();

  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);

  const [budgets, setBudgets] = useState([]);
  const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);

  const academicSessions = [...master.academicSessions];
  const budgetMasters = [...budget.budgetMasters];
  const { message } = useSelector((state) => state.message);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    if (accPaymentHeads.length === 0) {
      dispatch(getAccPaymentHeadsAsync());
    }
    if (budgetMasters.length === 0) {
      dispatch(getBudgetMastersAsync());
    }
    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
    });

    callBudgets(acc_budget_form_id);
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);
  console.log(initialValues);

  let budgetMaster = budgetMasters.find((e) => e.id == acc_budget_form_id);
  console.log(budgetMaster);
  const accPaymentHeads = [
    ...budget.accPaymentHeads.filter(
      (ah) =>
        ah.type == (type == "receipt" ? 1 : 2) &&
        ah.acc_budget_master_id == budgetMaster?.acc_budget_master_id
    ),
  ];

  let currentSession = academicSessions.find((e) => {
    return e.id == budgetMaster?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
  });

  // const handleSubmit = async (payload) => {
  //   dispatch(messageSlice.actions.clearMessage());
  //   console.log(payload);
  //   setLoading(true);
  //   await BaseApi().post(`budget-budget-submit`, payload);

  //   setLoading(false);
  //   // resetForm({ academic_session_id: "", details: [] });

  //   // setInitialValues({
  //   //   academic_session_id: "",
  //   //   type: type,
  //   //   details: [],
  //   // });
  //   dispatch(messageSlice.actions.setMessage("Budget Submitted Successfully"));
  // };

  const callBudgets = async (acc_budget_form_id) => {
    setLoading(true);
    const _budgets = await BaseApi().get(`budgets`, {
      params: { acc_budget_form_id: acc_budget_form_id, cost_center_id: "all" },
    });

    setBudgets(_budgets.data);

    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
    });
    setLoading(false);
  };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle
              name={
                "Budget " +
                (type == "receipt" ? "Receipt " : "Payment ") +
                currentSession?.label +
                "  (Prepared)"
              }
            />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              <Form>
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8"></div>
                {loading == true && (
                  <>
                    <div className="flex">
                      <span className="m-auto">
                        <ThreeDots
                          height="100"
                          width="100"
                          color="grey"
                          ariaLabel="loading"
                        />
                      </span>
                    </div>
                  </>
                )}
                <h1 className="my-5 font-medium text-xl">
                  {" "}
                  {type == "receipt" ? "Receipt" : "Payment"} Side:
                </h1>
                <Button
                  type="button"
                  classNames="float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                  value={"Download CSV"}
                  onClick={() => tableToCSV("datatable")}
                  icon={"DownloadIcon"}
                />
                <table
                  id="datatable"
                  className="min-w-full mt-5 bg-white dark:bg-gray-800"
                >
                  <thead>
                    <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
                      <th className="table-head">Ledger Code</th>
                      <th className="table-head">Heads</th>
                      <th className="table-head">
                        {" "}
                        Actual{" "}
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 2;
                          })?.label
                        }
                      </th>
                      <th className="table-head">
                        {" "}
                        Budget{" "}
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 1;
                          })?.label
                        }
                      </th>
                      <th className="table-head">
                        {" "}
                        Revised{" "}
                        {
                          academicSessions.find((as) => {
                            return as.serial == currentSession?.serial - 1;
                          })?.label
                        }
                      </th>
                      <th className="table-head">
                        {" "}
                        Budget {currentSession?.label}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {accPaymentHeads.map((head, index) => {
                      return (
                        <>
                          <BudgetDetailsRowPrepared
                            head={head}
                            index={index}
                            budgets={budgets}
                            currentSession={currentSession}
                            budgetMaster={budgetMaster}
                            lastSession={lastSession}
                            last2Session={last2Session}
                            type={type}
                          />
                        </>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
                      <th className="table-head"></th>
                      <th className="table-head">Total</th>
                      <th className="table-head">
                        {budgets
                          .filter(
                            (p) =>
                              p.academic_session_id == last2Session.id &&
                              p.budget_type == 2
                          )
                          ?.reduce(
                            (partialSum, budget) =>
                              partialSum +
                              (parseInt(
                                budget[
                                  type == "receipt"
                                    ? "receipt_amount"
                                    : "payment_amount"
                                ]
                              ) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                      <th className="table-head">
                        {budgets
                          .filter(
                            (p) =>
                              p.academic_session_id == lastSession.id &&
                              p.budget_type == 0
                          )
                          ?.reduce(
                            (partialSum, budget) =>
                              partialSum +
                              (parseInt(
                                budget[
                                  type == "receipt"
                                    ? "receipt_amount"
                                    : "payment_amount"
                                ]
                              ) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                      <th className="table-head">
                        {budgets
                          .filter(
                            (p) =>
                              p.academic_session_id == lastSession.id &&
                              p.budget_type == 1
                          )
                          ?.reduce(
                            (partialSum, budget) =>
                              partialSum +
                              (parseInt(
                                budget[
                                  type == "receipt"
                                    ? "receipt_amount"
                                    : "payment_amount"
                                ]
                              ) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                      <th className="table-head">
                        {budgets
                          .filter(
                            (p) =>
                              p.academic_session_id == currentSession.id &&
                              p.budget_type == 0
                          )
                          ?.reduce(
                            (partialSum, budget) =>
                              partialSum +
                              (parseInt(
                                budget[
                                  type == "receipt"
                                    ? "receipt_amount"
                                    : "payment_amount"
                                ]
                              ) || 0),
                            0
                          )
                          .toFixed(2)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
                <hr className="m-10" />
              </Form>
            </Formik>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
