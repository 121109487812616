import { Form, Formik } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { useParams } from "react-router-dom";
import * as Yup from "yup";
import PageTitle from "../../../Components/Common/PageTitle";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Input from "../../../Components/Utils/Input";
import SearchButton from "../../../Components/Utils/SearchButton";
import BaseApi from "../../../Http/BaseApi";

const CheckItemDelivery = () => {
  const navigate = useNavigate();

  // const { id } = useParams();
  // const dispatch = useDispatch();
  const [proposal, setProposal] = useState();
  const [search, setSearch] = useState();
  const [initialValues, setInitialValues] = useState();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    proposal_id: Yup.string().required("This field is required!"),
    type: Yup.string().required("This field is required!"),
    receiving_date: Yup.string().required("This field is required!"),
    stockentry_no: Yup.string().required("This field is required!"),
    items: Yup.array()
      .of(
        Yup.object().shape({
          received_quantity: Yup.number()
            .typeError("Received quantity must be a number")
            .required("Received quantity is required")
            .min(0, "Received quantity cannot be negative")
            .max(
              Yup.ref("remaining_qty"),
              "Can't be greater than remaining quantity"
            ),
        })
      )
      .test(
        "at-least-one-received-quantity",
        "At least one item must have a received quantity greater than zero",
        (items) => {
          return items.some((item) => item.received_quantity > 0);
        }
      ),
  });
  // useEffect(() => {

  // }, [id]);

  async function checkData() {
    setLoading(true);

    try {
      const _proposal = await BaseApi().get(
        `proposals?type=order&order_no=${search}`
      );
      setProposal(_proposal.data);
      console.log(_proposal.data?.proposal_items);
      setInitialValues({
        proposal_id: _proposal.data.id,
        stockentry_no: "",
        remarks: "",
        receiving_date: "",
        type: "",
        items: _proposal.data?.proposal_items?.map(
          ({
            id,
            item,
            description,
            total_qty,
            measurement_unit,
            remaining_qty,
          }) => ({
            id,
            item,
            description,

            total_qty,
            measurement_unit,
            remaining_qty,
            received_quantity: 0,
          })
        ),
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message);
      // setErrorMessage(message);
    }

    setLoading(false);
  }
  console.log(initialValues);
  const handleSubmit = async (payload) => {
    BaseApi()
      .post(`/receive-items`, payload)
      .then(() => {
        navigate(`/stock-registers`);

        // setProposal(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
  };
  // const handleQuantityChange = (index, event) => {
  //   const newItems = [...items];
  //   newItems[index].quantity = parseInt(event.target.value, 10);
  //   setItems(newItems);
  // };
  return (
    <>
      {" "}
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}
      <div className='p-10'>
        <PageTitle name='Search Order' />
        <div className='flex items-center gap-2'>
          {" "}
          <input
            className='border border-gray-400 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
            type='number'
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder='Search by order number'
          />
          <SearchButton onClick={checkData} />
        </div>
      </div>
      {proposal && (
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className='p-4'>
              <div className='flex justify-between mb-5'>
                <h1 className='text-4xl font-bold'>
                  {" "}
                  {[0, 1].includes(proposal.status) && "Proposal View"}
                  {proposal.status == 2 && "Order View"}
                </h1>
                <div className='text-right'>
                  <p className='text-gray-700'>
                    Submitted By: {proposal.staff?.name}
                  </p>
                </div>
              </div>
              <div className='flex justify-between mb-5'>
                <div>
                  <p className='text-gray-700 font-semibold'>
                    Type: {proposal.proposal_nature?.name}
                  </p>
                  <p className='text-gray-700 font-semibold'>
                    Proposal ID: {proposal.proposal_id}{" "}
                  </p>
                  <p className='text-gray-700 font-semibold'>
                    Manual Ref. Number: {proposal.manual_ref_no}
                  </p>
                  <p className='text-gray-700 font-semibold'>
                    Proposal Date:{" "}
                    {moment(proposal.proposal_date).format("Do MMM YYYY")}
                  </p>
                  <p className='text-gray-700 mb-2 font-semibold'>
                    Cost Center: {proposal.acc_cost_center?.name}{" "}
                  </p>

                  {proposal.vendor && (
                    <>
                      <p className='text-gray-700 mb-1 font-semibold'>
                        Vendor Details
                      </p>
                      <p className='text-gray-700'>{proposal?.vendor?.name}</p>
                      <p className='text-gray-700'>
                        {proposal?.vendor?.address}
                      </p>

                      <p className='text-gray-700'>{proposal?.vendor?.phone}</p>
                    </>
                  )}
                </div>

                <div>
                  <p className='text-gray-700 font-semibold'>
                    System Order No.: {proposal.order_id}
                  </p>

                  <p className='text-gray-700 font-semibold'>
                    Manual Order No.: {proposal.manual_order_no}
                  </p>

                  <p className='text-gray-700 font-semibold'>
                    Order Date:{" "}
                    {moment(proposal.order_date).format("Do MMM YYYY")}
                  </p>
                </div>
                <div>
                  <p className='text-gray-700  font-semibold'>Necessity</p>
                  <p className='text-gray-700 mb-1'>{proposal.necessity}</p>
                  <p className='text-gray-700 font-semibold'>Remarks</p>
                  <p className='text-gray-700'>{proposal.remarks}</p>
                </div>
              </div>
              <table className='table-auto w-full mb-4'>
                <thead>
                  <tr className='bg-gray-200'>
                    <th className='px-4 py-2'>Item</th>
                    <th className='px-4 py-2'>Ordered Qty</th>
                    <th className='px-4 py-2'>Remaining Qty</th>
                    <th className='px-4 py-2'>Unit</th>
                    <th className='px-4 py-2'>
                      Received Qty <br />
                      <small>Enter the number of items you have received</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {initialValues?.items?.map((item, index) => (
                    <tr key={index}>
                      <td className='border px-4 py-2'>
                        {item.item?.name} <br />
                        <small> {item.description}</small>
                      </td>
                      <td className='border px-4 py-2'>{item.total_qty}</td>
                      <td className='border px-4 py-2'>{item.remaining_qty}</td>
                      <td className='border px-4 py-2'>
                        {" "}
                        {item.measurement_unit?.name}
                      </td>
                      <td className='border px-4 py-2'>
                        <Input
                          type='number'
                          name={`items[${index}].received_quantity`}
                          placeholder='Received Qty'
                        />

                        {/* <input
                        type='number'
                        //   value={item.quantity}
                        onChange={(event) => handleQuantityChange(index, event)}
                        className='w-full px-2 py-1 border-2'
                      /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='flex flex-row mt-8 gap-3'>
                <CustomSelect
                  options={[
                    { label: "Partial", value: "0" },
                    { label: "Final", value: "1" },
                  ]}
                  name='type'
                  label='Type'
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option.label} `}
                  onChange={(selectedOption) => {
                    console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "type",
                      selectedOption.value
                    );
                  }}
                />
                <div>
                  <Input
                    label='Receiving Date'
                    type='date'
                    name={`receiving_date`}
                    placeholder='Receiving Date'
                  />{" "}
                </div>
                <div>
                  <Input
                    label='Stock Entry Number'
                    type='text'
                    name={`stockentry_no`}
                    placeholder='Stock Entry Number'
                  />
                </div>
                <div>
                  <Input
                    label='Remarks'
                    type='text'
                    name={`remarks`}
                    placeholder='Remarks'
                  />
                </div>
              </div>
              <br />

              <button className='bg-blue-500 text-white px-4 py-2 rounded mr-4'>
                Submit
              </button>
              {/* <button className='bg-gray-500 text-white px-4 py-2 rounded'>
        Cancel
      </button> */}
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default CheckItemDelivery;
