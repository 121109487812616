import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Input from "../../../Components/Utils/Input";
import BaseApi from "../../../Http/BaseApi";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../Components/Utils/Button";
import ValidationErrors from "../../../Components/Utils/ValidationErrors";

export default function ItemEntry() {
  const { proposalNature } = useParams();
  console.log(proposalNature);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const formikRef = useRef();
  const [proposalNatures, setProposalNatures] = useState([]);
  const [selectedProposalNature, setSelectedProposalNature] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const [initialValues, setInitialValues] = useState({});
  const [itemCategories, setItemCatrgories] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    item_category_id: Yup.string().required("This field is required!"),
    name: Yup.string().required("This field is required!"),
    proposal_nature_id: Yup.string().required("This field is required!"),
  });
  useEffect(() => {
    // if (itemCategories.length == 0) {
    //   getItemCategories();
    // }
    setInitialValues({
      name: "",
      item_category_id: "",
      proposal_nature_id: "",
    });
    if (proposalNatures.length == 0) {
      getProposalNatures();
    }
  }, [dispatch]);

  async function getProposalNatures() {
    setLoading(true);
    await BaseApi()
      .get(`/proposal-natures`)
      .then((data) => {
        let _pn = data.data;
        setProposalNatures(_pn);
        console.log("-------", proposalNatures);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        console.log(errorMessage);
      });

    setLoading(false);

    // setProposalNature([
    //   {name: 'Test', id: 1}
    //   ,{name: 'Test2', id: 2}
    // ])
  }

  async function getItemCategories(proposalNatureId) {
    setLoading(true);
    await BaseApi()
      .get(`/item-categories?proposal_nature_id=${proposalNatureId}`)
      .then((data) => {
        let _ic = data.data;
        setItemCatrgories(_ic);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        console.log(errorMessage);
      });

    setLoading(false);

    // setItemCatrgory([
    //   { name: "Kareng Technologies", id: 1 },
    //   { name: "TCS", id: 2 },
    // ]);
  }

  const handleSubmit = async (payload) => {
    setSuccess(false);
    setErrorMessage("");
    setValidationErrors([]);
    await BaseApi()
      .post(`items`, payload)
      .then((item) => {
        console.log(item);
        setSuccess(true);
        setInitialValues({
          name: "",
          item_category_id: "",
          proposal_nature_id: initialValues.proposal_nature_id,
        });
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        setValidationErrors(error.response.data.errors);
      });
  };

  return (
    <>
      <div className='container mx-auto'>
        <h1 className='text-2xl font-bold mb-4'>Create a new Item</h1>
        {loading == true && (
          <>
            <div className='flex'>
              <span className='m-auto'>
                <ThreeDots
                  height='100'
                  width='100'
                  color='grey'
                  ariaLabel='loading'
                />
              </span>
            </div>
          </>
        )}
        {success && (
          <p className='text-green-500 mb-4'>Item created successfully!</p>
        )}
        {errorMessage && <p className='text-red-500 mb-4'>{errorMessage}</p>}
        <ValidationErrors className='my-5' errors={validationErrors} />
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className='space-y-4'>
              <div className='flex flex-col'>
                <Input
                  label='Enter Item Name'
                  placeholder='Enter Item Name'
                  type='text'
                  name='name'
                  className='border rounded-md p-2'
                />
              </div>
              <div className='flex flex-col'>
                <CustomSelect
                  options={proposalNatures}
                  name='proposal_nature_id'
                  label='Select Nature'
                  isSearchable={true}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option?.name}`}
                  onChange={async (selectedOption) => {
                    console.log(
                      "pn--------",
                      formikRef.current?.values?.proposal_nature_id
                    );
                    setSelectedProposalNature(selectedOption);
                    formikRef.current?.setFieldValue(
                      "proposal_nature_id",
                      selectedOption.id
                    );
                    getItemCategories(selectedOption.id);
                    // setPnvalue(selectedOption.id)
                    // console.log('pn--------',pn_value);
                  }}
                />
              </div>
              <div className='flex flex-col'>
                <CustomSelect
                  options={itemCategories.filter(
                    (ld) => ld.proposal_nature_id == selectedProposalNature.id
                  )}
                  name='item_category_id'
                  label='Select Item Category'
                  isSearchable={true}
                  getOptionValue={(option) => `${option}`}
                  getOptionLabel={(option) => `${option?.name}`}
                  onChange={async (selectedOption) => {
                    // console.log(selectedOption);
                    formikRef.current?.setFieldValue(
                      "item_category_id",
                      selectedOption.id
                    );
                  }}
                />
              </div>
              <div className='flex justify-center'>
                {" "}
                <Button
                  type={"submit"}
                  // className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  disabled={isSubmitting}
                  value={"Create Item"}
                  icon={"SaveIcon"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
