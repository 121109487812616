import React from "react";
// import { useNavigate } from "react-router-dom";

export default function FundPositionReportRow({
  head,
  index,
  budgets,
  vouchers,
  expenses,
  budgetBlocks,
  currentSession,
  budgetMaster,
  lastSession,
  last2Session,
  type,
}) {
  // const navigate = useNavigate();

  if (head.children && head.children.length > 0) {
    let children = head.children;
    return (
      <>
        <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
          <td className='table-data'>{head.code}</td>
          <td key={index} colSpan='7' className=' table-data'>
            {head.name}
          </td>
        </tr>
        {children.map((head1, index1) => {
          return (
            <>
              <FundPositionReportRow
                head={head1}
                index={index1}
                budgets={budgets}
                vouchers={vouchers}
                expenses={expenses}
                budgetBlocks={budgetBlocks}
                currentSession={currentSession}
                budgetMaster={budgetMaster}
                lastSession={lastSession}
                last2Session={last2Session}
                type={type}
              />
            </>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
          <td className='table-data '>{head.code}</td>
          <td className='table-data '>
            <span
              className=''
              // onClick={() => {
              //   navigate(
              //     `/prepared-budgets/${type}/${budgetMaster.id}/${head.id}`
              //   );
              // }}
            >
              {head.name}
            </span>
          </td>

          <td className='table-data'>
            {/* budget current  */}
            {budgets
              ?.filter(
                (p) =>
                  p.acc_budget_form_id == budgetMaster.id &&
                  p.acc_payment_head_id == head.id &&
                  p.academic_session_id == currentSession.id &&
                  p.budget_type == 0 &&
                  p.draft == 0
              )
              ?.reduce(
                (partialSum, budget) =>
                  partialSum +
                  (parseInt(
                    budget[
                      type == "receipt" ? "receipt_amount" : "payment_amount"
                    ]
                  ) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className='table-data'>
            {/* revised budget current  */}
            {budgets
              ?.filter(
                (p) =>
                  p.acc_payment_head_id == head.id &&
                  p.academic_session_id == currentSession.id &&
                  p.budget_type == 1
              )
              ?.reduce(
                (partialSum, budget) =>
                  partialSum +
                  (parseInt(
                    budget[
                      type == "receipt" ? "receipt_amount" : "payment_amount"
                    ]
                  ) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className='table-data'>
            {/* Expenditure of current year */}
            {(
              budgetBlocks
                ?.filter(
                  (e) =>
                    e.acc_payment_head_id == head.id &&
                    e.academic_session_id == currentSession.id
                )
                ?.reduce(
                  (partialSum, expense) =>
                    partialSum + (parseInt(expense["amount"]) || 0),
                  0
                ) +
              vouchers
                ?.filter((v) => v.budget_code == head.code)
                .reduce(
                  (partialSum, voucher) =>
                    partialSum +
                    (parseInt(voucher["previous_year_balance"]) || 0),
                  0
                )
            ).toFixed(2)}
          </td>

          <td className='table-data'>
            {/* Calculate orders in hand */}
            {(
              expenses
                ?.filter(
                  (e) =>
                    e.acc_payment_head_id == head.id &&
                    e.status >= 2 &&
                    // Check that both accepted_date and order_date are in the current session
                    new Date(e.accepted_date).getTime() >=
                      new Date(currentSession.from_year).getTime() &&
                    new Date(e.accepted_date).getTime() <=
                      new Date(currentSession.to_year).getTime() &&
                    new Date(e.order_date).getTime() >=
                      new Date(currentSession.from_year).getTime() &&
                    new Date(e.order_date).getTime() <=
                      new Date(currentSession.to_year).getTime()
                )
                ?.reduce(
                  (partialSum, expense) =>
                    partialSum + (parseInt(expense["total_amount"]) || 0),
                  0
                ) -
              vouchers
                ?.filter((v) => v.budget_code == head.code)
                .reduce(
                  (partialSum, voucher) =>
                    partialSum +
                    (parseInt(voucher["current_year_balance"]) || 0),
                  0
                )
            ).toFixed(2)}
          </td>

          <td className='table-data'>
            {vouchers
              ?.filter((v) => v.budget_code == head.code)
              .reduce(
                (partialSum, voucher) =>
                  partialSum + (parseInt(voucher["current_year_balance"]) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className='table-data'>
            {/* clearance given */}
            {expenses
              ?.filter(
                (e) =>
                  e.acc_payment_head_id == head.id &&
                  e.status == 1 &&
                  // check accepted_date must be in current session)
                  new Date(e.accepted_date).getTime() >=
                    new Date(currentSession.from_year).getTime() &&
                  new Date(e.accepted_date).getTime() <=
                    new Date(currentSession.to_year).getTime()
              )
              ?.reduce(
                (partialSum, expense) =>
                  partialSum + (parseInt(expense["total_amount"]) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className='table-data'>
            {(
              budgetBlocks
                ?.filter(
                  (e) =>
                    e.acc_payment_head_id == head.id &&
                    e.academic_session_id == currentSession.id
                )
                ?.reduce(
                  (partialSum, expense) =>
                    partialSum + (parseInt(expense["amount"]) || 0),
                  0
                ) +
              vouchers
                ?.filter((v) => v.budget_code == head.code)
                .reduce(
                  (partialSum, voucher) =>
                    partialSum +
                    (parseInt(voucher["previous_year_balance"]) || 0),
                  0
                ) +
              expenses
                ?.filter(
                  (e) =>
                    e.acc_payment_head_id == head.id &&
                    e.status >= 2 &&
                    new Date(e.accepted_date).getTime() >=
                      new Date(currentSession.from_year).getTime() &&
                    new Date(e.accepted_date).getTime() <=
                      new Date(currentSession.to_year).getTime() &&
                    new Date(e.order_date).getTime() >=
                      new Date(currentSession.from_year).getTime() &&
                    new Date(e.order_date).getTime() <=
                      new Date(currentSession.to_year).getTime()
                )
                ?.reduce(
                  (partialSum, expense) =>
                    partialSum + (parseInt(expense["total_amount"]) || 0),
                  0
                ) +
              expenses
                ?.filter(
                  (e) =>
                    e.acc_payment_head_id == head.id &&
                    e.status == 1 &&
                    new Date(e.accepted_date).getTime() >=
                      new Date(currentSession.from_year).getTime() &&
                    new Date(e.accepted_date).getTime() <=
                      new Date(currentSession.to_year).getTime()
                )
                ?.reduce(
                  (partialSum, expense) =>
                    partialSum + (parseInt(expense["total_amount"]) || 0),
                  0
                )
            ).toFixed(2)}
          </td>
          <td className='table-data'>
            {(
              (budgets
                ?.filter(
                  (p) =>
                    p.acc_budget_form_id == budgetMaster.id &&
                    p.acc_payment_head_id == head.id &&
                    p.academic_session_id == currentSession.id &&
                    p.budget_type == 0 &&
                    p.draft == 0
                )
                ?.reduce(
                  (partialSum, budget) =>
                    partialSum +
                    (parseInt(
                      budget[
                        type == "receipt" ? "receipt_amount" : "payment_amount"
                      ]
                    ) || 0),
                  0
                ) || 0) +
                (budgets
                  ?.filter(
                    (p) =>
                      p.acc_payment_head_id == head.id &&
                      p.academic_session_id == currentSession.id &&
                      p.budget_type == 1
                  )
                  ?.reduce(
                    (partialSum, budget) =>
                      partialSum +
                      (parseInt(
                        budget[
                          type == "receipt"
                            ? "receipt_amount"
                            : "payment_amount"
                        ]
                      ) || 0),
                    0
                  ) || 0) -
                (budgetBlocks
                  ?.filter(
                    (e) =>
                      e.acc_payment_head_id == head.id &&
                      e.academic_session_id == currentSession.id
                  )
                  ?.reduce(
                    (partialSum, expense) =>
                      partialSum + (parseInt(expense["amount"]) || 0),
                    0
                  ) +
                  vouchers
                    ?.filter((v) => v.budget_code == head.code)
                    .reduce(
                      (partialSum, voucher) =>
                        partialSum +
                        (parseInt(voucher["previous_year_balance"]) || 0),
                      0
                    ) +
                  expenses
                    ?.filter(
                      (e) =>
                        e.acc_payment_head_id == head.id &&
                        e.status >= 2 &&
                        new Date(e.accepted_date).getTime() >=
                          new Date(currentSession.from_year).getTime() &&
                        new Date(e.accepted_date).getTime() <=
                          new Date(currentSession.to_year).getTime() &&
                        new Date(e.order_date).getTime() >=
                          new Date(currentSession.from_year).getTime() &&
                        new Date(e.order_date).getTime() <=
                          new Date(currentSession.to_year).getTime()
                    )
                    ?.reduce(
                      (partialSum, expense) =>
                        partialSum + (parseInt(expense["total_amount"]) || 0),
                      0
                    ) +
                  expenses
                    ?.filter(
                      (e) =>
                        e.acc_payment_head_id == head.id &&
                        e.status == 1 &&
                        new Date(e.accepted_date).getTime() >=
                          new Date(currentSession.from_year).getTime() &&
                        new Date(e.accepted_date).getTime() <=
                          new Date(currentSession.to_year).getTime()
                    )
                    ?.reduce(
                      (partialSum, expense) =>
                        partialSum + (parseInt(expense["total_amount"]) || 0),
                      0
                    )) || 0
            ).toFixed(2)}
          </td>
        </tr>
      </>
    );
  }
}
