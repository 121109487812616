import { TrashIcon } from "@heroicons/react/outline";
import React from "react";

export default function AddedItemsInProposal({
  selectedTaxType,
  items,
  itemCategories,
  measurementUnits,
  removeItem,
}) {
  return (
    <>
      <h3 className='mt-5 text-xl'>Added Items:</h3>
      {items.length == 0 && (
        <div className='p-10 mt-2 flex justify-center border-2 '>
          No items added. Please add atleast one item
        </div>
      )}
      {items.length > 0 && (
        <table
          id='datatable'
          className='min-w-full mt-2  bg-white dark:bg-gray-800'
        >
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='table-head'>
                #
              </th>
              <th scope='col' className='table-head'>
                Category
              </th>
              <th scope='col' className='table-head'>
                Item
              </th>
              <th scope='col' className='table-head'>
                Quantity
              </th>
              <th scope='col' className='table-head'>
                Unit
              </th>
              <th scope='col' className='table-head'>
                Rate
              </th>
              <th scope='col' className='table-head'>
                TAX (%)
              </th>
              <th scope='col' className='table-head'>
                Amount
              </th>
              <th scope='col' className='table-head'>
                {selectedTaxType.id == 3 && "Amount with Tax "}
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length > 0 &&
              items.map((item, index) => (
                <>
                  <tr key={item.id}>
                    <td className='table-data'>{index + 1}</td>
                    <td className='table-data'>
                      {
                        itemCategories.find(
                          (ic) => ic.id == item.item_category_id
                        )?.name
                      }
                    </td>
                    <td className='table-data'>{item.item_name}</td>
                    <td className='table-data'>{item.quantity}</td>
                    <td className='table-data'>
                      {
                        measurementUnits.find(
                          (mu) => mu.id == item.measurement_unit_id
                        )?.name
                      }
                    </td>

                    <td className='table-data'>{item.rate}</td>
                    <td className='table-data'>
                      {/* {item.tax > 0 ? item.tax : "Incl."} */}
                      {selectedTaxType.id == 1 && "Tax Incl."}
                      {selectedTaxType.id == 2 && "Tax Excl."}
                      {selectedTaxType.id == 3 && item.tax}
                    </td>
                    <td className='table-data'>{item.amount}</td>
                    <td className='table-data'>
                      {selectedTaxType.id == 3 && item.total_amount}
                      <button
                        type='button'
                        className='float-right'
                        onClick={() => {
                          removeItem(index);
                        }}
                      >
                        <TrashIcon className='w-4 text-red-500 ' />
                      </button>
                    </td>
                  </tr>
                  <tr key={item.id}>
                    <td className='table-data'></td>

                    <td colSpan='8' className='table-data'>
                      {item.description}
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td className='table-head'></td>
              <td className='table-head'></td>
              <td className='table-head'></td>
              <td className='table-head'>
                {items?.reduce((accumulator, item) => {
                  return accumulator + parseFloat(item.quantity);
                }, 0)}
              </td>
              <td className='table-head'></td>
              <td className='table-head'></td>
              <td className='table-head'></td>
              <td className='table-head'>
                {items?.reduce((accumulator, item) => {
                  return accumulator + parseFloat(item.amount);
                }, 0)}
              </td>
              <td className='table-head'>
                {selectedTaxType.id == 3 && (
                  <>
                    {" "}
                    {items?.reduce((accumulator, item) => {
                      return accumulator + parseFloat(item.total_amount);
                    }, 0)}{" "}
                  </>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      )}
    </>
  );
}
