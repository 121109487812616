import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PageTitle from "../../../Components/Common/PageTitle";
import BaseApi from "../../../Http/BaseApi";
// import Alert from "../../../Components/Utils/Alert";
// import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";

// import { toast } from "react-toastify";
// import moment from "moment";
// import { useReactToPrint } from "react-to-print";
// import { useSelector } from "react-redux";
import Badge from "../../../Components/Utils/Badge";
import moment from "moment";

export default function StockRegisterView() {
  const { id } = useParams();

  // const authData = useSelector((state) => state.auth);
  const [stock, setStock] = useState({});
  const [proposal, setProposal] = useState({});
  // const [item, setItem] = useState({});

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  // const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  // const [popupOpen, setPopupOpen] = useState(false);

  // const handleAcceptClick = () => {
  //   setPopupOpen(true);
  // };
  // const handlePopupClose = () => {
  //   setPopupOpen(false);
  // };

  useEffect(() => {
    async function checkData() {
      if (id && id > 0) {
        setLoading(true);

        try {
          const _stock = await BaseApi().get(`stock-registers/${id}`);
          setStock(_stock.data);
          setProposal(_stock.data.proposal);
          // const subTotal = items.reduce((acc, item) => acc + item.amount, 0);
          // const tax = 12 / 100; // 10%
          // const taxAmount = subTotal * tax;
          // const total = subTotal + taxAmount;
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(message);
          // setErrorMessage(message);
        }

        setLoading(false);
      }
    }
    checkData();
  }, [id]);

  // const callBudgetSuperMasters = () => {
  //   BaseApi()
  //     .get(`/acc-payment-heads`)
  //     .then((response) => {
  //       setSelectedBM(response.data);
  //       // console.log('data is', response.data)
  //     })
  //     .catch((error) => {
  //       console.log("error is", error);
  //     });
  // };

  // const Print = useReactToPrint({
  //   content: () => printRef.current,
  // });

  // let thClass =
  //   " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  // let tdClass =
  //   " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}
      <PageTitle name='' />

      <div className='p-10'>
        <div className='flex justify-between mb-5'>
          <h1 className='text-4xl font-bold'>Stock Details</h1>
          <div className='text-right'>
            {/* <p className='text-gray-700'>
              Submitted By: {proposal.staff?.name}
            </p> */}
          </div>
        </div>
        <div className='flex justify-between mb-5'>
          <div>
            <p className='text-gray-700 font-semibold'>
              Receiving Date: {stock.receiving_date}
            </p>
            <p className='text-gray-700 font-semibold'>
              Stock Entry No.: {stock.stockentry_no}
            </p>
            <p className='text-gray-700 font-semibold'>
              Remarks.: {stock.remarks}
            </p>
          </div>
        </div>
        <table className='w-full mb-5'>
          <thead>
            <tr className='border-b border-gray-500'>
              <th className='text-left pb-3 font-semibold'>Item</th>
              <th className='text-left pb-3 font-semibold'>Ordered Quantity</th>
              <th className='text-left pb-3 font-semibold'>
                Receieved Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {proposal?.proposal_items?.map((item, index) => (
              <>
                <tr key={index} className=''>
                  <td className='text-left py-3'>{item.item?.name}</td>
                  <td className='text-left py-3'>
                    {item.quantity} {item.measurement_unit?.name}{" "}
                  </td>
                  <td className='text-left py-3'>
                    {
                      stock.stock_details.find(
                        (sd) => sd.proposal_item_id == item.id
                      )?.received_quantity
                    }{" "}
                    {item.measurement_unit?.name}
                  </td>
                </tr>
                <tr key={index} className='border-b border-gray-500'>
                  <td colSpan={7} className='text-left py-3'>
                    {item.description}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        {proposal.status == 0 && <Badge color='red' status='Pending' />}
        {proposal.status == 1 && <Badge color='green' status='Accepted' />}
        {proposal.status == -1 && <Badge color='red' status='Rejected' />}

        {proposal.status == 2 && <Badge color='red' status='Ordered' />}
        {proposal.status == 3 && (
          <Badge color='red' status='Partialy Receieved' />
        )}
        {proposal.status == 4 && <Badge color='green' status='Receieved' />}

        {/* {proposal.status == 2 && <Badge color='green' status='Order Gene' />} */}

        {proposal.status > 0 && (
          <h3>Assigned Head: {proposal.acc_payment_head?.name}</h3>
        )}

        <p className='my-5 font-semibold text-xl'>Proposal Details</p>
        <div className='flex justify-between mb-5'>
          <div>
            <p className='text-gray-700 font-semibold'>
              Type: {proposal.proposal_nature?.name}
            </p>
            <p className='text-gray-700 font-semibold'>
              Proposal ID: {proposal.proposal_id}{" "}
            </p>
            <p className='text-gray-700 font-semibold'>
              Manual Ref. Number: {proposal.manual_ref_no}
            </p>
            <p className='text-gray-700 font-semibold'>
              Proposal Date:{" "}
              {moment(proposal.proposal_date).format("Do MMM YYYY")}
            </p>
            <p className='text-gray-700 mb-2 font-semibold'>
              Cost Center: {proposal.acc_cost_center?.name}{" "}
            </p>

            {proposal.vendor && (
              <>
                <p className='text-gray-700 mb-1 font-semibold'>
                  Vendor Details
                </p>
                <p className='text-gray-700'>{proposal?.vendor?.name}</p>
                <p className='text-gray-700'>{proposal?.vendor?.address}</p>

                <p className='text-gray-700'>{proposal?.vendor?.phone}</p>
              </>
            )}
          </div>

          <div>
            <p className='text-gray-700 font-semibold'>
              System Order No.: {proposal.order_id}
            </p>

            <p className='text-gray-700 font-semibold'>
              Manual Order No.: {proposal.manual_order_no}
            </p>

            <p className='text-gray-700 font-semibold'>
              Order Date: {moment(proposal.order_date).format("Do MMM YYYY")}
            </p>
          </div>
          <div>
            <p className='text-gray-700  font-semibold'>Necessity</p>
            <p className='text-gray-700 mb-1'>{proposal.necessity}</p>
            <p className='text-gray-700 font-semibold'>Remarks</p>
            <p className='text-gray-700'>{proposal.remarks}</p>
          </div>
        </div>
      </div>
    </>
  );
}
