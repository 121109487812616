import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
import { postLoginAsync } from "../../../Redux/AuthSlice";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
// import messageSlice from "../../../Redux/MessageSlice";

export default function StaffLogin() {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(message);
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    if (isLoggedIn) {
      console.log("sd");
      navigate("/dashboard");
      window.location.reload();
    }
  }, []);

  const initialValues = {
    username: "",
    password: "",
    panel: "staff",
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue, { resetForm }) => {
    setLoading(true);
    dispatch(postLoginAsync({ payload: formValue }))
      .unwrap()
      .then(() => {
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        resetForm();
        setLoading(false);
      });
  };

  return (
    <>
      <div
        // style={{
        //   backgroundImage: `url('/assets/background.jpg')`,
        //   backgroundSize: "cover",
        // }}
        className='flex items-center justify-center min-h-full px-4 py-12  sm:px-6 lg:px-8'
      >
        <div className='w-full max-w-md p-10 space-y-8 rounded-lg shadow-2xl'>
          <div>
            <img
              className='w-auto h-12 mx-auto rounded-full'
              src='https://erp.dibru.work/images/dibru.png'
              alt='Logo'
            />

            <h2 className='mt-6 text-3xl font-extrabold text-center text-gray-600'>
              Dibrugarh University ERP
            </h2>
            <h2 className='mt-1 text-xl font-medium text-center text-gray-600'>
              Login to continue...
            </h2>
          </div>
          {message && <Alert message={message} />}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <input type='hidden' name='remember' defaultValue='true' />
              <div className='-space-y-px '>
                <div className=''>
                  <Field
                    name='username'
                    type='text'
                    placeholder='Enter your registered email/contact no.'
                    className='relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                  />
                  <ErrorMessage
                    name='username'
                    component='div'
                    className='py-2 text-red-500'
                  />
                </div>

                <div className=''>
                  <Field
                    name='password'
                    type='password'
                    placeholder='Enter your password'
                    className='relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className='py-2 text-red-500'
                  />
                </div>
              </div>

              <div className='flex items-center justify-between my-10'>
                <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
                  />
                  <label
                    htmlFor='remember-me'
                    className='block ml-2 text-sm text-gray-900'
                  >
                    Remember me
                  </label>
                </div>

                <div className='text-sm'>
                  <a
                    href='/forgot-password'
                    className='font-medium text-indigo-600 hover:text-indigo-500'
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  disabled={loading}
                >
                  {loading && (
                    <span className='spinner-border spinner-border-sm'></span>
                  )}
                  <span>Login</span>
                </button>

                {/* <button
                type="button"
                
                onClick={login}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="w-5 h-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button> */}
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
