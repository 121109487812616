import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";

import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
import { getCostCentersAsync } from "../../../Redux/BudgetSlice";
// import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { ThreeDots } from "react-loader-spinner";
import BaseApi from "../../../Http/BaseApi";
import { Form, Formik } from "formik";
import { getAcademicSessionsAsync } from "../../../Redux/MasterSlice";

export default function BudgetCostCenterView() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const formikRef = useRef();

  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [accBudgetForm, setAccBudgetForm] = useState(false);
  const [submittedCostCenters, setSubmittedCostCenters] = useState([]);
  const [copiedCostCenters, setCopiedCostCenters] = useState([]);
  const [openBudgets, setOpenBudgets] = useState([]);
  const budget = useSelector((state) => state.budget);
  const costCenters = [...budget.costCenters];
  const [initialValues, setInitialValues] = useState();
  const master = useSelector((state) => state.master);

  const academicSessions = [...master.academicSessions];

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    setInitialValues({
      academic_session_id: "",
      acc_budget_form_id: "",
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (costCenters.length === 0) {
      dispatch(getCostCentersAsync());
    }
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    getOpenBudgets();
    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const getOpenBudgets = async () => {
    await BaseApi()
      .get(`get-open-budgets?from_admin=1`)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          // Assuming academic_session_id is a numeric value
          return b.academic_session_id - a.academic_session_id;
        });

        setOpenBudgets(sortedData);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const callSubmittedCostCenters = async (selectedOption) => {
    setLoading(true);
    const _costCenters = await BaseApi().get(`submitted-cost-centers`, {
      params: {
        acc_budget_form_id: selectedOption.id,
      },
    });

    setSubmittedCostCenters(_costCenters.data);

    setLoading(false);
  };
  const callCopiedCostCenters = async (selectedOption) => {
    setLoading(true);
    const _costCenters = await BaseApi().get(`copied-cost-centers`, {
      params: {
        acc_budget_form_id: selectedOption.id,
      },
    });

    setCopiedCostCenters(_costCenters.data);

    setLoading(false);
  };

  const acceptBudget = async (event, center) => {
    if (
      !window.confirm(
        "After coping you will be able to edit the submitted budgets as per your need. Are you sure?"
      ) == true
    ) {
      return;
    }
    event.target.disabled = true;
    setLoading(true);
    await BaseApi()
      .post(`accept-budget-proposals`, {
        acc_budget_form_id: accBudgetForm.id,
        acc_cost_center_id: center.id,
      })
      .then(() => {
        event.target.disabled = true;
        callSubmittedCostCenters(accBudgetForm);
        callCopiedCostCenters(accBudgetForm);
      })
      .catch(() => {
        event.target.disabled = false;

        alert("Something went Wrong");
      });

    setLoading(false);
  };
  console.log(message);
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Prepare Budget' />
            <small>Accept/Reject Proposals</small>
            {message && <Alert message={message} />}
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <br />
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Financial Year'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `  ${option.label}  `}
                    onChange={(selectedOption) => {
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                      formikRef.current?.setFieldValue(
                        "acc_budget_form_id",
                        ""
                      );
                      setInitialValues({
                        academic_session_id: selectedOption.id,
                        acc_budget_form_id: "",
                      });
                      // console.log(selectedOption.id);
                    }}
                  />
                  {initialValues?.academic_session_id > 0 && (
                    <CustomSelect
                      key={initialValues?.academic_session_id}
                      options={openBudgets.filter(
                        (ob) =>
                          ob.academic_session_id ==
                          initialValues?.academic_session_id
                      )}
                      name='acc_budget_form_id'
                      label='Select Budget Master'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) =>
                        `${option.budget_master?.name} - ${option.academic_session?.label}   `
                      }
                      onChange={(selectedOption) => {
                        setAccBudgetForm(selectedOption);
                        callSubmittedCostCenters(selectedOption);
                        callCopiedCostCenters(selectedOption);
                      }}
                    />
                  )}
                </div>
              </Form>
            </Formik>
            {submittedCostCenters.length > 0 && (
              <table
                id='datatable'
                className='min-w-full bg-white dark:bg-gray-800'
              >
                <thead>
                  <tr>
                    <th className={thClass + "w-16"}>#</th>
                    <th className={thClass}>Name</th>
                    <th className={thClass}>Cost Center Code</th>
                    <th className={thClass}>Revenue Center Code</th>
                    <th className={thClass}>Nodal Person</th>
                    <th className={thClass}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {costCenters.length > 0 &&
                    costCenters.map((center, i) => {
                      return (
                        <tr
                          key={center.id}
                          className='odd:bg-white even:bg-gray-50'
                        >
                          <td className={tdClass + "w-32"}>{++i}</td>
                          <td className={tdClass}>{center.name}</td>
                          <td className={tdClass}>{center.cost_center_code}</td>
                          <td className={tdClass}>
                            {center.revenue_center_code}
                          </td>
                          <td className={tdClass}>{center.staff?.name}</td>

                          <td className={tdClass}>
                            {submittedCostCenters?.some(
                              (scc) => scc.id == center.id
                            ) && (
                              <button
                                type='button'
                                className='px-2 py-1 m-2 font-light text-white rounded cursor-pointer bg-gradient-to-r from-pink-500 to-yellow-500 hover:bg-blue-700'
                                onClick={() => {
                                  window.open(
                                    `/budget-proposal-by-center/${center.id}/${accBudgetForm.id}`,
                                    "_blank"
                                  );
                                }}
                              >
                                View Original Proposal
                              </button>
                            )}
                            {submittedCostCenters?.some(
                              (scc) => scc.id == center.id
                            ) && (
                              <>
                                {!copiedCostCenters?.some(
                                  (ccc) => ccc.id == center.id
                                ) && (
                                  <>
                                    <button
                                      type='button'
                                      className='px-2 py-1 m-2 font-light text-white rounded cursor-pointer bg-gradient-to-r from-green-500 to-yellow-500 hover:bg-blue-700'
                                      onClick={(event) => {
                                        acceptBudget(event, center);
                                      }}
                                    >
                                      Copy Proposal
                                    </button>
                                  </>
                                )}
                                {copiedCostCenters?.some(
                                  (ccc) => ccc.id == center.id
                                ) && (
                                  <>
                                    <button
                                      type='button'
                                      className='px-2 py-1 m-2 font-light text-white rounded cursor-pointer bg-gradient-to-r from-orange-700 to-red-700 hover:bg-blue-700'
                                      onClick={() => {
                                        window.open(
                                          `/budget-by-center/${center.id}/${accBudgetForm.id}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      Edit Copied Budget
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
