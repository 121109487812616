import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
// import Input from "../../Components/Utils/Input";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import moment from "moment";
import BaseApi from "../../../Http/BaseApi";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Button from "../../../Components/Utils/Button";
import messageSlice from "../../../Redux/MessageSlice";
import { getAcademicSessionsAsync } from "../../../Redux/MasterSlice";
import tableToCSV from "../../../Components/Utils/TableToCSV";

export default function BudgetSummaryPrepared() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const master = useSelector((state) => state.master);
  const academicSessions = [...master.academicSessions];
  const { message } = useSelector((state) => state.message);
  const [openBudgets, setOpenBudgets] = useState([]);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    getOpenBudgets();
    setInitialValues({
      academic_session_id: "",
      acc_budget_form_id: "",
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);
  console.log(initialValues);
  let currentSession = academicSessions.find((e) => {
    return e.id == formikRef.current?.values?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
  });

  const getOpenBudgets = async () => {
    await BaseApi()
      .get(`get-open-budgets?from_admin=1`)
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          // Assuming academic_session_id is a numeric value
          return b.academic_session_id - a.academic_session_id;
        });

        setOpenBudgets(sortedData);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (payload) => {
    if (!window.confirm("Are you sure?")) return;

    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);
    setLoading(true);
    await BaseApi().post(`budget-final-submit`, payload);

    setLoading(false);
    // resetForm({ academic_session_id: "", details: [] });

    // setInitialValues({
    //   academic_session_id: "",
    //   type: type,
    //   details: [],
    // });
    dispatch(messageSlice.actions.setMessage("Budget Submitted Successfully"));

    callBudgets({
      id: formikRef.current?.values?.acc_budget_form_id,
      academic_session_id: formikRef.current?.values?.academic_session_id,
    });
  };

  const callBudgets = async (selectedOption) => {
    setLoading(true);
    const _budgets = await BaseApi().get(`budgets`, {
      params: {
        acc_budget_form_id: selectedOption.id,
        cost_center_id: "all",
      },
    });

    setBudgets(_budgets.data);

    setInitialValues({
      acc_budget_form_id: selectedOption.id,
      academic_session_id: selectedOption.academic_session_id,
    });
    setLoading(false);
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Budget Summary (Prepared)' />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Financial Year'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `  ${option.label}  `}
                    onChange={(selectedOption) => {
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                      formikRef.current?.setFieldValue(
                        "acc_budget_form_id",
                        ""
                      );
                      setInitialValues({
                        academic_session_id: selectedOption.id,
                        acc_budget_form_id: "",
                      });
                      // console.log(selectedOption.id);
                    }}
                  />
                  {initialValues?.academic_session_id > 0 && (
                    <CustomSelect
                      key={initialValues?.academic_session_id}
                      options={openBudgets.filter(
                        (ob) =>
                          ob.academic_session_id ==
                          initialValues?.academic_session_id
                      )}
                      name='acc_budget_form_id'
                      label='Select Budget Master'
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) =>
                        `${option.budget_master?.name} - ${option.academic_session?.label} `
                      }
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "acc_budget_form_id",
                          selectedOption.id
                        );

                        formikRef.current?.setFieldValue(
                          "academic_session_id",
                          selectedOption.academic_session_id
                        );

                        callBudgets(selectedOption);
                      }}
                    />
                  )}
                </div>
                {loading == true && (
                  <>
                    <div className='flex'>
                      <span className='m-auto'>
                        <ThreeDots
                          height='100'
                          width='100'
                          color='grey'
                          ariaLabel='loading'
                        />
                      </span>
                    </div>
                  </>
                )}
                <Button
                  type='button'
                  classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                  value={"Download CSV"}
                  onClick={() => tableToCSV("datatable")}
                  icon={"DownloadIcon"}
                />
                {budgets?.length > 0 && (
                  <>
                    <table
                      id='datatable'
                      className='min-w-full mt-5 bg-white dark:bg-gray-800'
                    >
                      <thead>
                        <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
                          <th className='table-head'>Particulars</th>
                          <th className='table-head'>
                            {" "}
                            Actual
                            {
                              academicSessions.find((as) => {
                                return as.serial == currentSession?.serial - 2;
                              })?.label
                            }
                          </th>
                          <th className='table-head'>
                            {" "}
                            Budget
                            {
                              academicSessions.find((as) => {
                                return as.serial == currentSession?.serial - 1;
                              })?.label
                            }
                          </th>
                          <th className='table-head'>
                            {" "}
                            Revised
                            {
                              academicSessions.find((as) => {
                                return as.serial == currentSession?.serial - 1;
                              })?.label
                            }
                          </th>
                          <th className='table-head'>
                            {" "}
                            Budget {currentSession?.label}
                          </th>
                          <th className='table-head'>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
                          <td className='table-data'> Total Receipt</td>
                          <td className='table-data'>
                            {" "}
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == last2Session.id &&
                                  p.budget_type == 2
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.receipt_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {" "}
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == lastSession.id &&
                                  p.budget_type == 0
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.receipt_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {" "}
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == lastSession.id &&
                                  p.budget_type == 1
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.receipt_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == currentSession.id &&
                                  p.budget_type == 0
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.receipt_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {" "}
                            <Button
                              classNames='bg-gradient-to-r from-pink-500 to-yellow-500 hover:bg-blue-700 focus:ring-blue-500'
                              value={"Details"}
                              icon={"ChevronDoubleRightIcon"}
                              onClick={() => {
                                navigate(
                                  `/prepared-budgets/receipt/${formikRef.current.values?.acc_budget_form_id}`
                                );
                              }}
                            />
                          </td>
                        </tr>
                        <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
                          <td className='table-data'>Total Payment</td>
                          <td className='table-data'>
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == last2Session.id &&
                                  p.budget_type == 2
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.payment_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == lastSession.id &&
                                  p.budget_type == 0
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.payment_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == lastSession.id &&
                                  p.budget_type == 1
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.payment_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            {budgets
                              .filter(
                                (p) =>
                                  p.academic_session_id == currentSession.id &&
                                  p.budget_type == 0
                              )
                              ?.reduce(
                                (partialSum, budget) =>
                                  partialSum +
                                  (parseInt(budget.payment_amount) || 0),
                                0
                              )
                              .toFixed(2)}
                          </td>
                          <td className='table-data'>
                            <Button
                              classNames='bg-gradient-to-r from-pink-500 to-yellow-500 hover:bg-blue-700 focus:ring-blue-500'
                              value={"Details"}
                              icon={"ChevronDoubleRightIcon"}
                              onClick={() => {
                                navigate(
                                  `/prepared-budgets/payment/${formikRef.current.values?.acc_budget_form_id}`
                                );
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
                          <th className='table-head'>Surplus/Deficit</th>
                          <th className='table-head'>
                            {(
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id == last2Session.id &&
                                    p.budget_type == 2
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.receipt_amount) || 0),
                                  0
                                ) -
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id == last2Session.id &&
                                    p.budget_type == 2
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.payment_amount) || 0),
                                  0
                                )
                            ).toFixed(2)}
                          </th>
                          <th className='table-head'>
                            {(
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id == lastSession.id &&
                                    p.budget_type == 0
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.receipt_amount) || 0),
                                  0
                                ) -
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id == lastSession.id &&
                                    p.budget_type == 0
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.payment_amount) || 0),
                                  0
                                )
                            ).toFixed(2)}
                          </th>
                          <th className='table-head'>
                            {(
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id == lastSession.id &&
                                    p.budget_type == 1
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.receipt_amount) || 0),
                                  0
                                ) -
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id == lastSession.id &&
                                    p.budget_type == 1
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.payment_amount) || 0),
                                  0
                                )
                            ).toFixed(2)}
                          </th>
                          <th className='table-head'>
                            {(
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id ==
                                      currentSession.id && p.budget_type == 0
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.receipt_amount) || 0),
                                  0
                                ) -
                              budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id ==
                                      currentSession.id && p.budget_type == 0
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(budget.payment_amount) || 0),
                                  0
                                )
                            ).toFixed(2)}
                          </th>
                        </tr>
                      </tfoot>
                    </table>

                    {currentSession &&
                      budgets.filter((p) => p.draft == 1).length > 0 && (
                        <div className='mt-5 text-center'>
                          <button
                            className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none'
                            type='submit'
                          >
                            Create Final Budget
                          </button>
                        </div>
                      )}

                    {currentSession &&
                      budgets.filter((p) => p.draft == 1).length == 0 && (
                        <div className='mt-5 text-center'>
                          <span className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-red-600 hover:shadow-lg focus:outline-none'>
                            Budget Already Created
                          </span>
                        </div>
                      )}
                  </>
                )}
              </Form>
            </Formik>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
