import React from "react";
import { Link } from "react-router-dom";
function LandingPage() {
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8  mt-10">
        <div className="max-w-xl w-full space-y-8 shadow-2xl p-10 rounded-lg">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-600">
              Accounts Module
            </h2>
            <h2 className="mt-1 text-center text-xl font-medium text-gray-600">
              Dibrugarh University ERP
            </h2>
          </div>
          <hr />
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />

            <div className="grid grid-cols-1 content-center gap-8">
              <div className="text-sm text-center">
                <Link
                  to="/staff-login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Login Here
                </Link>
              </div>

              {/* <div className="text-sm">
                <Link
                  to="/costcenter-login"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Cost Centers Login
                </Link>
              </div> */}
            </div>
            <div className="mx-5">&nbsp;</div>
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  target="blank"
                  href="https://dibru.ac.in"
                  className="  text-xs font-light text-muted hover:text-indigo-500"
                >
                  Terms &amp; Conditions
                </a>
              </div>
              <div className="text-sm">
                <a
                  target="blank"
                  href="https://dibru.ac.in"
                  className="  text-xs font-light text-muted hover:text-indigo-500"
                >
                  About
                </a>
              </div>

              <div className="text-sm">
                <a
                  target="blank"
                  href="https://dibru.ac.in"
                  className="  text-xs font-light text-muted hover:text-indigo-500"
                >
                  Help
                </a>
              </div>
              <div className="text-sm">
                <a
                  target="blank"
                  href="https://dibru.ac.in"
                  className="  text-xs font-light text-muted hover:text-indigo-500"
                >
                  Faq
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
