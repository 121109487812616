import moment from "moment";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PageTitle from "../../../Components/Common/PageTitle";
import Pagination from "../../../Components/Utils/Pagination";
import SearchButton from "../../../Components/Utils/SearchButton";
import BaseApi from "../../../Http/BaseApi";
// import Input from "../../../Components/Utils/Input";
import GenerateOrder from "./Partials/GenerateOrder";
import DateExtendModal from "./Partials/DateExtendModal";
import can from "../../../Middleware/Permission";

const CommittedExpendatureIndex = ({ tabChange }) => {
  const dispatch = useDispatch();

  const [popupOpen, setPopupOpen] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState();
  // const [vendors, setVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDateExtendModal, setShowDateExtendModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  const [errorMessage, setErrorMessage] = useState(false);

  const handleAcceptClick = (e, proposal) => {
    setPopupOpen(true);
    setSelectedProposal(proposal);
    // formikRef.current?.setFieldValue("proposal_id", proposal.id);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  const handleDateChangePopupClose = ({ updatedProposal = null }) => {
    console.log(updatedProposal);
    let _proposals = { ...proposals };
    _proposals.data.find((p) => p.id == updatedProposal.id).order_last_date =
      updatedProposal.order_last_date;
    setProposals(_proposals);
    setShowDateExtendModal(false);
  };

  useEffect(() => {
    if (proposals.length === 0) {
      callCommExpendatures(null);
    }

    // if (vendors.length == 0) {
    //   getVendors();
    // }
  }, [dispatch]);

  // const myDate = moment("2023-04-20");
  // const isDateValid = moment().isSameOrAfter(myDate);

  // console.log(isDateValid);
  const callCommExpendatures = (url) => {
    setLoading(true);
    BaseApi()
      .get(url ?? `/proposals?status=1&search_term=${searchTerm}`)
      .then((response) => {
        setProposals(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
    setLoading(false);
  };
  // async function getVendors() {
  //   setLoading(true);
  //   await BaseApi()
  //     .get(`/vendors`)
  //     .then((data) => {
  //       let _vendor = data.data;
  //       setVendors(_vendor);
  //       console.log("-------", vendors);
  //     })
  //     .catch(() => {});

  //   setLoading(false);
  // }

  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    setErrorMessage("");
    setLoading(true);
    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`proposals/${payload.id}`, {})
        .then(() => {
          let _proposals = { ...proposals };
          let _data = _proposals.data.filter((p) => p.id != payload.id);
          _proposals.data = _data;
          setProposals(_proposals);
          setLoading(false);

          // getBudgetBlocks();
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          setLoading(false);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  let tdClass = "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='List of Committed Expenditure' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <div className='flex items-center gap-2 mb-3'>
              {" "}
              <input
                className='px-4 py-2 border border-gray-400 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                type='number'
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder='Search by proposal number'
              />
              <SearchButton
                onClick={() => {
                  callCommExpendatures(null);
                }}
              />
            </div>

            {errorMessage && (
              <p className='mb-4 text-red-500'>{errorMessage}</p>
            )}
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                  >
                    Accepted Date
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap'
                  >
                    Proposal ID
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                  >
                    Assigned head
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                  >
                    Cost Center
                  </th>

                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                  >
                    Amount
                  </th>
                  <th scope='col' className='relative px-6 py-3'>
                    <span className='sr-only'>View</span>
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {proposals.data?.length > 0 &&
                  proposals.data?.map((proposal) => (
                    <tr key={proposal.id}>
                      <td className='px-6 py-4 '>
                        <div className='text-sm font-medium text-gray-900'>
                          {moment(proposal.accepted_date).format("Do MMM YYYY")}{" "}
                          <br />
                          <br />
                          <p className='text-red-400'>
                            Last Date to generate order:
                            <br />
                            {moment(proposal.order_last_date).format(
                              "Do MMM YYYY"
                            )}
                          </p>
                        </div>
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap '>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.proposal_id}
                        </div>
                      </td>
                      <td className='px-6 py-4 '>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.acc_payment_head?.name}
                        </div>
                      </td>
                      <td className='px-6 py-4 '>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal.acc_cost_center?.name}
                        </div>
                      </td>

                      <td className='px-6 py-4 '>
                        <div className='text-sm font-medium text-yellow-600'>
                          Approved
                        </div>
                      </td>
                      <td className='px-6 py-4 '>
                        <div className='text-sm font-medium text-gray-900'>
                          {proposal?.proposal_items
                            ?.reduce((accumulator, item) => {
                              return (
                                accumulator + parseFloat(item.total_amount)
                              );
                            }, 0)
                            .toFixed(2)}
                        </div>
                      </td>
                      <td className='px-6 py-4 text-sm font-medium text-right whitespace-nowrap'>
                        <Link
                          target={"_blank"}
                          to={`/proposals/${proposal.id}`}
                          className='mx-5 text-indigo-600 hover:text-indigo-900'
                        >
                          View
                        </Link>

                        {moment()
                          .endOf("day")
                          .isSameOrBefore(
                            moment(proposal.order_last_date).endOf("day")
                          ) && (
                          <a
                            onClick={(e) => {
                              handleAcceptClick(e, proposal);
                            }}
                            className='p-1 ml-5 text-indigo-600 border-2 border-indigo-700 hover:text-indigo-900'
                          >
                            Generate Order
                          </a>
                        )}
                        {moment()
                          .endOf("day")
                          .isAfter(
                            moment(proposal.order_last_date).endOf("day")
                          ) && (
                          <>
                            {" "}
                            <span className='text-red-500'>
                              Expired Order
                            </span>{" "}
                          </>
                        )}
                        {can("Account Masters") && (
                          <>
                            {" "}
                            <br />
                            <button
                              onClick={() => {
                                setSelectedProposal(proposal);
                                setShowDateExtendModal(true);
                              }}
                              className='mt-1 text-blue-500'
                              type='button'
                            >
                              Update Last Date
                            </button>
                          </>
                        )}
                      </td>
                      <td className={tdClass}>
                        {(deleteConfirm.state == 0 ||
                          deleteConfirm.itemId != proposal.id) &&
                          can("Account Masters") && (
                            <button
                              className='px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700'
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: proposal.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </button>
                          )}
                        {deleteConfirm.state == 1 &&
                          deleteConfirm.itemId == proposal.id && (
                            <>
                              <p className='text-red-700'> Are You Sure? </p>

                              <button
                                className='outline-button-danger'
                                onClick={(event) =>
                                  handleDelete(event, proposal)
                                }
                              >
                                Yes
                              </button>
                              <span className='mr-2'></span>
                              <button
                                className='outline-button-success'
                                onClick={() => setDeleteConfirm({})}
                              >
                                No
                              </button>
                            </>
                          )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {proposals.total > proposals.per_page && (
              <div className='m-5'>
                <Pagination
                  links={proposals?.links}
                  onClick={(url) => {
                    callCommExpendatures(url);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {popupOpen && (
        <GenerateOrder
          tabChange={tabChange}
          selectedProposal={selectedProposal}
          handlePopupClose={handlePopupClose}
        />
      )}

      {showDateExtendModal && (
        <DateExtendModal
          tabChange={tabChange}
          selectedProposal={selectedProposal}
          handlePopupClose={handleDateChangePopupClose}
        />
      )}
    </>
  );
};

export default CommittedExpendatureIndex;
