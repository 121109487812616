import { ErrorMessage } from "formik";
import React, { useState, useRef, useEffect } from "react";
import BaseApi from "../../Http/BaseApi";

function SearchableDropdownVendor(props) {
  let classNames = props.readOnly ? "bg-gray-100" : "";
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(props.options);
  // console.log(options);
  const [searchTerm, setSearchTerm] = useState(
    props?.defaultOption?.name ?? ""
  );
  // const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
        // console.log("abc");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSearch = (e) => {
    // console.log(e.target.value);
    setSearchTerm(e.target.value);
    setShowOptions(true);
    if (searchTerm.length > 1) {
      callOptions(e.target.value);
    }

    props.setSelectedOption("");
  };
  const callOptions = async (search) => {
    setLoading(true);
    await BaseApi()
      .get(`vendors?search=${search}`)
      .then((data) => {
        setOptions(data.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const handleClear = () => {
    setSearchTerm("");
    props.setSelectedOption("");
    props.reference.current.value = "";
    setShowOptions(false);
  };

  const handleSelect = (option) => {
    props.setSelectedOption(option);
    setSearchTerm(option.name);
    props.reference.current.value = option.name + "-" + option.pan;
    setShowOptions(false);
  };

  const filteredOptions = options.filter(
    (option) =>
      option.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
  );

  // console.log(filteredOptions);
  return (
    <div className=''>
      <label
        htmlFor={props.id}
        className='block text-sm font-medium text-gray-700'
      >
        {props.label}
        {loading && "Loading"}
      </label>
      <div>
        <div className='relative'>
          <input
            value={searchTerm}
            ref={props.reference}
            type='text'
            onChange={handleSearch}
            placeholder='Search for an option'
            className={`appearance-none mt-2 rounded-none   block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900   focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${classNames}`}
          />
          {searchTerm.length > 0 && (
            <button
              type='button'
              className='absolute top-0 right-0 m-2'
              onClick={handleClear}
            >
              x
            </button>
          )}
        </div>
        {showOptions && searchTerm.length > 1 && (
          <ul className='absolute z-10 rounded-md bg-white shadow-xl'>
            {filteredOptions.map((option) => (
              <li
                key={option.id}
                onClick={() => handleSelect(option)}
                className='p-2 hover:bg-gray-200'
              >
                {option.name} - {option.pan}
              </li>
            ))}
          </ul>
        )}
      </div>
      <ErrorMessage
        name={props.name}
        component='div'
        className='text-red-500 py-2'
      />
    </div>
  );
}

export default SearchableDropdownVendor;
