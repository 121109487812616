import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";

import { useParams } from "react-router-dom";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import moment from "moment";
import messageSlice from "../../../Redux/MessageSlice";
import { getAcademicSessionsAsync } from "../../../Redux/MasterSlice";
import { getCostCentersAsync } from "../../../Redux/BudgetSlice";
import BaseApi from "../../../Http/BaseApi";
import PageTitle from "../../../Components/Common/PageTitle";
import Alert from "../../../Components/Utils/Alert";
// import CustomSelect from "../../../Components/Utils/CustomSelect";
import Input from "../../../Components/Utils/Input";
// import can from "../../../Middleware/Permission";

export default function BudgetEdit() {
  const { type } = useParams();
  const { costCenter_id } = useParams();
  const { accBudgetFormId } = useParams();

  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [assignedHeads, setAssignedHeads] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [budgetForm, setBudgetForm] = useState();
  const [budgetMasters, setBudgetMasters] = useState([]);

  const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);
  const academicSessions = [...master.academicSessions];
  const costCenters = [...budget.costCenters];
  const { message } = useSelector((state) => state.message);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    // getOpenBudgets();
    // getAssignedHeads();

    if (costCenters.length === 0) {
      dispatch(getCostCentersAsync());
    }
    setInitialValues({
      acc_budget_form_id: null,
      academic_session_id: null,
      type: type,
      cost_center_id: costCenter_id,
      details: [],
    });
    async function getData() {
      if (budgetMasters.length === 0) {
        var response = await BaseApi().get(`budget-masters`);
        setBudgetMasters(response.data);
      }
      if (response.data?.length > 0) {
        let _budgetMaster = response.data.find(
          (bm) => bm.id == accBudgetFormId
        );
        console.log(_budgetMaster);
        setBudgetForm(_budgetMaster);
        await getAssignedHeads(_budgetMaster?.acc_budget_master_id);
        await callBudgets(
          accBudgetFormId,
          costCenter_id,
          _budgetMaster?.academic_session_id
        );
      }
    }

    getData();

    // dispatch(getOldStudentsAsync());
  }, [dispatch, type]);
  console.log(costCenters.length);
  let currentSession = academicSessions.find((e) => {
    return e.id == budgetForm?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });
  const getAssignedHeads = async (acc_budget_master_id) => {
    await BaseApi()
      .get(
        `get-assigned-heads?acc_budget_master_id=${acc_budget_master_id}&cost_center_id=${
          costCenter_id ?? 0
        }&type=${type == "receipt" ? 1 : 2}`
      )
      .then(async (response) => {
        let heads = response.data;

        await heads.map((head) => {
          if (head.parent.parent) {
            if (!heads.some((h) => h.id == head.parent.parent.id))
              heads.push(head.parent.parent);
            delete head.parent.parent;
          }
          if (head.parent) {
            if (!heads.some((h) => h.id == head.parent.id))
              heads.push(head.parent);
            delete head.parent;
          }
        });

        console.log("Flat Heads", heads);

        setAssignedHeads(heads);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  // const getOpenBudgets = async () => {
  //   await BaseApi()
  //     .get(`get-open-budgets`)
  //     .then((response) => {
  //       console.log(response.data);
  //       setOpenBudgets(response.data);
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // };

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);
    setLoading(true);
    await BaseApi().post(`budget-update`, payload);

    setLoading(false);
    // resetForm({ academic_session_id: "", details: [] });

    // setInitialValues({
    //   academic_session_id: "",
    //   type: type,
    //   details: [],
    // });
    dispatch(messageSlice.actions.setMessage("Saved As Draft"));
  };

  useEffect(() => {
    console.log(lastSession?.id);
    // console.log(add);
    assignedHeads.map((head) => {
      const exists = budgets.filter((e) => e.acc_payment_head_id === head.id);

      if (exists != null && exists.length > 0 && initialValues) {
        console.log();
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          name: head.name,
          parent_id: head.acc_payment_head_id,
          acc_payment_head_id: head.id,
          budget_current: exists.find(
            (e) =>
              e.academic_session_id == currentSession.id && e.budget_type == 0
          )?.[type == "receipt" ? "receipt_amount" : "payment_amount"],
          rev_last: exists.find(
            (e) => e.academic_session_id == lastSession.id && e.budget_type == 1
          )?.[type == "receipt" ? "receipt_amount" : "payment_amount"],
          budget_last: exists.find(
            (e) => e.academic_session_id == lastSession.id && e.budget_type == 0
          )?.[type == "receipt" ? "receipt_amount" : "payment_amount"],
          actual_prev: exists.find(
            (e) =>
              e.academic_session_id == last2Session.id && e.budget_type == 2
          )?.[type == "receipt" ? "receipt_amount" : "payment_amount"],
          justification: exists.find(() => true).justification,
        });
        setInitialValues({
          name: initialValues.name,
          parent_id: initialValues.parent_id,
          acc_budget_form_id: initialValues.acc_budget_form_id,
          academic_session_id: initialValues.academic_session_id,
          type: initialValues.type,
          cost_center_id: costCenter_id,
          details,
        });
      } else if (initialValues) {
        console.log("null");
        let copy = { ...initialValues };
        let details = copy.details;
        console.log();
        details.push({
          name: head.name,
          parent_id: head.acc_payment_head_id,
          acc_payment_head_id: head.id,
          budget_current: null,
          rev_last: null,
          budget_last: null,
          actual_prev: null,
          justification: null,
        });
        setInitialValues({
          name: initialValues.name,
          parent_id: initialValues.parent_id,
          acc_budget_form_id: initialValues.acc_budget_form_id,
          academic_session_id: initialValues.academic_session_id,
          type: initialValues.type,
          cost_center_id: costCenter_id,
          details,
        });
      }
    });

    // dispatch(getOldStudentsAsync());
  }, [budgets]);

  const callBudgets = async (
    acc_budget_form_id,
    costCenter_id,
    academic_session_id
  ) => {
    setLoading(true);
    const _budgets = await BaseApi()
      .get(`budgets`, {
        params: {
          acc_budget_form_id: acc_budget_form_id,
          cost_center_id: costCenter_id,
        },
      })
      .catch(() => {
        setLoading(false);
      });

    setBudgets(_budgets.data);

    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
      academic_session_id: academic_session_id,
      type: type,
      cost_center_id: costCenter_id,
      details: [],
    });
    setLoading(false);
  };

  return (
    <>
      <div className="py-2">
        <div className="mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded">
          <div className="w-full px-5   ">
            <PageTitle
              name={
                "Budget " +
                (type == "receipt" ? "Receipt " : "Payment ") +
                "Edit"
              }
            />
            <h2 className="text-xl ml-5">
              {costCenters.find((c) => c.id == costCenter_id)?.name}
            </h2>{" "}
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  {/* <CustomSelect
                    key={type}
                    options={openBudgets}
                    name="acc_budget_form_id"
                    label="Select Budget Master"
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) =>
                      `${option.budget_master?.name} - ${
                        option.academic_session?.label
                      } 
                      | Last Date:  ${moment(option.to_date).format(
                        "Do MMM YYYY"
                      )}
                      `
                    }
                    onChange={async (selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "acc_budget_form_id",
                        selectedOption.id
                      );

                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.academic_session_id
                      );
                      await getAssignedHeads(
                        selectedOption.acc_budget_master_id
                      );
                      callBudgets(selectedOption);
                    }}
                  /> */}
                </div>

                {loading == true && (
                  <>
                    <div className="flex">
                      <span className="m-auto">
                        <ThreeDots
                          height="100"
                          width="100"
                          color="grey"
                          ariaLabel="loading"
                        />
                      </span>
                    </div>
                  </>
                )}
                <div className="font-medium grid grid-cols-12 gap-2">
                  <div className="col-span-6 sm:col-span-3">
                    Head of Accounts
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    Actual
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 2;
                      })?.label
                    }
                    <small className="text-gray-400 text-xs">
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    Budget
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 1;
                      })?.label
                    }{" "}
                    <small className="text-gray-400 text-xs">
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    Revised
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 1;
                      })?.label
                    }{" "}
                    <small className="text-gray-400 text-xs">
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    Budget {currentSession?.label}{" "}
                    <small className="text-gray-400 text-xs">
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  {type == "payment" && (
                    <div className="col-span-6 sm:col-span-1">
                      Justification
                    </div>
                  )}
                </div>

                {initialValues?.details
                  .filter((detail) => detail.parent_id == null)
                  ?.map((head) => {
                    return (
                      <>
                        {initialValues?.details.some(
                          (detail1) =>
                            detail1.parent_id == head.acc_payment_head_id
                        ) ? (
                          <div className="col-span-6 font-bold  text-sm py-3 sm:col-span-3">
                            {head.name}
                          </div>
                        ) : (
                          inputRow(
                            initialValues?.details.indexOf(head),
                            assignedHeads,
                            head,
                            type
                          )
                        )}

                        {initialValues?.details
                          .filter(
                            (detail1) =>
                              detail1.parent_id == head.acc_payment_head_id
                          )
                          ?.map((child) => {
                            console.log(child);
                            return (
                              <>
                                {initialValues?.details.some(
                                  (detail2) =>
                                    detail2.parent_id ==
                                    child.acc_payment_head_id
                                ) ? (
                                  <div className="col-span-6 font-medium  text-sm py-3 sm:col-span-3">
                                    &nbsp;&nbsp;{child.name}
                                  </div>
                                ) : (
                                  inputRow(
                                    initialValues?.details.indexOf(child),
                                    assignedHeads,
                                    child,
                                    type
                                  )
                                )}
                                {initialValues?.details
                                  .filter(
                                    (detail2) =>
                                      detail2.parent_id ==
                                      child.acc_payment_head_id
                                  )
                                  ?.map((child1) => {
                                    console.log(child1);

                                    return (
                                      <>
                                        {inputRow(
                                          initialValues?.details.indexOf(
                                            child1
                                          ),
                                          assignedHeads,
                                          child1,
                                          type
                                        )}
                                      </>
                                    );
                                  })}
                              </>
                            );
                          })}
                      </>
                    );
                  })}

                {/* {initialValues?.details.map((head, index) => {
                  console.log("abc");
                  return (
                    <>
                      {inputRow(index, assignedHeads, head, type)}
                    </>
                  );
                })} */}

                {currentSession &&
                  (budgets.length == 0 ||
                    budgets.filter((p) => p.draft == 1).length > 0) && (
                    <div className="text-center mt-5">
                      <button
                        className=" bg-gradient-to-r from-pink-500 to-yellow-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Save as Draft
                      </button>
                    </div>
                  )}

                {currentSession &&
                  budgets.length > 0 &&
                  budgets.filter((p) => p.draft == 1).length == 0 && (
                    <div className="text-center mt-5">
                      <button
                        type="submit"
                        className="bg-gradient-to-r from-pink-500 to-yellow-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      >
                        Budget Already Submitted
                      </button>
                    </div>
                  )}
              </Form>
            </Formik>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
function inputRow(index, assignedHeads, head, type) {
  return (
    <div key={index} className="grid grid-cols-12 gap-2">
      <div className="col-span-6  text-sm py-3 sm:col-span-3">
        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        {assignedHeads.find((e) => e.id == head.acc_payment_head_id)?.code} -{" "}
        {assignedHeads.find((e) => e.id == head.acc_payment_head_id)?.name}
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input type="number" label="" name={`details[${index}].actual_prev`} />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input type="number" label="" name={`details[${index}].budget_last`} />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input type="number" label="" name={`details[${index}].rev_last`} />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          type="number"
          label=""
          name={`details[${index}].budget_current`}
        />
      </div>
      {type == "payment" && (
        <div className="col-span-6 sm:col-span-1">
          <Input
            type="text"
            label=""
            name={`details[${index}].justification`}
            placeholder="Optional"
          />
        </div>
      )}
    </div>
  );
}
