import React from "react";
// import { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// import Badge from "../../Components/Utils/Badge.js";

export default function DepartmentDashboard() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   navigate("/old-students");
  // }, []);

  return <>Dashboard</>;
}
