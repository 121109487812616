import React from "react";
// import Button from "../Utils/Button";

export default function PageTitle({ ...props }) {
  return (
    <header className="  rounded-sm">
      <div className="max-w-7xl mx-auto py-2 px-0  flex justify-between mt-1 ">
        <h1 className="text-2xl capitalize font-bold  ">
          {props.name ?? "Welcome"}{" "}
        </h1>
        {/* <Button value={props.buttonText ?? "Add New"} icon={"PlusIcon"} /> */}
      </div>
    </header>
  );
}
