import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";
import messageSlice from "../../Redux/MessageSlice";
import BaseApi from "../../Http/BaseApi";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import CustomSelect from "../../Components/Utils/CustomSelect";
import Input from "../../Components/Utils/Input";
import { useParams } from "react-router-dom";
// import can from "../../Middleware/Permission";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";
import moment from "moment";
import { getCostCentersAsync } from "../../Redux/BudgetSlice";
import AddBudgetHead from "./Partials/AddBudgetHead";

export default function BudgetProposalEntry() {
  const { type } = useParams();
  const { costCenter_id } = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [assignedHeads, setAssignedHeads] = useState([]);
  const [openBudgets, setOpenBudgets] = useState([]);
  const [proposals, setProposals] = useState([]);
  const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);
  const academicSessions = [...master.academicSessions];
  const costCenters = [...budget.costCenters];
  const { message } = useSelector((state) => state.message);
  const authData = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    getOpenBudgets();
    getAssignedHeads();

    if (costCenters.length === 0) {
      dispatch(getCostCentersAsync());
    }
    setInitialValues({
      acc_budget_form_id: null,
      academic_session_id: null,
      type: type,
      cost_center_id: costCenter_id,
      details: [],
    });

    // dispatch(getOldStudentsAsync());
  }, [dispatch, type]);
  console.log(costCenters.length);
  let currentSession = academicSessions.find((e) => {
    return e.id == formikRef.current?.values?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });
  const getAssignedHeads = async () => {
    console.log("returned");
    await BaseApi()
      .get(
        `get-assigned-heads?cost_center_id=${costCenter_id ?? 0}&type=${
          type == "receipt" ? 1 : 2
        }`
      )
      .then((response) => {
        console.log(response.data);
        setAssignedHeads(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getOpenBudgets = async () => {
    await BaseApi()
      .get(`get-open-budgets`)
      .then((response) => {
        console.log(response.data);
        setOpenBudgets(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload) => {
    dispatch(messageSlice.actions.clearMessage());
    console.log(payload);
    setLoading(true);
    await BaseApi().post(`budget-proposal-entry`, payload);

    setLoading(false);
    // resetForm({ academic_session_id: "", details: [] });

    // setInitialValues({
    //   academic_session_id: "",
    //   type: type,
    //   details: [],
    // });
    dispatch(messageSlice.actions.setMessage("Saved As Draft"));
  };

  useEffect(() => {
    console.log(lastSession?.id);
    // console.log(add);
    assignedHeads.map((head) => {
      const exists = proposals.filter((e) => e.acc_payment_head_id === head.id);

      if (exists != null && exists.length > 0 && initialValues) {
        console.log();
        let copy = { ...initialValues };
        let details = copy.details;
        details.push({
          acc_payment_head_id: head.id,
          budget_current: exists.find(
            (e) =>
              e.academic_session_id == currentSession.id && e.budget_type == 0
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          rev_last: exists.find(
            (e) => e.academic_session_id == lastSession.id && e.budget_type == 1
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          budget_last: exists.find(
            (e) => e.academic_session_id == lastSession.id && e.budget_type == 0
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          actual_prev: exists.find(
            (e) =>
              e.academic_session_id == last2Session.id && e.budget_type == 2
          )?.[
            type == "receipt"
              ? "proposed_amount_receipt"
              : "proposed_amount_payment"
          ],
          justification: exists.find(() => true).justification,
        });
        setInitialValues({
          acc_budget_form_id: initialValues.acc_budget_form_id,
          academic_session_id: initialValues.academic_session_id,
          type: initialValues.type,
          cost_center_id: costCenter_id,
          details,
        });
      } else if (initialValues) {
        console.log("null");
        let copy = { ...initialValues };
        let details = copy.details;
        console.log();
        details.push({
          acc_payment_head_id: head.id,
          budget_current: null,
          rev_last: null,
          budget_last: null,
          actual_prev: null,
          justification: null,
        });
        setInitialValues({
          acc_budget_form_id: initialValues.acc_budget_form_id,
          academic_session_id: initialValues.academic_session_id,
          type: initialValues.type,
          cost_center_id: costCenter_id,
          details,
        });
      }
    });

    // dispatch(getOldStudentsAsync());
  }, [proposals]);

  const afterAdding = async () => {
    await getAssignedHeads();
    await callProposals({
      id: initialValues.acc_budget_form_id,
      academic_session_id: initialValues.academic_session_id,
    });
  };
  const callProposals = async (selectedOption) => {
    console.log(selectedOption);
    setLoading(true);
    const _proposals = await BaseApi()
      .get(`budget-proposals`, {
        params: {
          acc_budget_form_id: selectedOption.id,
          cost_center_id: costCenter_id,
        },
      })
      .catch(() => {
        setLoading(false);
      });

    setProposals(_proposals.data);

    setInitialValues({
      acc_budget_form_id: selectedOption.id,
      academic_session_id: selectedOption.academic_session_id,
      type: type,
      cost_center_id: costCenter_id,
      details: [],
    });
    setLoading(false);
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle
              name={
                "Budget " +
                (type == "receipt" ? "Receipt " : "Payment ") +
                "Entry"
              }
            />

            <button
              className='float-right px-2 py-2 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-blue-500 to-red-500 active:bg-pink-600 hover:shadow-lg focus:outline-none'
              type='button'
              onClick={() => {
                formikRef.current.handleSubmit();
                setShowModal(true);
              }}
            >
              Add Budget Head
            </button>

            <h2 className='ml-5 text-xl'>
              {type == "receipt" ? "Revenue " : "Cost "} Center:{" "}
              {authData.user?.cost_center?.name} -
              {type == "receipt"
                ? authData.user?.cost_center?.revenue_center_code
                : authData.user?.cost_center?.cost_center_code}
            </h2>
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    key={type}
                    options={openBudgets}
                    name='acc_budget_form_id'
                    label='Select Budget Master'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) =>
                      `${option.budget_master?.name} - ${
                        option.academic_session?.label
                      } 
                      | Last Date:  ${moment(option.to_date).format(
                        "Do MMM YYYY"
                      )}
                      `
                    }
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "acc_budget_form_id",
                        selectedOption.id
                      );

                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.academic_session_id
                      );

                      callProposals(selectedOption);
                    }}
                  />
                </div>

                {loading == true && (
                  <>
                    <div className='flex'>
                      <span className='m-auto'>
                        <ThreeDots
                          height='100'
                          width='100'
                          color='grey'
                          ariaLabel='loading'
                        />
                      </span>
                    </div>
                  </>
                )}
                <div className='grid grid-cols-12 gap-2 font-medium'>
                  <div className='col-span-6 sm:col-span-3'>
                    Head of Accounts
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Actual
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 2;
                      })?.label
                    }
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Budget
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 1;
                      })?.label
                    }{" "}
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Revised
                    {
                      academicSessions.find((as) => {
                        return as.serial == currentSession?.serial - 1;
                      })?.label
                    }{" "}
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  <div className='col-span-6 sm:col-span-2'>
                    Budget {currentSession?.label}{" "}
                    <small className='text-xs text-gray-400'>
                      <br />
                      Amount in Rs.
                    </small>
                  </div>
                  {type == "payment" && (
                    <div className='col-span-6 sm:col-span-1'>
                      Justification
                    </div>
                  )}
                </div>
                {initialValues?.details.map((head, index) => {
                  console.log("abc");
                  return (
                    <>
                      <div key={index} className='grid grid-cols-12 gap-2'>
                        <div className='col-span-6 py-3 text-sm sm:col-span-3'>
                          {
                            assignedHeads.find(
                              (e) => e.id == head.acc_payment_head_id
                            )?.code
                          }{" "}
                          -{" "}
                          {
                            assignedHeads.find(
                              (e) => e.id == head.acc_payment_head_id
                            )?.name
                          }
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='number'
                            label=''
                            name={`details[${index}].actual_prev`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='number'
                            label=''
                            name={`details[${index}].budget_last`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='number'
                            label=''
                            name={`details[${index}].rev_last`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            type='number'
                            label=''
                            name={`details[${index}].budget_current`}
                          />
                        </div>
                        {type == "payment" && (
                          <div className='col-span-6 sm:col-span-1'>
                            <Input
                              type='text'
                              label=''
                              name={`details[${index}].justification`}
                              placeholder='Optional'
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}

                {currentSession &&
                  (proposals.length == 0 ||
                    proposals.filter((p) => p.draft == 1).length > 0) && (
                    <div className='mt-5 text-center'>
                      <button
                        className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none  bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-pink-600 hover:shadow-lg focus:outline-none'
                        type='submit'
                      >
                        Save as Draft
                      </button>
                    </div>
                  )}

                {currentSession &&
                  proposals.length > 0 &&
                  proposals.filter((p) => p.draft == 1).length == 0 && (
                    <div className='mt-5 text-center'>
                      <span className='px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-gradient-to-r from-pink-500 to-yellow-500 active:bg-red-600 hover:shadow-lg focus:outline-none'>
                        Budget Submitted
                      </span>
                    </div>
                  )}
              </Form>
            </Formik>
            <br />

            {showModal ? (
              <AddBudgetHead
                setShowModal={setShowModal}
                afterAdding={afterAdding}
                loading={loading}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
