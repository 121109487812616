import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";

// import Button from "../../Components/Utils/Button";
// import { useNavigate } from "react-router-dom";
import BaseApi from "../../../Http/BaseApi";

import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Pagination from "../../../Components/Utils/Pagination";
import Badge from "../../../Components/Utils/Badge";
import SearchButton from "../../../Components/Utils/SearchButton";
import moment from "moment";

export default function PendingProposalIndex() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const authData = useSelector((state) => state.auth);
  // const [staff, setStaff] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  // const [selectedIds, setSelectedIds] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // const master = useSelector((state) => state.master);
  // const staffLeaveType = [...master.staffLeaveTypes];

  useEffect(() => {
    if (proposals.length === 0) {
      callProposals(null);
    }
  }, [dispatch]);

  const callProposals = async (url) => {
    setLoading(true);
    await BaseApi()
      .get(url ?? `/proposals?status=0&search_term=${searchTerm}`)
      .then((response) => {
        setProposals(response.data);
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
      });
    setLoading(false);
  };
  // let thClass =
  //   " text-gray-600   border p-2  lg:whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    setErrorMessage("");
    setLoading(true);
    if (deleteConfirm.state == 1) {
      await BaseApi()
        .delete(`proposals/${payload.id}`, {})
        .then(() => {
          let _proposals = { ...proposals };
          let _data = _proposals.data.filter((p) => p.id != payload.id);
          _proposals.data = _data;
          setProposals(_proposals);
          setLoading(false);

          // getBudgetBlocks();
        })
        .catch((error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setErrorMessage(message);
          setLoading(false);
        });
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  let tdClass = "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900";

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5   '>
            <PageTitle name='Pending Proposals' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}
            <div className='flex items-center gap-2 mb-3'>
              {" "}
              <input
                className='border border-gray-400 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                type='number'
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder='Search by proposal number'
              />
              <SearchButton
                onClick={() => {
                  callProposals(null);
                }}
              />
            </div>
            {errorMessage && (
              <p className='text-red-500 mb-4'>{errorMessage}</p>
            )}

            <table
              id='datatable'
              className='min-w-full   bg-white dark:bg-gray-800'
            >
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Submitted Date
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Submitted By
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Proposal ID
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Cost Center
                  </th>

                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                  >
                    Total
                  </th>
                  <th scope='col' colSpan='2' className='relative px-6 py-3'>
                    <span className='sr-only'>View</span>
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {proposals.data?.length > 0 &&
                  proposals.data?.map((proposal) => {
                    return (
                      <tr
                        key={proposal.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass}>
                          {moment(proposal.created_at).format("Do MMM YYYY")}
                        </td>
                        <td className={tdClass}>{proposal.staff?.name}</td>
                        <td className={tdClass}>{proposal.proposal_id}</td>

                        <td className={tdClass}>
                          {proposal.acc_cost_center.name}
                        </td>
                        <td className={tdClass}>
                          {proposal.status == 0 && (
                            <Badge color='red' status='Pending' />
                          )}
                          {proposal.status == -1 && (
                            <span className='px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-700 text-white'>
                              Rejected
                            </span>
                          )}
                        </td>
                        <td className={tdClass}>
                          {proposal?.proposal_items
                            ?.reduce((accumulator, item) => {
                              return (
                                accumulator + parseFloat(item.total_amount)
                              );
                            }, 0)
                            .toFixed(2)}
                        </td>
                        <td className={tdClass}>
                          <Link
                            target='_blank'
                            to={`/proposals/${proposal.id}`}
                            className='text-indigo-600 hover:text-indigo-900'
                          >
                            View
                          </Link>
                        </td>
                        <td className={tdClass}>
                          {(deleteConfirm.state == 0 ||
                            deleteConfirm.itemId != proposal.id) && (
                            <button
                              className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: proposal.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </button>
                          )}
                          {deleteConfirm.state == 1 &&
                            deleteConfirm.itemId == proposal.id && (
                              <>
                                <p className='text-red-700'> Are You Sure? </p>

                                <button
                                  className='outline-button-danger'
                                  onClick={(event) =>
                                    handleDelete(event, proposal)
                                  }
                                >
                                  Yes
                                </button>
                                <span className='mr-2'></span>
                                <button
                                  className='outline-button-success'
                                  onClick={() => setDeleteConfirm({})}
                                >
                                  No
                                </button>
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {proposals.total > proposals.per_page && (
              <div className='m-5'>
                <Pagination
                  links={proposals?.links}
                  onClick={(url) => {
                    callProposals(url);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
