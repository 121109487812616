import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "../Http/BaseApi";
import messageSlice from "./MessageSlice";
// import messageSlice from "./MessageSlice";

export const getAdmittedStudentsAsync = createAsyncThunk(
  "students/getAdmittedStudentsAsync",
  async (payload) => {
    console.log(payload);
    const response = await BaseApi().get(`admitted-students`, {
      params: payload.payload,
    });
    console.log(response.data);
    return response.data;
  }
);

export const showAdmittedStudentAsync = createAsyncThunk(
  "students/showAdmittedStudentAsync",
  async (payload) => {
    const response = await BaseApi().get(`admitted-students/${payload.id}`);

    return response.data;
  }
);

export const updateAdmittedStudentsAsync = createAsyncThunk(
  "master/updateAdmittedStudentsAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post(
        `admitted-students/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  admittedStudents: [],
  singleAdmittedStudent: {},
  errorMessage: "",
};

const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {},
  extraReducers: {
    [getAdmittedStudentsAsync.fulfilled]: (state, action) => {
      console.log("get admittedStudents data");
      return { ...state, admittedStudents: action.payload };
    },
    [getAdmittedStudentsAsync.rejected]: (state) => {
      console.log("error getting admittedStudents data");
      return { ...state, errorMessage: "Something went wrong" };
    },

    [updateAdmittedStudentsAsync.fulfilled]: (state, action) => {
      const index = state.admittedStudents.findIndex(
        (student) => student.id == action.payload.id
      );
      state.admittedStudents[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [showAdmittedStudentAsync.fulfilled]: (state, action) => {
      return { ...state, singleAdmittedStudent: action.payload };
    },
  },
});

export default studentSlice.reducer;
