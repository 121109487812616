import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messageSlice from "../../../Redux/MessageSlice";
import { toast } from "react-toastify";
import * as Yup from "yup";
import BaseApi from "../../../Http/BaseApi";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
// import Alert from "../../Components/Utils/Alert";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import Upload from "../../../Components/Utils/Upload";
import Button from "../../../Components/Utils/Button";
import Input from "../../../Components/Utils/Input";
// import SearchableDropdown from "../../../Components/Utils/SearchableDropdown";
import PageTitle from "../../../Components/Common/PageTitle";
import SearchableDropdownItem from "../../../Components/Utils/SearchableDropdownItem";
import ItemEntry from "../Item/ItemEntry";
import BasicInput from "../../../Components/Utils/BasicInput";
import Alert from "../../../Components/Utils/Alert";
import { getCostCentersAsync } from "../../../Redux/BudgetSlice";
import AddedItemsInProposal from "./Partials/AddedItemsInProposal";
import ProposalEntryPreview from "./Partials/ProposalEntryPreview";

export default function ProposalEntry() {
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  //   const [errorMessage, setErrorMessage] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  //   const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const budgetSlice = useSelector((state) => state.budget);
  const costCenters = [...budgetSlice.costCenters];
  const [vendors, setVendors] = useState([]);
  const [selectedProposalNature, setSelectedProposalNature] = useState([]);
  const [selectedTaxType, setSelectedTaxType] = useState({});
  const [proposalNatures, setProposalNatures] = useState([]);
  const [itemCategories, setItemCategories] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // const [pn_value, setPnvalue] = useState("");
  // const [ic_value, setIcvalue] = useState("");
  const inputRefItem = useRef(null);
  const inputRefCategory = useRef(null);
  const inputRefUnit = useRef(null);

  const [itemInputs, setItemInputs] = useState({
    item_id: "",
    item_name: "",
    item_category_id: "",
    measurement_unit_id: "",
    description: "",
    quantity: "",
    rate: "",
    tax: "",
    amount: "",
    total_amount: "",
  });
  const [items, setItems] = useState([]);

  const handleChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    let amount = itemInputs.amount;
    let total_amount = itemInputs.total_amount;
    if (name === "rate") {
      amount = value * itemInputs.quantity;
      total_amount = amount + amount * (itemInputs.tax / 100);
    } else if (name === "quantity") {
      amount = value * itemInputs.rate;
      total_amount = amount + amount * (itemInputs.tax / 100);
    } else if (name === "tax") {
      amount = itemInputs.quantity * itemInputs.rate;
      total_amount = amount + amount * (value / 100);
    }
    console.log(total_amount);
    setItemInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      amount: parseFloat(amount).toFixed(2),
      total_amount: parseFloat(total_amount).toFixed(2),
    }));
    console.log();
    // setItemInputs((prevInputs) => ({
    //   ...prevInputs,
    //   [e.target.name]: e.target.value,
    // }));
  };
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    if (costCenters.length == 0) {
      dispatch(getCostCentersAsync());
    }
    setInitialValues({
      tax_type: "",
      acc_cost_center_id: "",
      proposed_date: "",
      vendor_id: "",
      // reference_no: "",
      // amount: "",
      // tax: "",
      // total_amount: "",
      proposal_nature_id: "",
      items: [{ ...items }],
      remarks: "",
      file: "",
      manual_ref_no: "",
      necessity: "",
    });

    if (vendors.length == 0) {
      getVendors();
    }

    if (proposalNatures.length == 0) {
      getProposalNatures();
    }

    if (itemCategories.length == 0) {
      getItemCategories();
    }
    if (measurementUnits.length == 0) {
      getMeasurementUnit();
    }
  }, [dispatch]);
  const addItem = () => {
    setPopupOpen(true);
  };
  async function getProposalNatures() {
    setLoading(true);
    await BaseApi()
      .get(`/proposal-natures`)
      .then((data) => {
        let _pn = data.data;
        setProposalNatures(_pn);
        console.log("-------", proposalNatures);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        console.log(errorMessage);
      });

    setLoading(false);

    // setProposalNature([
    //   {name: 'Test', id: 1}
    //   ,{name: 'Test2', id: 2}
    // ])
  }
  async function getVendors() {
    setLoading(true);
    await BaseApi()
      .get(`/vendors`)
      .then((data) => {
        let _vendor = data.data;
        setVendors(_vendor);
        console.log("-------", vendors);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        console.log(errorMessage);
      });

    setLoading(false);
  }

  async function getItemCategories() {
    setLoading(true);
    await BaseApi()
      .get(`/item-categories`)
      .then((data) => {
        let _ic = data.data;
        setItemCategories(_ic);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        console.log(errorMessage);
      });

    setLoading(false);

    // setItemCatrgory([
    //   { name: "Kareng Technologies", id: 1 },
    //   { name: "TCS", id: 2 },
    // ]);
  }

  async function getMeasurementUnit() {
    setLoading(true);
    await BaseApi()
      .get(`/measurement-units`)
      .then((data) => {
        let _mu = data.data;
        setMeasurementUnits(_mu);
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        console.log(errorMessage);
      });

    setLoading(false);

    // setMeasurmentUnitId([
    //   { name: "Kareng Technologies", id: 1 },
    //   { name: "TCS", id: 2 },
    // ]);
  }

  // async function getItemMasters() {
  //   console.log("call items");
  //   setLoading(true);
  //   await BaseApi()
  //     .get(
  //       `/items?itemcategory_id=${formikRef.current?.values?.itemcategory_id}`
  //     )
  //     .then((data) => {
  //       let _item = data.data.data;
  //       setItemMasters(_item);
  //       console.log(_item);
  //     })
  //     .catch((error) => {
  //       const message =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();
  //       setErrorMessage(message);
  //       console.log(errorMessage);
  //     });

  //   setLoading(false);
  // }
  const handleAddFields = () => {
    console.log(itemInputs);
    setErrorMessage("");

    if (
      Object.keys(itemInputs).some((key) => {
        console.log(selectedTaxType);
        if (selectedTaxType.id != 3 && key == "tax") {
          return false && !itemInputs[key];
        }
        return !itemInputs[key];
      })
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    const allItems = [...items];
    allItems.push(itemInputs);
    setItems(allItems);
    formikRef.current.setFieldValue("items", allItems);
    console.log(allItems);
    setItemInputs({
      item_id: "",
      item_name: "",

      item_category_id: "",
      measurement_unit_id: "",
      description: "",
      quantity: "",
      rate: "",
      tax: "",
      amount: "",
      total_amount: "",
    });
    // inputRefItem.current.clearValue();
    console.log(inputRefItem);
    inputRefItem.current.value = "";
    inputRefCategory.current.clearValue();
    inputRefUnit.current.clearValue();

    // const checkboxElement = document.getElementById("tax_inc");
    // checkboxElement.checked = false;
  };

  const removeItem = (index) => {
    console.log(index);
    let allItems = [...items];
    // console.log(allItems);
    allItems.splice(index, 1);
    console.log(allItems);
    setItems(allItems);
    formikRef.current.setFieldValue("items", allItems);
  };

  // const handleAddFields = (e) => {
  //   e.preventDefault();
  //   let values = { ...formikRef.current?.values };

  //   let added_items = [...values.items, { ...items }];
  //   values.items = added_items;
  //   setInitialValues(values);

  //   formikRef.current?.setFieldValue("items", added_items);
  // };

  // const handleRemoveFields = (index, event) => {
  //   event.preventDefault();
  //   let values = { ...formikRef.current?.values };
  //   let items = [...values.items];

  //   if (items.length === 1) {
  //     return toast.error("Can not Remove Row...");
  //   }
  //   items.splice(index, 1);
  //   values.items = items;
  //   setInitialValues(values);

  //   formikRef.current?.setFieldValue("items", items);

  //  };

  const validationSchema = Yup.object().shape({
    tax_type: Yup.string().required("This field is required!"),
    acc_cost_center_id: Yup.string().required("This field is required!"),
    proposal_nature_id: Yup.string().required("This field is required!"),
    manual_ref_no: Yup.string().required("This field is required!"),
    necessity: Yup.string().required("This field is required!"),
    // vendor_id: Yup.string().required("This field is required!"),
    // reference_no: Yup.string().required("This field is required!"),
    proposed_date: Yup.date().required("This field is required!"),
    // // delivery_time: Yup.string()
    // //   .required("This field is required!")
    // //   .max(255, "Maximum 250 character allowed"),

    // file: Yup.string().required("This field is required!"),
    // // arrangement: Yup.string().required("This field is required!"),
    // items: Yup.array().of(
    //   Yup.object().shape({
    //     item_id: Yup.number().required("Please select from the list"),
    //     description: Yup.string().required("This field is required!"),
    //     quantity: Yup.string().required("This field is required"),
    //     rate: Yup.string().required("This field is required"),
    //     tax: Yup.string().required("This field is required"),
    //     amount: Yup.string().required("This field is required"),
    //     total_amount: Yup.string().required("This field is required"),
    //   })
    // ),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    setValidationErrors([]);

    // if (payload) {
    //   return;
    // }
    console.log(payload.items);
    for (let i = 0; i < payload.items?.length; i++) {
      console.log(payload.items[i]);
      if (
        payload.items[i].item_id == undefined ||
        payload.items[i].item_id == null ||
        payload.items[i].item_id == "" ||
        payload.items[i].item_id <= 0
      ) {
        alert("Please add items properly");
        return;
      }
    }

    if (showPreview == false) {
      setShowPreview(true);
      return;
    }
    if (window.confirm("Are you sure?") != true) {
      return;
    }

    console.log("data is-", payload);

    setLoading(true);
    // setFormRole("add");
    console.log(payload);
    var form_data = new FormData();

    for (var key in payload) {
      console.log(key);
      if (key === "items") {
        // append nested object
        for (let iKay in payload[key]) {
          for (let iKay1 in payload[key][iKay]) {
            form_data.append(
              `items[${iKay}][${iKay1}]`,
              payload[key][iKay][iKay1]
            );
          }
        }
      } else {
        form_data.append(
          key,

          payload[key]
        );
      }
    }

    try {
      let response = await BaseApi().post("/proposals", form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      resetForm();
      setInitialValues({
        tax_type: "",
        acc_cost_center_id: "",
        proposed_date: "",
        vendor_id: "",
        // reference_no: "",
        // amount: "",
        // tax: "",
        // total_amount: "",
        proposal_nature_id: "",
        items: [{ ...items }],
        remarks: "",
        file: "",
        manual_ref_no: "",
        necessity: "",
      });
      setShowPreview(false);
      setLoading(false);

      navigate(`/proposals`);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,

        progress: undefined,
      });
      setValidationErrors(error.response.data.errors);

      setLoading(false);
    }
  };

  return (
    <>
      <div className='py-2'>
        <div className='mx-auto container min-h-screen bg-white dark:bg-gray-800 shadow rounded'>
          <div className='w-full px-5   '>
            <PageTitle name='Proposal Entry' />
            {loading == true && (
              <>
                <div className='flex'>
                  <span className='m-auto'>
                    <ThreeDots
                      height='100'
                      width='100'
                      color='grey'
                      ariaLabel='loading'
                    />
                  </span>
                </div>
              </>
            )}

            {/* <a
              className='text-blue-700 float-right'
              href='/holiday-calendar'
              target='_blank'
            >
              Add Item Master
            </a> */}

            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <h3 className='text-xl pt-6'>
                  {/* {formRole === "add" ? "Create New" : "Edit"} Staff: */}
                </h3>
                <div className='grid grid-cols-3  gap-4'>
                  <CustomSelect
                    options={proposalNatures}
                    name='proposal_nature_id'
                    label='Select Proposal Nature'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option?.name}`}
                    onChange={async (selectedOption) => {
                      console.log(
                        "pn--------",
                        formikRef.current?.values?.proposal_nature_id
                      );
                      setSelectedProposalNature(selectedOption);
                      formikRef.current?.setFieldValue(
                        "proposal_nature_id",
                        selectedOption.id
                      );
                      setItems([]);
                      formikRef.current?.setFieldValue("items", [[]]);
                      // setPnvalue(selectedOption.id)
                      // console.log('pn--------',pn_value);
                    }}
                  />
                  <CustomSelect
                    options={[
                      { id: 1, name: "Inclusive of GST" },
                      { id: 2, name: "Exclusive of GST" },
                      { id: 3, name: "Specify the Rate of GST" },
                    ]}
                    name='tax_type'
                    label='Select Taxation Type'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option?.name}`}
                    onChange={async (selectedOption) => {
                      // console.log(selectedOption);
                      setItems([]);

                      setSelectedTaxType(selectedOption);
                      formikRef.current?.setFieldValue(
                        "tax_type",
                        selectedOption.id
                      );
                    }}
                  />

                  <CustomSelect
                    options={costCenters}
                    name='acc_cost_center_id'
                    label='Select Cost/Revenue Center'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option?.name}`}
                    onChange={async (selectedOption) => {
                      // console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "acc_cost_center_id",
                        selectedOption.id
                      );
                    }}
                  />

                  <Input type='date' name='proposed_date' label='Date' />

                  <Input
                    type='text'
                    name='manual_ref_no'
                    label='Manual Reference No'
                  />
                  <Input type='text' name='necessity' label='Necessity' />
                  <Input type='text' name='remarks' label='Remarks' />
                  <Upload
                    className='col-span-3'
                    label='Supporting Document(if any)'
                    type='file'
                    name='file'
                    onChange={(event) => {
                      console.log("asd");
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue("file", myFile);
                    }}
                  />
                </div>
                <hr className='my-8 h-px bg-gray-200 border-0 dark:bg-gray-700' />
                <p className='text-lg'>
                  Add Item Details:
                  <div className='float-right '>
                    <Button
                      value='Create New Item'
                      type='button'
                      icon={"PlusIcon"}
                      onClick={addItem}
                    />
                  </div>
                </p>
                <div className=''>
                  {errorMessage && <Alert message={errorMessage} />}
                </div>

                <br />

                {selectedProposalNature.id > 0 && selectedTaxType.id > 0 && (
                  <div
                    key={itemInputs.length}
                    className='grid grid-cols-6 gap-2'
                  >
                    <div className='col-span-1'>
                      <CustomSelect
                        reference={inputRefCategory}
                        name={`item_category_id`}
                        placeholder='Search...'
                        label='Select Category'
                        options={itemCategories.filter(
                          (ld) =>
                            ld.proposal_nature_id == selectedProposalNature.id
                        )}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option?.name}`}
                        onChange={async (selectedOption) => {
                          // setIcvalue(selectedOption.id);
                          setItemInputs((prevInputs) => ({
                            ...prevInputs,
                            ["item_category_id"]: selectedOption?.id,
                          }));
                        }}
                      />
                    </div>
                    <div className='col-span-2'>
                      <SearchableDropdownItem
                        reference={inputRefItem}
                        name={`item_id`}
                        label='Select Item'
                        placeholder='Search...'
                        options={[]}
                        itemCategoryId={itemInputs?.item_category_id}
                        setSelectedOption={(selectedOption) => {
                          setItemInputs((prevInputs) => ({
                            ...prevInputs,
                            ["item_id"]: selectedOption?.id,
                            ["item_name"]: selectedOption?.name,
                          }));
                        }}
                      />
                    </div>
                    <div className='col-span-2'>
                      <BasicInput
                        label='Description'
                        type='text'
                        name={`description`}
                        placeholder='Description'
                        onChange={handleChange}
                        value={itemInputs.description}
                        // onChange={(event) => handleChangeDetails(index, event)}
                      />
                    </div>
                    <div className='col-span-1'>
                      {/* <Select
                        ref={itemInputRef}
                        // value={itemInputs.measurement_unit_id ?? null}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option?.name}`}
                        name='measurement_unit_id'
                        options={measurmentUnitId}
                        // value={itemInputs.measurement_unit_id}
                        onChange={(selectedOption) =>
                          handleChange({
                            target: {
                              name: "measurement_unit_id",
                              value: selectedOption?.id,
                            },
                          })
                        }
                      /> */}

                      <CustomSelect
                        reference={inputRefUnit}
                        name={`measurement_unit_id`}
                        placeholder='Search...'
                        label='Select Unit'
                        options={measurementUnits}
                        getOptionValue={(option) => `${option}`}
                        getOptionLabel={(option) => `${option?.name}`}
                        onChange={async (selectedOption) => {
                          console.log(selectedOption);
                          setItemInputs((prevInputs) => ({
                            ...prevInputs,
                            ["measurement_unit_id"]: selectedOption?.id,
                          }));
                        }}
                      />
                    </div>
                    <div className='col-span-1'>
                      <BasicInput
                        label='Quantity'
                        placeholder='quantity'
                        type='number'
                        name={`quantity`}
                        value={itemInputs.quantity}
                        onChange={handleChange}
                        // onChange={(event) => handleChangeDetails(index, event)}
                      />
                    </div>

                    <div className='col-span-1'>
                      <BasicInput
                        type='number'
                        name={`rate`}
                        value={itemInputs.rate}
                        // onChange={(event) => handleChangeDetails(index, event)}
                        label='Rate'
                        onChange={handleChange}
                      />
                    </div>
                    {selectedTaxType.id == 1 && (
                      <div className='col-span-1'>
                        <BasicInput
                          type='text'
                          value='Tax Incl.'
                          // onChange={(event) => handleChangeDetails(index, event)}
                          label='Tax (%)'
                        />
                      </div>
                    )}
                    {selectedTaxType.id == 2 && (
                      <div className='col-span-1'>
                        <BasicInput
                          type='text'
                          value='Tax Excl.'
                          // onChange={(event) => handleChangeDetails(index, event)}
                          label='Tax (%)'
                        />
                      </div>
                    )}
                    {selectedTaxType.id == 3 && (
                      <div className='col-span-1'>
                        <BasicInput
                          id='tax'
                          type='number'
                          name={`tax`}
                          value={itemInputs.tax}
                          // onChange={(event) => handleChangeDetails(index, event)}
                          label='Tax (%)'
                          onChange={handleChange}
                        />
                        {/* <input
                        onChange={(e) => {
                          if (e.target.checked) {
                            setItemInputs((prevInputs) => ({
                              ...prevInputs,
                              ["tax"]: 0,
                            }));
                            const inputElement = document.getElementById("tax");
                            const customEvent = new Event("change", {
                              bubbles: true,
                            });

                            inputElement.dispatchEvent(customEvent);
                            setTimeout(() => {
                              console.log(customEvent.target.value); // logs the updated value
                              handleChange(customEvent); // handles the updated value
                            });
                          }
                        }}
                        type='checkbox'
                        name='tax_inc'
                        id='tax_inc'
                      />
                      <label htmlFor='tax_inc'>Tax Inclusive</label> */}
                      </div>
                    )}
                    <div className='col-span-1'>
                      <BasicInput
                        type='number'
                        name={`amount`}
                        readOnly={true}
                        value={itemInputs.amount}
                        // onChange={(event) => handleChangeDetails(index, event)}
                        label='Amount'
                      />
                    </div>
                    <div className='col-span-1'>
                      <BasicInput
                        type='number'
                        name={`total_amount`}
                        readOnly={true}
                        // onChange={(event) => handleChangeDetails(index, event)}
                        label='Total Amount'
                        value={itemInputs.total_amount}
                      />
                    </div>

                    <div className='col-span-1 '>
                      <div className='    flex flex-row	'>
                        <div className='	pt-2	'>
                          <Button
                            type='button'
                            onClick={handleAddFields}
                            value=''
                            icon={"PlusIcon"}
                          />
                        </div>
                        <span className='mr-2'></span>
                        {/* <div className=' 	pt-2'>
                          <Button
                            onClick={(event) => handleRemoveFields(event)}
                            value=''
                            icon={"MinusIcon"}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
                <AddedItemsInProposal
                  selectedTaxType={selectedTaxType}
                  removeItem={removeItem}
                  items={items}
                  itemCategories={itemCategories}
                  measurementUnits={measurementUnits}
                />

                <div className='text-center mt-5'>
                  <Button value={"Submit"} icon={"SaveIcon"} type='submit' />
                </div>
                <hr className='my-8 h-px bg-gray-200 border-0 dark:bg-gray-700' />

                {showPreview ? (
                  <ProposalEntryPreview
                    selectedTaxType={selectedTaxType}
                    costCenters={costCenters}
                    selectedProposalNature={selectedProposalNature}
                    itemCategories={itemCategories}
                    setShowPreview={setShowPreview}
                    data={formikRef.current?.values}
                    loading={loading}
                    validationErrors={validationErrors}
                  />
                ) : null}
              </Form>
            </Formik>

            {popupOpen && (
              <>
                <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                  <div className='relative  lg:w-2/6	 my-6 mx-auto max-w-3xl'>
                    {/*content*/}

                    <div className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                      <button
                        className='absolute top-0 right-0 m-4'
                        onClick={handlePopupClose}
                      >
                        X
                      </button>

                      <ItemEntry proposalNature={selectedProposalNature} />
                    </div>
                  </div>
                </div>

                <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
