import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PageTitle from "../../../Components/Common/PageTitle";
import BaseApi from "../../../Http/BaseApi";
// import Alert from "../../../Components/Utils/Alert";
// import Select from "../../Components/Utils/Select";
import { ThreeDots } from "react-loader-spinner";
import can from "../../../Middleware/Permission";

// import { toast } from "react-toastify";
// import moment from "moment";
// import { useReactToPrint } from "react-to-print";
// import { useSelector } from "react-redux";
import Badge from "../../../Components/Utils/Badge";
import AcceptProposal from "./Partials/AcceptProposal";
import moment from "moment";
import { PaperClipIcon } from "@heroicons/react/outline";
import EditProposal from "./Partials/EditProposal";

export default function ProposalView() {
  const { id } = useParams();

  // const authData = useSelector((state) => state.auth);
  const [proposal, setProposal] = useState({});
  // const [item, setItem] = useState({});

  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });
  // const [deactivateConfirm, setDeactivateConfirm] = useState({ state: 0 });
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupOpenEdit, setPopupOpenEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleAcceptClick = () => {
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handleEditPopupOpen = () => {
    setPopupOpenEdit(true);
  };
  const handleEditPopupClose = () => {
    setPopupOpenEdit(false);
  };

  const handleRejectClick = async (reason) => {
    await BaseApi()
      .post(`reject-proposal`, { reason: reason, id: id })
      .then(() => {
        checkData();

        setLoading(false);
      });
    setLoading(false);
  };

  useEffect(() => {
    checkData();
  }, [id, refresh]);

  async function checkData() {
    if (id && id > 0) {
      setLoading(true);

      try {
        const _proposal = await BaseApi().get(`proposals/${id}`);
        setProposal(_proposal.data);
        // const subTotal = items.reduce((acc, item) => acc + item.amount, 0);
        // const tax = 12 / 100; // 10%
        // const taxAmount = subTotal * tax;
        // const total = subTotal + taxAmount;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message);
        // setErrorMessage(message);
      }

      setLoading(false);
    }
  }
  // const callBudgetSuperMasters = () => {
  //   BaseApi()
  //     .get(`/acc-payment-heads`)
  //     .then((response) => {
  //       setSelectedBM(response.data);
  //       // console.log('data is', response.data)
  //     })
  //     .catch((error) => {
  //       console.log("error is", error);
  //     });
  // };

  // const Print = useReactToPrint({
  //   content: () => printRef.current,
  // });

  // let thClass =
  //   " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  // let tdClass =
  //   " text-gray-600 border p-2 whitespace-nowrap dark:text-gray-400 font-normal pr-6 text-left text-sm tracking-normal leading-4";

  return (
    <>
      {loading == true && (
        <>
          <div className='flex'>
            <span className='m-auto'>
              <ThreeDots
                height='100'
                width='100'
                color='grey'
                ariaLabel='loading'
              />
            </span>
          </div>
        </>
      )}
      <PageTitle name='' />

      <div className='p-10'>
        <div className='flex justify-between mb-5'>
          <h1 className='text-4xl font-bold'>
            {" "}
            {[0, 1].includes(proposal.status) && "Proposal"}
            {[2, 3, 4].includes(proposal.status) && "Order"}
          </h1>
          <div className='text-right'>
            <p className='text-gray-700'>
              Submitted By: {proposal.staff?.name} <br />
              Submitted Date:{" "}
              {moment(proposal.created_at).format("Do MMM YYYY")}
            </p>
          </div>
        </div>

        <div className='flex justify-between mb-5'>
          <div>
            <p className='text-gray-700 font-semibold'>
              Type: {proposal.proposal_nature?.name}
            </p>
            <p className='text-gray-700 font-semibold'>
              Proposal ID: {proposal.proposal_id}{" "}
            </p>
            <p className='text-gray-700 font-semibold'>
              Manual Ref. Number: {proposal.manual_ref_no}
            </p>
            <p className='text-gray-700 font-semibold'>
              Proposal Date:{" "}
              {moment(proposal.proposal_date).format("Do MMM YYYY")}
            </p>
            <p className='text-gray-700 mb-2 font-semibold'>
              Cost Center: {proposal.acc_cost_center?.name}{" "}
            </p>

            {proposal.vendor && (
              <>
                <p className='text-gray-700 mb-1 font-semibold'>
                  Vendor Details
                </p>
                <p className='text-gray-700'>{proposal?.vendor?.name}</p>
                <p className='text-gray-700'>{proposal?.vendor?.unique_id}</p>
                <p className='text-gray-700'>{proposal?.vendor?.address}</p>

                <p className='text-gray-700'>{proposal?.vendor?.phone}</p>
              </>
            )}
          </div>
          {proposal.order_no != null && (
            <div>
              <p className='text-gray-700 font-semibold'>
                System Order No.: {proposal.order_id}
              </p>

              <p className='text-gray-700 font-semibold'>
                Manual Order No.: {proposal.manual_order_no}
              </p>

              <p className='text-gray-700 font-semibold'>
                Order Date: {moment(proposal.order_date).format("Do MMM YYYY")}
              </p>
              <p className='text-gray-700 font-semibold'>
                Delivery Date:{" "}
                {moment(proposal.delivery_date).format("Do MMM YYYY")}
              </p>
              <p className='text-gray-700 font-semibold'>
                Order Created By: {proposal.order_entry_by?.name}
              </p>
            </div>
          )}
          <div>
            <p className='text-gray-700  font-semibold'>Necessity</p>
            <p className='text-gray-700 mb-1'>{proposal.necessity}</p>
            <p className='text-gray-700 font-semibold'>Remarks</p>
            <p className='text-gray-700'>{proposal.remarks}</p>
          </div>
        </div>

        <table className='w-full mb-5'>
          <thead>
            <tr className='border-b border-gray-500'>
              <th className='text-left pb-3 font-semibold'>Item Category</th>
              <th className='text-left pb-3 font-semibold'>Item</th>
              <th className='text-left pb-3 font-semibold'>Quantity</th>
              <th className='text-left pb-3 font-semibold'>Unit</th>
              <th className='text-left pb-3 font-semibold'>Rate</th>
              <th className='text-left pb-3 font-semibold'>Amount</th>
              <th className='text-left pb-3 font-semibold'>TAX (%)</th>
              <th className='text-left pb-3 font-semibold'>Amount</th>
              <th className='text-right pb-3 font-semibold'>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {proposal?.proposal_items?.map((item, index) => (
              <>
                {" "}
                <tr key={index} className=''>
                  <td className='text-left py-3'>
                    {item.item?.item_category?.name}
                  </td>
                  <td className='text-left py-3'>{item.item?.name}</td>

                  <td className='text-left py-3'>{item.quantity}</td>
                  <td className='text-left py-3'>
                    {item.measurement_unit?.name}
                  </td>
                  <td className='text-left py-3'>{item.rate.toFixed(2)}</td>
                  <td className='text-left py-3'>{item.amount.toFixed(2)}</td>
                  <td className='text-left py-3'>
                    {/* {item.tax > 0 ? parseFloat(item.tax).toFixed(2) : "Incl."} */}
                    {proposal.tax_type == 3 &&
                      (item.tax > 0 ? parseFloat(item.tax) : "0.00")}
                    {proposal.tax_type == 1 && "Tax Incl."}
                    {proposal.tax_type == 2 && "Tax Excl."}
                  </td>
                  <td className='text-left py-3'>{item.amount.toFixed(2)}</td>
                  <td className='text-right py-3'>
                    {item.total_amount.toFixed(2)}
                  </td>
                </tr>
                <tr key={index} className='border-b border-gray-500'>
                  <td className='text-left py-3'></td>
                  <td colSpan={7} className='text-left py-3'>
                    {item.description}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot>
            <tr className='border-b border-gray-500'>
              <td colSpan='6'></td>
              <td className='text-left py-3'>Total:</td>
              <td className='text-right py-3'>
                {" "}
                {proposal?.proposal_items
                  ?.reduce((accumulator, item) => {
                    return accumulator + parseFloat(item.amount);
                  }, 0)
                  .toFixed(2)}
              </td>
              <td></td>
            </tr>

            {proposal.tax_type == 3 && (
              <>
                <tr className='border-b border-gray-500'>
                  <td colSpan='6'></td>
                  <td className='text-left py-3'>Tax Amount:</td>
                  <td className='text-right py-3'>
                    {proposal?.proposal_items
                      ?.reduce((accumulator, item) => {
                        return (
                          accumulator +
                          parseFloat((item.tax * item.amount) / 100)
                        );
                      }, 0)
                      .toFixed(2)}
                  </td>
                  <td></td>
                </tr>
                <tr className='border-b border-gray-500'>
                  <td colSpan='6'></td>
                  <td className='text-left py-3 font-semibold'>
                    Total Amount with Tax:
                  </td>
                  <td className='text-right py-3 font-semibold'>
                    {proposal?.proposal_items
                      ?.reduce((accumulator, item) => {
                        return accumulator + parseFloat(item.total_amount);
                      }, 0)
                      .toFixed(2)}
                  </td>
                  <td></td>
                </tr>{" "}
              </>
            )}
          </tfoot>
        </table>
        {proposal.tax_type == 1 && (
          <p className='text-right text-sm'>Amout is inclusive of Tax</p>
        )}
        {proposal.tax_type == 2 && (
          <p className='text-right text-sm'>Amout is exclusive of Tax</p>
        )}
        {proposal.file && (
          <a
            className='text-blue-700'
            target='_blank'
            href={proposal.proposal_doc}
            rel='noreferrer'
          >
            <PaperClipIcon className='w-4 inline-block' /> View Proposal Copy
          </a>
        )}
        {proposal.order_file && (
          <a
            className='text-blue-700    '
            target='_blank'
            href={proposal.order_doc}
            rel='noreferrer'
          >
            <PaperClipIcon className='w-4 inline-block' /> View Order Copy
          </a>
        )}
        <hr className='my-5' />

        {proposal.status == 0 && <Badge color='red' status='Pending' />}
        {proposal.status == 1 && <Badge color='green' status='Accepted' />}
        {proposal.status == -1 && (
          <>
            <Badge color='red' status='Rejected' /> <br />
            Reason: {proposal.reject_reason}
          </>
        )}

        {proposal.status == 2 && <Badge color='red' status='Ordered' />}
        {proposal.status == 3 && (
          <Badge color='orange' status='Partialy Receieved' />
        )}
        {proposal.status == 4 && <Badge color='green' status='Receieved' />}

        {/* {proposal.status == 2 && <Badge color='green' status='Order Gene' />} */}

        {can("Accept Account Proposal") && proposal.status == 0 && (
          <div className='text-center'>
            <div className='flex justify-center space-x-4'>
              <button
                onClick={handleAcceptClick}
                className='bg-green-500 text-white font-bold py-2 px-4 rounded'
              >
                Accept
              </button>
              <button
                onClick={async (event) => {
                  event.target.disabled = true;
                  let reason = window.prompt(
                    "Are you sure? Please enter a reason. "
                  );
                  if (reason == null || reason.trim() == "") {
                    return;
                  } else {
                    await handleRejectClick(reason);
                  }
                  event.target.disabled = false;
                }}
                className='bg-red-500 text-white font-bold py-2 px-4 rounded'
              >
                Reject
              </button>
            </div>
          </div>
        )}
        {proposal.status > 0 && (
          <h3>
            Assigned Head: {proposal.acc_payment_head?.code} -{" "}
            {proposal.acc_payment_head?.name}
          </h3>
        )}

        {can("Accept Account Proposal") && (
          <>
            <button
              onClick={handleEditPopupOpen}
              className='bg-red-500 text-white font-bold  text-sm py-2 px-4 rounded mt-10'
            >
              Edit Proposal
            </button>
          </>
        )}
      </div>
      {popupOpenEdit && (
        <EditProposal
          selectedProposal={proposal}
          handleEditPopupClose={handleEditPopupClose}
        />
      )}
      {popupOpen && (
        <AcceptProposal
          id={id}
          onCompleteAction={() => {
            setRefresh(!refresh);
          }}
          handlePopupClose={handlePopupClose}
        />
      )}
    </>
  );
}
