import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Button from "../../Components/Utils/Button";
import * as Yup from "yup";
// import Input from "../../Components/Utils/Input";
import messageSlice from "../../../Redux/MessageSlice";
// import Select from "../../../Components/Utils/Select";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";
import {
  addAccPaymentHeadAsync,
  getAccPaymentHeadsAsync,
  updateAccPaymentHeadAsync,
} from "../../../Redux/BudgetSlice";
import Select from "../../../Components/Utils/Select";

export default function AddBudgetHead({ setShowModal, afterAdding }) {
  console.log(setShowModal);
  const dispatch = useDispatch();
  const formikRef = useRef();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  // const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const { message } = useSelector((state) => state.message);

  const budget = useSelector((state) => state.budget);
  const accPaymentHeads = [...budget.accPaymentHeads];

  const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    setInitialValues({
      id: null,
      acc_payment_head_id: "",
      name: "",
      code: "",
      type: "",
      assign_to_current: true,
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (accPaymentHeads.length === 0) {
      dispatch(getAccPaymentHeadsAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  // const onEdit = (event, paymentHead) => {
  //   event.preventDefault();
  //   setInitialValues({
  //     id: paymentHead.id,
  //     acc_payment_head_id: paymentHead.acc_payment_head_id,
  //     name: paymentHead.name,
  //     code: paymentHead.code,
  //     type: paymentHead.type,
  //   });

  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //   setFormRole("edit");
  // };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await dispatch(updateAccPaymentHeadAsync(payload)).unwrap();
    } else {
      await dispatch(addAccPaymentHeadAsync(payload)).unwrap();
    }

    setLoading(false);
    setFormRole("add");
    resetForm();
    setInitialValues({
      id: null,
      acc_payment_head_id: "",
      name: "",
      code: "",
      type: "",
      assign_to_current: true,
    });

    afterAdding();
    setShowModal(false);
  };
  // const handleDelete = async (event, payload) => {
  //   event.target.disabled = true;
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (deleteConfirm.state == 1) {
  //     await dispatch(deleteAccPaymentHeadAsync(payload));
  //   }
  //   event.target.disabled = false;
  //   setDeleteConfirm({});
  // };

  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item != value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await dispatch(bulkDeleteAccPaymentHeadsAsync({ selectedIds })).unwrap();
  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  console.log(message);

  return (
    <>
      <div className="justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative   w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}

          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Add New Budget Head</h3>
            </div>
            {/*body*/}
            <p style={{ width: "1000px" }}>&nbsp;</p>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className=" px-5 py-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                  <Input
                    label={
                      formRole === "add"
                        ? "Create New Master Head"
                        : "Edit Master Head"
                    }
                    name="name"
                    placeholder="Enter Head Name"
                  />
                  <Input
                    label={formRole === "add" ? "Code" : "Code"}
                    name="code"
                    placeholder="Enter Code"
                  />

                  <Select name="type" label="Select Type">
                    <option value="">Select</option>
                    <option value="1">Receipt</option>
                    <option value="2">Payment</option>
                  </Select>

                  <Select
                    name="acc_payment_head_id"
                    label="Select Parent Head"
                    // onChange={(selectedOption) => {
                    //   console.log(selectedOption);
                    //   formikRef.current?.setFieldValue(
                    //     "acc_payment_head_id",
                    //     selectedOption.id
                    //   );
                    // }}
                  >
                    <option value="">None</option>
                    {accPaymentHeads.map((head) => {
                      return (
                        <>
                          <option value={head.id}>{head.name}</option>
                          {head.children?.map((child) => {
                            return (
                              <>
                                <option value={child.id}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
                                </option>
                                {child.children?.map((grandchild) => {
                                  return (
                                    <>
                                      <option value={grandchild.id}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {grandchild.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </Select>
                  <label>
                    <Field type="checkbox" name="edit" className="my-5" />
                    &nbsp;&nbsp;Assign to Me
                  </label>

                  <div className="text-left mt-5">
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <Button
                        value={loading ? "Updating..." : "Update"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    )}
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </Form>
            </Formik>
            {/* <table
              id="datatable"
              className="min-w-full   bg-white dark:bg-gray-800"
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Name</th>
                </tr>
              </thead>
              <tbody>
                {accPaymentHeads.length > 0 &&
                  accPaymentHeads.map((head, i) => {
                    return (
                      <tr
                        key={head.id}
                        className="odd:bg-white even:bg-gray-50"
                      >
                        <td className={tdClass + "w-32"}>{++i}</td>
                        <td className={tdClass}>
                          {head.code} - {head.name}{" "}
                          <span
                            className=" ml-10
                             bg-blue-200  hover:bg-blue-700  text-white font-bold py-1 px-2 rounded"
                            onClick={(event) => onEdit(event, head)}
                          >
                            Edit
                          </span>
                          {(deleteConfirm.state == 0 ||
                            deleteConfirm.itemId != head.id) && (
                            <span
                              className="ml-5 bg-red-200 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: head.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </span>
                          )}
                          {deleteConfirm.state == 1 &&
                            deleteConfirm.itemId == head.id && (
                              <>
                                <p className="text-red-700"> Are You Sure? </p>

                                <button
                                  className="outline-button-danger"
                                  onClick={(event) => handleDelete(event, head)}
                                >
                                  Yes
                                </button>
                                <span className="mr-2"></span>
                                <button
                                  className="outline-button-success"
                                  onClick={() => setDeleteConfirm({})}
                                >
                                  No
                                </button>
                              </>
                            )}
                          <ul className="pl-10">
                            {head.children?.map((child) => {
                              return (
                                <>
                                  <li className="m-4">
                                    {child.code} - {child.name}{" "}
                                    <span
                                      className=" 
                                      ml-10
                                      bg-blue-200  hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                      onClick={(event) => onEdit(event, child)}
                                    >
                                      Edit
                                    </span>
                                    {(deleteConfirm.state == 0 ||
                                      deleteConfirm.itemId != child.id) && (
                                      <span
                                        className="ml-5 bg-red-200 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                        onClick={() =>
                                          setDeleteConfirm({
                                            itemId: child.id,
                                            state: 1,
                                          })
                                        }
                                      >
                                        Delete
                                      </span>
                                    )}
                                    {deleteConfirm.state == 1 &&
                                      deleteConfirm.itemId == child.id && (
                                        <>
                                          <p className="text-red-700">
                                            {" "}
                                            Are You Sure?{" "}
                                          </p>

                                          <button
                                            className="outline-button-danger"
                                            onClick={(event) =>
                                              handleDelete(event, child)
                                            }
                                          >
                                            Yes
                                          </button>
                                          <span className="mr-2"></span>
                                          <button
                                            className="outline-button-success"
                                            onClick={() => setDeleteConfirm({})}
                                          >
                                            No
                                          </button>
                                        </>
                                      )}
                                    <ul className="pl-10">
                                      {child.children?.map((grandchild) => {
                                        return (
                                          <>
                                            <li className="m-4">
                                              {grandchild.code} -{" "}
                                              {grandchild.name}
                                              <span
                                                className=" 
                                                ml-10
                                                bg-blue-200  hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                                onClick={(event) =>
                                                  onEdit(event, grandchild)
                                                }
                                              >
                                                Edit
                                              </span>
                                              {(deleteConfirm.state == 0 ||
                                                deleteConfirm.itemId !=
                                                  grandchild.id) && (
                                                <span
                                                  className="ml-5 bg-red-200 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                                                  onClick={() =>
                                                    setDeleteConfirm({
                                                      itemId: grandchild.id,
                                                      state: 1,
                                                    })
                                                  }
                                                >
                                                  Delete
                                                </span>
                                              )}
                                              {deleteConfirm.state == 1 &&
                                                deleteConfirm.itemId ==
                                                  grandchild.id && (
                                                  <>
                                                    <p className="text-red-700">
                                                      {" "}
                                                      Are You Sure?{" "}
                                                    </p>

                                                    <button
                                                      className="outline-button-danger"
                                                      onClick={(event) =>
                                                        handleDelete(
                                                          event,
                                                          grandchild
                                                        )
                                                      }
                                                    >
                                                      Yes
                                                    </button>
                                                    <span className="mr-2"></span>
                                                    <button
                                                      className="outline-button-success"
                                                      onClick={() =>
                                                        setDeleteConfirm({})
                                                      }
                                                    >
                                                      No
                                                    </button>
                                                  </>
                                                )}
                                            </li>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table> */}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
