import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BaseApi from "../Http/BaseApi";
import messageSlice from "./MessageSlice";
// import messageSlice from "./MessageSlice";
// import messageSlice from "./MessageSlice";

export const getMeasurementUnitsAsync = createAsyncThunk(
  "master/getMeasurementUnitsAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`measurement-units`);
    // console.log(response.data);
    return response.data;
  }
);

export const getProgrammesAsync = createAsyncThunk(
  "master/getProgrammesAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`programmes`);
    // console.log(response.data);
    return response.data;
  }
);

export const getFormsAsync = createAsyncThunk(
  "master/getFormsAsync",
  async () => {
    // console.log("programme call");
    const response = await BaseApi().get(`forms`);
    // console.log(response.data);
    return response.data;
  }
);

export const getAcademicSessionsAsync = createAsyncThunk(
  "master/getAcademicSessionsAsync",
  async () => {
    const response = await BaseApi().get(`academic-sessions`);
    // console.log(response.data);
    return response.data;
  }
);

export const getHostelsAsync = createAsyncThunk(
  "master/getHostelsAsync",
  async () => {
    const response = await BaseApi().get(`hostels`);
    // console.log(response.data);
    return response.data;
  }
);

export const getCastesAsync = createAsyncThunk(
  "master/getCastesAsync",
  async () => {
    const response = await BaseApi().get(`castes`);
    // console.log(response.data);
    return response.data;
  }
);

export const getPaymentHeadsAsync = createAsyncThunk(
  "master/getPaymentHeadsAsync",
  async () => {
    const response = await BaseApi().get(`payment-heads`);
    // console.log(response.data);
    return response.data;
  }
);

export const addPaymentHeadAsync = createAsyncThunk(
  "master/addPaymentHeadAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("payment-heads", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addMeasurementUnitAsync = createAsyncThunk(
  "master/addMeasurementUnitAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("measurement-units", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateMeasurementUnitAsync = createAsyncThunk(
  "master/updateMeasurementUnitAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `measurement-units/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePaymentHeadAsync = createAsyncThunk(
  "master/updatePaymentHeadAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `payment-heads/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletePaymentHeadAsync = createAsyncThunk(
  "master/deletePaymentHeadAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`payment-heads/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteMeasurementUnitAsync = createAsyncThunk(
  "master/deleteMeasurementUnitAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`measurement-units/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeletePaymentHeadsAsync = createAsyncThunk(
  "master/bulkDeletePaymentHeadsAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`payment-heads/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPaymentGroupsAsync = createAsyncThunk(
  "master/getPaymentGroupsAsync",
  async () => {
    const response = await BaseApi().get(`payment-groups`);
    // console.log(response.data);
    return response.data;
  }
);

export const addPaymentGroupAsync = createAsyncThunk(
  "master/addPaymentGroupAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().post("payment-groups", payload);

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();

      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePaymentGroupAsync = createAsyncThunk(
  "master/updatePaymentGroupAsync",
  async (payload, thunkAPI) => {
    try {
      const response = await BaseApi().put(
        `payment-groups/${payload.id}`,
        payload
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deletePaymentGroupAsync = createAsyncThunk(
  "master/deletePaymentGroupAsync",
  async (payload, thunkAPI) => {
    console.log(payload);
    try {
      await BaseApi().delete(`payment-groups/${payload.id}`);
      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const bulkDeletePaymentGroupsAsync = createAsyncThunk(
  "master/bulkDeletePaymentGroupsAsync",
  async (payload, thunkAPI) => {
    try {
      await BaseApi().post(`payment-groups/bulk-delete`, payload);

      return payload;
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      thunkAPI.dispatch(messageSlice.actions.setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  programmes: [],
  forms: [],
  academicSessions: [],
  hostels: [],
  castes: [],
  paymentHeads: [],

  paymentGroups: [],
  measurementUnits: [],

  errorMessage: "",
};

const masterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {},
  extraReducers: {
    [getProgrammesAsync.fulfilled]: (state, action) => {
      return { ...state, programmes: action.payload };
    },
    [getFormsAsync.fulfilled]: (state, action) => {
      return { ...state, forms: action.payload };
    },
    [getAcademicSessionsAsync.fulfilled]: (state, action) => {
      return { ...state, academicSessions: action.payload };
    },
    [getHostelsAsync.fulfilled]: (state, action) => {
      return { ...state, hostels: action.payload };
    },
    [getCastesAsync.fulfilled]: (state, action) => {
      return { ...state, castes: action.payload };
    },
    [getPaymentHeadsAsync.fulfilled]: (state, action) => {
      return { ...state, paymentHeads: action.payload };
    },

    [addPaymentHeadAsync.fulfilled]: (state, action) => {
      state.paymentHeads.push(action.payload);
    },

    [updatePaymentHeadAsync.fulfilled]: (state, action) => {
      const index = state.paymentHeads.findIndex(
        (head) => head.id === action.payload.id
      );
      state.paymentHeads[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deletePaymentHeadAsync.fulfilled]: (state, action) => {
      let paymentHeads = state.paymentHeads.filter(
        (head) => head.id != action.payload.id
      );

      return { ...state, paymentHeads: paymentHeads };
    },
    [bulkDeletePaymentHeadsAsync.fulfilled]: (state, action) => {
      let paymentHeads = state.paymentHeads.filter((head) => {
        return !action.payload.selectedIds.includes(head.id.toString());
      });

      return { ...state, paymentHeads: paymentHeads };
    },

    [getPaymentGroupsAsync.fulfilled]: (state, action) => {
      return { ...state, paymentGroups: action.payload };
    },
    [addPaymentGroupAsync.fulfilled]: (state, action) => {
      state.paymentGroups.push(action.payload);
    },

    [updatePaymentGroupAsync.fulfilled]: (state, action) => {
      const index = state.paymentGroups.findIndex(
        (group) => group.id === action.payload.id
      );
      state.paymentGroups[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },

    [deletePaymentGroupAsync.fulfilled]: (state, action) => {
      let paymentGroups = state.paymentGroups.filter(
        (group) => group.id != action.payload.id
      );

      return { ...state, paymentGroups: paymentGroups };
    },
    [bulkDeletePaymentGroupsAsync.fulfilled]: (state, action) => {
      let paymentGroups = state.paymentGroups.filter((group) => {
        return !action.payload.selectedIds.includes(group.id.toString());
      });

      return { ...state, paymentGroups: paymentGroups };
    },

    ////

    [getMeasurementUnitsAsync.fulfilled]: (state, action) => {
      return { ...state, measurementUnits: action.payload };
    },
    [addMeasurementUnitAsync.fulfilled]: (state, action) => {
      state.measurementUnits.push(action.payload);
    },
    [updateMeasurementUnitAsync.fulfilled]: (state, action) => {
      const index = state.measurementUnits.findIndex(
        (unit) => unit.id === action.payload.id
      );
      state.measurementUnits[index] = action.payload;
      // return {...state, companies: action.payload} ;
    },
    [deleteMeasurementUnitAsync.fulfilled]: (state, action) => {
      let measurementUnits = state.measurementUnits.filter(
        (unit) => unit.id != action.payload.id
      );

      return { ...state, measurementUnits: measurementUnits };
    },
  },
});

export default masterSlice.reducer;
