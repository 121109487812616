import React from "react";
import { useNavigate } from "react-router-dom";

export default function BudgetDetailsRow({
  head,
  index,
  proposals,
  currentSession,
  budgetMaster,
  lastSession,
  last2Session,
  type,
}) {
  const navigate = useNavigate();

  console.log(head.name);
  if (head.children && head.children.length > 0) {
    let children = head.children;
    return (
      <>
        <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
          <td key={index} className="table-data">
            {head.code}
          </td>
          <td key={index} colSpan="5" className="table-data">
            {head.name}
          </td>
        </tr>
        {children.map((head1, index1) => {
          return (
            <>
              <BudgetDetailsRow
                head={head1}
                index={index1}
                proposals={proposals}
                currentSession={currentSession}
                budgetMaster={budgetMaster}
                lastSession={lastSession}
                last2Session={last2Session}
                type={type}
              />
            </>
          );
        })}
      </>
    );
  } else {
    return (
      <>
        <tr className="h-12 border-gray-300 dark:border-gray-200 border-b">
          <td className="table-data "> {head.code} </td>
          <td className="table-data ">
            <span
              className="text-blue-600 cursor-pointer"
              onClick={() => {
                console.log("ad");
                navigate(
                  `/budget-proposals/${type}/${budgetMaster.id}/${head.id}`
                );
              }}
            >
              {head.name}
            </span>{" "}
          </td>

          <td className="table-data">
            {proposals
              ?.filter(
                (p) =>
                  p.acc_payment_head_id == head.id &&
                  p.academic_session_id == last2Session.id &&
                  p.budget_type == 2
              )
              ?.reduce(
                (partialSum, proposal) =>
                  partialSum +
                  (parseInt(
                    proposal[
                      type == "receipt"
                        ? "proposed_amount_receipt"
                        : "proposed_amount_payment"
                    ]
                  ) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className="table-data">
            {proposals
              ?.filter(
                (p) =>
                  p.acc_payment_head_id == head.id &&
                  p.academic_session_id == lastSession.id &&
                  p.budget_type == 0
              )
              ?.reduce(
                (partialSum, proposal) =>
                  partialSum +
                  (parseInt(
                    proposal[
                      type == "receipt"
                        ? "proposed_amount_receipt"
                        : "proposed_amount_payment"
                    ]
                  ) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className="table-data">
            {proposals
              ?.filter(
                (p) =>
                  p.acc_payment_head_id == head.id &&
                  p.academic_session_id == lastSession.id &&
                  p.budget_type == 1
              )
              ?.reduce(
                (partialSum, proposal) =>
                  partialSum +
                  (parseInt(
                    proposal[
                      type == "receipt"
                        ? "proposed_amount_receipt"
                        : "proposed_amount_payment"
                    ]
                  ) || 0),
                0
              )
              .toFixed(2)}
          </td>
          <td className="table-data">
            {" "}
            {proposals
              ?.filter(
                (p) =>
                  p.acc_payment_head_id == head.id &&
                  p.academic_session_id == currentSession.id &&
                  p.budget_type == 0
              )
              ?.reduce(
                (partialSum, proposal) =>
                  partialSum +
                  (parseInt(
                    proposal[
                      type == "receipt"
                        ? "proposed_amount_receipt"
                        : "proposed_amount_payment"
                    ]
                  ) || 0),
                0
              )
              .toFixed(2)}
          </td>
        </tr>
      </>
    );
  }
}
