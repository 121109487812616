import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Input from "../../../Components/Utils/Input";

import { Form, Formik } from "formik";
import * as Yup from "yup";
// import BasicInput from "../../../Components/Utils/BasicInput";
// import Input from "../../../Components/Utils/Input";
import { ThreeDots } from "react-loader-spinner";

import { Link, useSearchParams } from "react-router-dom";
import messageSlice from "../../Redux/MessageSlice";
import { getAcademicSessionsAsync } from "../../Redux/MasterSlice";
import {
  getAccPaymentHeadsAsync,
  getBudgetMastersAsync,
} from "../../Redux/BudgetSlice";
import BaseApi from "../../Http/BaseApi";
import PageTitle from "../../Components/Common/PageTitle";
import Alert from "../../Components/Utils/Alert";
import Button from "../../Components/Utils/Button";
import tableToCSV from "../../Components/Utils/TableToCSV";
import FundPositionReportRow from "./Partials/FundPositionReportRow";
import CustomSelect from "../../Components/Utils/CustomSelect";

// import Input from "../../Components/Utils/Input";
// import Button from "../../Components/Utils/Button";
// import BasicInput from "../../../Components/Utils/BasicInput";

export default function FundPositionReport() {
  const type = "payment";
  const [searchParams] = useSearchParams();

  const acc_budget_form_id = searchParams.get("acc_budget_form_id");

  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);

  const [academicSession, setAcademicSession] = useState();
  const [budgets, setBudgets] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [budgetBlocks, setBudgetBlocks] = useState([]);
  const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);

  const academicSessions = [...master.academicSessions];
  const budgetMasters = [...budget.budgetMasters];
  const { message } = useSelector((state) => state.message);

  // const [formRole, setFormRole] = useState("add");

  //   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }

    // dispatch(messageSlice.actions.clearMessage());
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }
    if (accPaymentHeads.length === 0) {
      dispatch(getAccPaymentHeadsAsync());
    }
    if (budgetMasters.length === 0) {
      dispatch(getBudgetMastersAsync());
    }
    if (acc_budget_form_id) {
      setInitialValues({
        acc_budget_form_id: acc_budget_form_id,
      });
      setBudgets([]);
      callBudgets(acc_budget_form_id);
      setExpenses([]);
      callExpenses(acc_budget_form_id);

      setVouchers([]);
      callVouchers(acc_budget_form_id);
      setBudgetBlocks([]);
      callBudgetBlocks(acc_budget_form_id);
    }
    // dispatch(getOldStudentsAsync());
  }, [dispatch, acc_budget_form_id]);

  let budgetMaster = budgetMasters.find((e) => e.id == acc_budget_form_id);
  //   console.log(budgetMaster);
  const accPaymentHeads = [
    ...budget.accPaymentHeads.filter(
      (ah) =>
        ah.type == (type == "receipt" ? 1 : 2) &&
        ah.acc_budget_master_id == budgetMaster?.acc_budget_master_id
    ),
  ];

  let currentSession = academicSessions.find((e) => {
    return e.id == budgetMaster?.academic_session_id;
  });

  let lastSession = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 1;
  });

  let last2Session = academicSessions.find((as) => {
    return as.serial == currentSession?.serial - 2;
  });

  const validationSchema = Yup.object().shape({
    academic_session_id: Yup.string().required("This field is required!"),
  });

  const callBudgets = async (acc_budget_form_id) => {
    setLoading(true);
    const _budgets = await BaseApi().get(
      `acc-budgets-for-fund-position-report`,
      {
        params: {
          acc_budget_form_id: acc_budget_form_id,
          cost_center_id: "all",
        },
      }
    );

    setBudgets(_budgets.data);

    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
    });
    setLoading(false);
  };

  const callVouchers = async (acc_budget_form_id) => {
    setLoading(true);
    const _vouchers = await BaseApi().get(`budget-code-expenses`, {
      params: { acc_budget_form_id: acc_budget_form_id },
    });

    setVouchers(_vouchers.data);

    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
    });
    setLoading(false);
  };

  const callExpenses = async (acc_budget_form_id) => {
    setLoading(true);
    const _expenses = await BaseApi().get(`proposal-expenses`, {
      params: { acc_budget_form_id: acc_budget_form_id, cost_center_id: "all" },
    });

    setExpenses(_expenses.data);

    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
    });
    setLoading(false);
  };

  const callBudgetBlocks = async (acc_budget_form_id) => {
    setLoading(true);
    const _budgetBlocks = await BaseApi().get(
      `acc-budget-blocks?academic_session_id=${academicSession?.id}`
    );

    setBudgetBlocks(_budgetBlocks.data);

    setInitialValues({
      acc_budget_form_id: acc_budget_form_id,
    });
    setLoading(false);
  };

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle
              name={"Fund Utilization Report " + (currentSession?.label ?? "")}
            />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'></div>
                {loading == true && (
                  <>
                    <div className='flex'>
                      <span className='m-auto'>
                        <ThreeDots
                          height='100'
                          width='100'
                          color='grey'
                          ariaLabel='loading'
                        />
                      </span>
                    </div>
                  </>
                )}
                <div>
                  <CustomSelect
                    className='w-64 mb-5'
                    options={academicSessions}
                    name='academic_session_id'
                    label='Select Financial Year'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `  ${option.label}  `}
                    onChange={(selectedOption) => {
                      setAcademicSession(selectedOption);

                      // console.log(selectedOption.id);
                    }}
                  />
                </div>

                {academicSession &&
                  budgetMasters
                    .filter(
                      (bm) => bm?.academic_session_id == academicSession?.id
                    )
                    ?.slice() // Create a shallow copy to avoid modifying the original array
                    .sort((a, b) => {
                      // Sort the array based on the 'name' property of 'budget_master'
                      const nameA = a?.budget_master?.name || "";
                      const nameB = b?.budget_master?.name || "";
                      return nameA.localeCompare(nameB);
                    })
                    .map((budgetMaster) => {
                      return (
                        <Link
                          to={`/fund-position-report?acc_budget_form_id=${budgetMaster.id}`}
                          key={budgetMaster.id} // Add a unique key for each link
                          className={`mx-2 py-4 px-6 text-lg font-semibold text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white
          ${
            budgetMaster.id == acc_budget_form_id
              ? "bg-blue-500 text-white"
              : "text-gray-800"
          }
          `}
                        >
                          {budgetMaster?.budget_master?.name}
                        </Link>
                      );
                    })}
                {/* <h1 className='my-5 text-xl font-medium'>
                  {budgetMaster?.budget_master?.name}
                </h1> */}
                {acc_budget_form_id && (
                  <>
                    <Button
                      type='button'
                      classNames='float-right bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                      value={"Download CSV"}
                      onClick={() => tableToCSV("datatable")}
                      icon={"DownloadIcon"}
                    />
                    <div className='block table-wrp max-h-96'>
                      <table
                        id='datatable'
                        className='w-full min-w-full mt-5 bg-white dark:bg-gray-800'
                      >
                        <thead className='sticky top-0 bg-white border-b'>
                          <tr className='h-12 border-b border-gray-300 dark:border-gray-200'>
                            <th className='table-head'>Ledger Code</th>
                            <th className='table-head'>Heads</th>

                            <th className='table-head'>
                              Budget {currentSession?.label}
                            </th>
                            <th className='table-head'>
                              Rev. Budget {currentSession?.label}
                            </th>

                            <th className='table-head'>
                              Expenditure of Prev. F.Y.
                            </th>
                            <th className='table-head'>Unpaid Orders</th>
                            <th className='table-head'>Paid</th>
                            <th className='table-head'>Clearance given</th>
                            <th className='table-head'>Total</th>
                            <th className='table-head'>Balance</th>
                          </tr>
                        </thead>
                        <tbody className='overflow-y-auto h-96'>
                          {accPaymentHeads.map((head, index) => {
                            return (
                              <>
                                <FundPositionReportRow
                                  head={head}
                                  index={index}
                                  budgets={budgets}
                                  vouchers={vouchers}
                                  expenses={expenses}
                                  budgetBlocks={budgetBlocks}
                                  currentSession={currentSession}
                                  budgetMaster={budgetMaster}
                                  lastSession={lastSession}
                                  last2Session={last2Session}
                                  type={type}
                                />
                              </>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr className='hidden h-12 border-b border-gray-300 dark:border-gray-200'>
                            <th className='table-head'></th>
                            <th className='table-head'>Total</th>
                            <th className='table-head'>
                              {budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id ==
                                      currentSession.id &&
                                    p.budget_type == 0 &&
                                    p.draft == 0
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(
                                      budget[
                                        type == "receipt"
                                          ? "receipt_amount"
                                          : "payment_amount"
                                      ]
                                    ) || 0),
                                  0
                                )
                                .toFixed(2)}
                            </th>

                            <th className='table-head'>
                              {budgets
                                .filter(
                                  (p) =>
                                    p.academic_session_id ==
                                      currentSession.id && p.budget_type == 1
                                )
                                ?.reduce(
                                  (partialSum, budget) =>
                                    partialSum +
                                    (parseInt(
                                      budget[
                                        type == "receipt"
                                          ? "receipt_amount"
                                          : "payment_amount"
                                      ]
                                    ) || 0),
                                  0
                                )
                                .toFixed(2)}
                            </th>

                            <th className='table-head'></th>
                            <th className='table-head'></th>
                            <th className='table-head'></th>
                            <th className='table-head'></th>
                            <th className='table-head'></th>
                            <th className='table-head'></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </>
                )}
                {!acc_budget_form_id && (
                  <div className='my-10 ml-8 text-red-500 '>
                    Please select a part
                  </div>
                )}
                <hr className='m-10' />
              </Form>
            </Formik>

            <br />
          </div>
        </div>
      </div>
    </>
  );
}
