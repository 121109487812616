import moment from "moment";
import React from "react";
import ValidationErrors from "../../../../Components/Utils/ValidationErrors";
// import moment from "moment";

export default function ProposalEntryPreview({
  selectedTaxType,
  costCenters,
  selectedProposalNature,
  itemCategories,
  data,
  setShowPreview,
  loading,
  validationErrors,
}) {
  console.log(data);
  console.log(setShowPreview);
  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <div className='relative lg:w-full	 my-6 mx-auto max-w-4xl'>
          {/*content*/}
          <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            {/*header*/}

            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
              <h3 className='text-3xl font-semibold'>Preview</h3>

              <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className='mx-5'>
              {" "}
              <ValidationErrors className='my-5' errors={validationErrors} />
            </div>

            <div className='relative   p-4 flex-auto'>
              <div className='my-1 text-left  text-sm '>
                <div className='flex justify-between mb-5'>
                  <div>
                    <p className='text-gray-700 font-semibold'>
                      Proposal Nature: {selectedProposalNature?.name}
                    </p>
                    <p className='text-gray-700  font-semibold'>
                      Cost Center:
                      {
                        costCenters.find(
                          (cc) => cc.id == data.acc_cost_center_id
                        )?.name
                      }
                    </p>
                    <p className='text-gray-700 font-semibold'>
                      Proposal Date:
                      {moment(data.proposed_date).format("Do MMM YYYY")}
                    </p>
                  </div>
                  <div>
                    <p className='text-gray-700 font-semibold'>
                      Manual Ref. Number: {data.manual_ref_no}
                    </p>{" "}
                    <p className='text-gray-700  font-semibold'>
                      Necessity: {data.necessity}
                    </p>
                    <p className='text-gray-700  font-semibold'>
                      Remarks: {data.remarks}
                    </p>
                  </div>
                </div>
                <table className='w-full mb-5'>
                  <thead>
                    <tr className='border-b border-gray-500'>
                      <th className='text-left pb-3 font-semibold'>
                        Item Category
                      </th>
                      <th className='text-left pb-3 font-semibold'>Item</th>
                      <th className='text-left pb-3 font-semibold'>Quantity</th>
                      <th className='text-left pb-3 font-semibold'>Rate</th>
                      <th className='text-left pb-3 font-semibold'>Amount</th>
                      <th className='text-left pb-3 font-semibold'>TAX (%)</th>
                      <th className='text-left pb-3 font-semibold'>Amount</th>
                      <th className='text-right pb-3 font-semibold'>
                        Total Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items?.map((item, index) => (
                      <>
                        {" "}
                        <tr key={index} className=''>
                          <td className='text-left py-3'>
                            {
                              itemCategories.find(
                                (ic) => ic.id == item.item_category_id
                              )?.name
                            }
                          </td>
                          <td className='text-left py-3'>{item.item_name}</td>
                          <td className='text-left py-3'>{item.quantity}</td>
                          <td className='text-left py-3'>
                            {parseFloat(item.rate).toFixed(2)}
                          </td>
                          <td className='text-left py-3'>
                            {parseFloat(item.amount).toFixed(2)}
                          </td>
                          <td className='text-left py-3'>
                            {selectedTaxType.id == 1 && "Tax Incl."}
                            {selectedTaxType.id == 2 && "Tax Excl."}
                            {selectedTaxType.id == 3 &&
                              parseFloat(item.tax).toFixed(2)}
                          </td>
                          <td className='text-left py-3'>
                            {parseFloat(item.amount).toFixed(2)}
                          </td>
                          <td className='text-right py-3'>
                            {parseFloat(item.total_amount).toFixed(2)}
                          </td>
                        </tr>
                        <tr key={index} className='border-b border-gray-500'>
                          <td className='text-left py-3'></td>
                          <td colSpan={7} className='text-left py-3'>
                            {item.description}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className='border-b border-gray-500'>
                      <td colSpan='6'></td>
                      <td className='text-left py-3'>Total Amount:</td>
                      <td className='text-right py-3'>
                        {" "}
                        {data?.items
                          ?.reduce((accumulator, item) => {
                            return accumulator + parseFloat(item.amount);
                          }, 0)
                          .toFixed(2)}
                      </td>
                      <td></td>
                    </tr>

                    {selectedTaxType.id == 3 && (
                      <tr className='border-b border-gray-500'>
                        <td colSpan='6'></td>
                        <td className='text-left py-3 font-semibold'>
                          Total Amount with Tax:
                        </td>
                        <td className='text-right py-3 font-semibold'>
                          {data?.items
                            ?.reduce((accumulator, item) => {
                              return (
                                accumulator + parseFloat(item.total_amount)
                              );
                            }, 0)
                            .toFixed(2)}
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tfoot>
                </table>
                {selectedTaxType.id == 1 && (
                  <p className='text-right text-sm'>
                    Amout is inclusive of Tax
                  </p>
                )}
                {selectedTaxType.id == 2 && (
                  <p className='text-right text-sm'>
                    Amout is exclusive of Tax
                  </p>
                )}
              </div>
            </div>
            {/*footer*/}
            <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
              <button
                className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                type='button'
                onClick={() => setShowPreview(false)}
              >
                Close
              </button>
              <button
                disabled={loading}
                className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                type='submit'
              >
                {loading ? "Saving..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
    </>
  );
}
