import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../../Components/Common/PageTitle";
import Input from "../../../Components/Utils/Input";
import Button from "../../../Components/Utils/Button";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import Alert from "../../../Components/Utils/Alert";
import messageSlice from "../../../Redux/MessageSlice";
import {
  addBudgetMasterAsync,
  deleteBudgetMasterAsync,
  getBudgetMastersAsync,
  getBudgetSuperMastersAsync,
  updateBudgetMasterAsync,
} from "../../../Redux/BudgetSlice";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import { getAcademicSessionsAsync } from "../../../Redux/MasterSlice";

export default function BudgetMaster() {
  const dispatch = useDispatch();

  const formikRef = useRef();

  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ state: 0 });

  const { message } = useSelector((state) => state.message);

  const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);
  const budgetMasters = [...budget.budgetMasters];
  const budgetSuperMasters = [...budget.budgetSuperMasters];

  const [formRole, setFormRole] = useState("add");
  const academicSessions = [...master.academicSessions];

  //   const dispatch = useDispatch();
  useEffect(() => {
    if (academicSessions.length === 0) {
      dispatch(getAcademicSessionsAsync());
    }

    dispatch(messageSlice.actions.clearMessage());
    // if (!can("View Attendance Sheet")) {
    //   navigate(`/not-found`);
    // }
    setInitialValues({
      id: null,
      acc_budget_master_id: "",
      academic_session_id: "",
      from_date: "",
      to_date: "",
    });

    // dispatch(messageSlice.actions.clearMessage());
    if (budgetMasters.length === 0) {
      dispatch(getBudgetMastersAsync());
    }

    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }

    // dispatch(getOldStudentsAsync());
  }, [dispatch]);

  const onEdit = (event, budgetMaster) => {
    console.log(budgetMaster);
    event.preventDefault();
    setInitialValues({
      id: budgetMaster.id,
      acc_budget_master_id: budgetMaster.acc_budget_master_id,
      academic_session_id: budgetMaster.academic_session_id,
      from_date: budgetMaster.from_date,
      to_date: budgetMaster.to_date,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setFormRole("edit");
  };

  const validationSchema = Yup.object().shape({
    acc_budget_master_id: Yup.string().required("This field is required!"),
    academic_session_id: Yup.string().required("This field is required!"),
  });

  const handleSubmit = async (payload, { resetForm }) => {
    dispatch(messageSlice.actions.clearMessage());
    setLoading(true);
    console.log(formRole);
    if (formRole === "edit") {
      await dispatch(updateBudgetMasterAsync(payload)).unwrap();
    } else {
      await dispatch(addBudgetMasterAsync(payload)).unwrap();
    }

    setLoading(false);
    setFormRole("add");
    resetForm();
    setInitialValues({
      id: null,
      acc_budget_master_id: "",
      academic_session_id: "",
      from_date: "",
      to_date: "",
    });
  };
  const handleDelete = async (event, payload) => {
    event.target.disabled = true;
    dispatch(messageSlice.actions.clearMessage());
    if (deleteConfirm.state == 1) {
      await dispatch(deleteBudgetMasterAsync(payload));
    }
    event.target.disabled = false;
    setDeleteConfirm({});
  };

  // const handleCheckbox = (event) => {
  //   const target = event.target;
  //   var value = target.value;
  //   if (target.checked) {
  //     setSelectedIds([...selectedIds, value]);
  //   } else {
  //     var index = selectedIds.indexOf(value);
  //     if (index != -1) {
  //       setSelectedIds(selectedIds.filter((item) => item != value));
  //     }
  //   }
  // };

  // console.log(selectedIds);
  // const handleBulkDelete = async (event) => {
  //   event.preventDefault();
  //   event.target.disabled = true;

  //   await dispatch(bulkDeleteAccPaymentHeadsAsync({ selectedIds })).unwrap();
  //   setSelectedIds([]);
  //   event.target.disabled = false;
  // };

  console.log(message);
  let thClass =
    " text-gray-600   border p-2  whitespace-nowrap dark:text-gray-400 font-bold pr-6 text-left text-sm tracking-normal leading-4";

  let tdClass =
    " text-gray-600 border p-2   dark:text-gray-400 font-normal pr-6 text-left text-sm m-4 tracking-normal leading-4";

  return (
    <>
      <div className='py-2'>
        <div className='container min-h-screen mx-auto bg-white rounded shadow dark:bg-gray-800'>
          <div className='w-full px-5 '>
            <PageTitle name='Budget Masters' />
            {message && <Alert message={message} />}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className='grid gap-8 py-5 pt-6  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
                  <CustomSelect
                    options={budgetSuperMasters}
                    name='acc_budget_master_id'
                    label='Select Type'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "acc_budget_master_id",
                        selectedOption.id
                      );
                    }}
                  />
                  <CustomSelect
                    options={academicSessions}
                    name='academic_session_id'
                    label='Financial Year'
                    isSearchable={true}
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.label} `}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "academic_session_id",
                        selectedOption.id
                      );
                    }}
                  />
                  <Input
                    label='Submission Open - From Date'
                    name='from_date'
                    type='date'
                    placeholder='Select Date'
                  />
                  <Input
                    label='Submission Open - To Date'
                    name='to_date'
                    type='date'
                    placeholder='Select Date'
                  />

                  <div className='mt-5 text-left'>
                    {formRole === "add" ? (
                      <Button
                        value={loading ? "Saving..." : "Save"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <Button
                        value={loading ? "Updating..." : "Update"}
                        icon={"SaveIcon"}
                        onClick={handleSubmit}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </Formik>

            <br />
            <table
              id='datatable'
              className='min-w-full bg-white dark:bg-gray-800'
            >
              <thead>
                <tr>
                  <th className={thClass + "w-16"}>#</th>
                  <th className={thClass}>Budget Type</th>
                  <th className={thClass}>Financial Year</th>
                  <th className={thClass}>Submission Open - From Date</th>
                  <th className={thClass}>Submission Open - To Date</th>

                  <th className={thClass}>Action</th>
                </tr>
              </thead>
              <tbody>
                {budgetMasters.length > 0 &&
                  budgetMasters.map((budget, i) => {
                    return (
                      <tr
                        key={budget.id}
                        className='odd:bg-white even:bg-gray-50'
                      >
                        <td className={tdClass + "w-32"}>{++i}</td>
                        <td className={tdClass}>
                          {budget.budget_master?.name}
                        </td>
                        <td className={tdClass}>
                          {budget.academic_session.label}
                        </td>
                        <td className={tdClass}>{budget.from_date}</td>
                        <td className={tdClass}>{budget.to_date}</td>

                        <td className={tdClass}>
                          <span
                            className='px-2 py-1 ml-5 font-light text-white rounded  bg-gradient-to-r from-blue-500 to-yellow-500 hover:bg-blue-700'
                            onClick={(event) => onEdit(event, budget)}
                          >
                            Edit
                          </span>
                          {(deleteConfirm.state == 0 ||
                            deleteConfirm.itemId != budget.id) && (
                            <span
                              className='px-2 py-1 ml-5 font-light text-white rounded bg-gradient-to-r from-red-500 to-yellow-500 hover:bg-red-700'
                              onClick={() =>
                                setDeleteConfirm({
                                  itemId: budget.id,
                                  state: 1,
                                })
                              }
                            >
                              Delete
                            </span>
                          )}
                          {deleteConfirm.state == 1 &&
                            deleteConfirm.itemId == budget.id && (
                              <>
                                <p className='text-red-700'> Are You Sure? </p>

                                <button
                                  className='outline-button-danger'
                                  onClick={(event) =>
                                    handleDelete(event, budget)
                                  }
                                >
                                  Yes
                                </button>
                                <span className='mr-2'></span>
                                <button
                                  className='outline-button-success'
                                  onClick={() => setDeleteConfirm({})}
                                >
                                  No
                                </button>
                              </>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
