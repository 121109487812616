import { ErrorMessage } from "formik";
import React, { useState } from "react";

export default function Upload(props) {
  const [image, setImage] = useState(null);
  const [mimeType, setMimeType] = useState(null);

  return (
    <>
      <div
        className={`flex  items-center   bg-grey-lighter  ${props.className}`}
      >
        <label
          className='w-64 flex flex-col items-center px-4 py-6
         bg-white text-blue rounded-lg   tracking-wide   border border-blue cursor-pointer'
        >
          <small> {props.label}</small>
          <svg
            className='w-8 h-8 hover:text-indigo-600'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
          >
            <path d='M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z' />
          </svg>
          <span className='mt-2 text-base leading-normal'>Select a file</span>
          <input
            id={props.id}
            name={props.name}
            type='file'
            accept='image/jpeg, image/png, application/pdf'
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.onload = (event) => {
                setImage(event.target.result);
                setMimeType(
                  event.target.result.split(",")[0].split(":")[1].split(";")[0]
                );
              };
              reader.readAsDataURL(file);

              props.onChange(e);
            }}
            className='hidden'
          />
          <div className='text-xs text-center'>
            {mimeType?.startsWith("image") && (
              <img className='w-32 rounded-md shadow-md mt-4' src={image} />
            )}
            {mimeType?.startsWith("application") && (
              <span className='bg-green-100 text-green-700 px-5 py-1'>
                Selected
              </span>
            )}
            {props.defaultImage && image == null && (
              <img
                className='w-32 rounded-md shadow-md mt-4'
                src={props.defaultImage}
              />
            )}
          </div>

          <ErrorMessage
            name={props.name}
            component='div'
            className='text-red-500 py-2'
          />
        </label>
      </div>
    </>
  );
}
