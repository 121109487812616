import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import BaseApi from "../../../../Http/BaseApi";
import Input from "../../../../Components/Utils/Input";
import SearchableDropdownVendor from "../../../../Components/Utils/SearchableDropdownVendor";
import { PlusIcon } from "@heroicons/react/outline";
import VendorEntry from "../../Vendor/VendorEntry";
import ValidationErrors from "../../../../Components/Utils/ValidationErrors";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import { getBudgetSuperMastersAsync } from "../../../../Redux/BudgetSlice";
export default function EditProposal({
  selectedProposal,

  handleEditPopupClose,
}) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const inputRefVendor = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [vendorPopup, setVendorPopup] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const validationSchema = Yup.object().shape({
    // order_file: Yup.string().required("This field is required!"),
  });

  const budget = useSelector((state) => state.budget);

  const budgetSuperMasters = [...budget.budgetSuperMasters];
  const [accPaymentHeads, setAccPaymentHeads] = useState([]);
  const [loading, seLoading] = useState(false);
  const [accPaymentHeadName, setAccPaymentHeadName] = useState("");

  useEffect(() => {
    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }
    setInitialValues({
      order_date: selectedProposal.order_date,
      manual_order_no: selectedProposal.manual_order_no,
      vendor_id: selectedProposal.vendor_id,
      delivery_date: selectedProposal.delivery_date,
      remarks: selectedProposal.remarks,
      acc_budget_master_id: selectedProposal.acc_budget_master_id,
      acc_payment_head_id: selectedProposal.acc_payment_head_id,

      proposal_id: selectedProposal?.id,
    });

    callAccHeads(selectedProposal.acc_budget_master_id);
  }, [dispatch]);

  const callAccHeads = async (acc_budget_master_id) => {
    seLoading(true);
    BaseApi()
      .get(
        `/acc-payment-heads-with-amount?acc_budget_master_id=${acc_budget_master_id}`
      )
      .then((response) => {
        console.log(response.data);
        setAccPaymentHeads(response.data);
        seLoading(false);

        setAccPaymentHeadName(
          response.data.find(
            (head) => head.id == selectedProposal?.acc_payment_head_id
          )?.name ?? " "
        );
      })
      .catch((error) => {
        console.log("error is", error);
        seLoading(false);
      });
  };

  const handleSubmit = async (payload) => {
    BaseApi()
      .put(`/proposals/${selectedProposal?.id}`, payload)
      .then(() => {
        handleEditPopupClose();
        // BaseApi()
        //   .get(`/proposals?status=1`)
        //   .then(() => {
        //     // console.log('data is', response.data)
        //   })
        //   .catch((error) => {
        //     console.log("error is", error);
        //   });
        // console.log('data is', response.data)
      })
      .catch((error) => {
        console.log("error is", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setErrorMessage(message);
        setValidationErrors(error.response.data.errors);
      });
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div
            className={
              "justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none" +
              (vendorPopup ? " hidden" : "")
            }
          >
            <div className='relative lg:w-2/6	 my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>
                    Edit Proposal/Order
                  </h3>
                  <button className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'>
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative   p-4 flex-auto'>
                  <div className='my-1    text-sm '>
                    <div className='relative p-2 flex-auto'>
                      {errorMessage && (
                        <p className='text-red-500 mb-4'>{errorMessage}</p>
                      )}
                      <ValidationErrors
                        className='my-5'
                        errors={validationErrors}
                      />
                      <div>Proposal ID: {selectedProposal.proposal_id}</div>
                      <div>
                        Cost Center: {selectedProposal.acc_cost_center?.name}
                      </div>
                      <br />
                      <Input type='hidden' name='proposal_id' />
                      {selectedProposal.vendor_id && (
                        <>
                          <div className='float-right '>
                            <button
                              className='flex items-center text-blue-500'
                              type='button'
                              onClick={() => {
                                setVendorPopup(true);
                              }}
                            >
                              Create new vendor{" "}
                              <PlusIcon className='w-4 ml-1' />
                            </button>
                          </div>

                          <SearchableDropdownVendor
                            reference={inputRefVendor}
                            name={`item_id`}
                            defaultOption={{
                              id: selectedProposal.vendor.id ?? "",
                              name: selectedProposal.vendor.name ?? "",
                            }}
                            label='Select Vendor'
                            placeholder='Search...'
                            options={[]}
                            setSelectedOption={(selectedOption) => {
                              formikRef.current?.setFieldValue(
                                "vendor_id",
                                selectedOption.id
                              );
                            }}
                          />
                        </>
                      )}
                      <br />
                      {selectedProposal.order_date && (
                        <Input
                          label='Order Date'
                          type='date'
                          name='order_date'
                          placeholder='Order Date'
                        />
                      )}
                      <br />
                      {selectedProposal.manual_order_no && (
                        <Input
                          label='Enter Manual Order Number'
                          type='text'
                          name='manual_order_no'
                          placeholder='Enter Manual Order Number'
                        />
                      )}
                      <br />
                      {selectedProposal.delivery_date && (
                        <Input
                          label='Enter Delivery Date'
                          type='date'
                          name='delivery_date'
                          placeholder='Enter Delivery Date'
                        />
                      )}

                      <br />

                      {}
                      {budgetSuperMasters &&
                        budgetSuperMasters.find(
                          (budget) =>
                            budget.id == initialValues?.acc_budget_master_id
                        )?.name &&
                        selectedProposal.acc_payment_head_id && (
                          <>
                            <CustomSelect
                              options={budgetSuperMasters}
                              defaultInputValue={
                                budgetSuperMasters.find(
                                  (budget) =>
                                    budget.id ==
                                    initialValues?.acc_budget_master_id
                                )?.name
                              }
                              name='acc_budget_master_id'
                              label='Select Budget Master'
                              isSearchable={true}
                              getOptionValue={(option) => `${option}`}
                              getOptionLabel={(option) => `${option.name} `}
                              onChange={(selectedOption) => {
                                console.log(selectedOption);
                                formikRef.current?.setFieldValue(
                                  "acc_budget_master_id",
                                  selectedOption?.id
                                );
                                formikRef.current?.setFieldValue(
                                  "acc_payment_head_id",
                                  null
                                );

                                //   setSelectedBM(selectedOption);
                                callAccHeads(selectedOption.id);
                              }}
                            />

                            {accPaymentHeads && accPaymentHeadName && (
                              <CustomSelect
                                options={accPaymentHeads}
                                defaultInputValue={accPaymentHeadName}
                                name='acc_payment_head_id'
                                label={`Select Payment Head ${
                                  loading ? "&nbsp;&nbsp;&nbsp;Loading..." : ""
                                }`}
                                isSearchable={true}
                                getOptionValue={(option) => `${option}`}
                                getOptionLabel={(option) =>
                                  (option.code ? option.code : "") +
                                  "-" +
                                  option.name +
                                  (option.balance
                                    ? " (Rs. " + option.balance + ")"
                                    : "")
                                }
                                onChange={(selectedOption) => {
                                  console.log(selectedOption);
                                  formikRef.current?.setFieldValue(
                                    "acc_payment_head_id",
                                    selectedOption.id
                                  );
                                }}
                              />
                            )}
                          </>
                        )}
                      <br />
                      <Input type='text' name='remarks' label='Remarks' />
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handleEditPopupClose}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className='opacity-25 fixed inset-0 z-30 bg-black'></div>

      {vendorPopup && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative  lg:w-2/3	 my-6 mx-auto max-w-3xl'>
              {/*content*/}

              <div className='border-0 p-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <button
                  className='absolute top-0 right-0 m-4'
                  onClick={() => {
                    setVendorPopup(false);
                  }}
                >
                  X
                </button>
                <VendorEntry setVendorPopup={setVendorPopup} />
              </div>
            </div>
          </div>

          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </>
  );
}
