import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageTitle from "../../../Components/Common/PageTitle";
import Badge from "../../../Components/Utils/Badge";
import Button from "../../../Components/Utils/Button";
import CustomSelect from "../../../Components/Utils/CustomSelect";
import BaseApi from "../../../Http/BaseApi";
import {
  getAccPaymentHeadsAsync,
  getBudgetSuperMastersAsync,
  getCostCentersAsync,
} from "../../../Redux/BudgetSlice";

export default function AssignBudgetHeads() {
  const dispatch = useDispatch();
  const { type } = useParams();
  const formikRef = useRef();

  const [formFields, setFormFields] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [searchInputHead, setSearchInputHead] = useState("");
  // const master = useSelector((state) => state.master);
  const budget = useSelector((state) => state.budget);
  const heads = [
    ...budget.accPaymentHeads.filter(
      (ah) => ah.type == (type == "receipt" ? 1 : 2)
    ),
  ];
  const budgetSuperMasters = [...budget.budgetSuperMasters];

  const costCenters = [...budget.costCenters];
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    dispatch(getAccPaymentHeadsAsync());

    dispatch(getCostCentersAsync());

    setFormFields({
      cost_center_ids: [],
      head_ids: [],
      type: type == "receipt" ? 1 : 2,
    });
    if (budgetSuperMasters.length === 0) {
      dispatch(getBudgetSuperMastersAsync());
    }
    setInitialValues({
      acc_budget_master_id: "",
    });
  }, [dispatch, type]);

  const handleCostCenterInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    console.log(target.checked);
    if (target.checked) {
      let _formFields = { ...formFields };

      _formFields.cost_center_ids.push(value);
      setFormFields(_formFields);
    } else {
      let _formFields = { ...formFields };
      var index = _formFields.cost_center_ids.indexOf(value);
      if (index !== -1) {
        _formFields.cost_center_ids.splice(index, 1);
      }
      setFormFields(_formFields);
    }
    console.log(" a " + formFields?.cost_center_ids?.includes(value) + " ");
  };
  const handleHeadInputChange = (event) => {
    const target = event.target;
    var value = target.value;
    if (target.checked) {
      let _formFields = { ...formFields };
      _formFields.head_ids.push(value);
      setFormFields(_formFields);
    } else {
      let _formFields = { ...formFields };
      var index = _formFields.head_ids.indexOf(value);
      if (index !== -1) {
        _formFields.head_ids.splice(index, 1);
      }
      setFormFields(_formFields);
    }
    console.log(formFields);
  };

  const saveData = async (e) => {
    e.preventDefault();

    await BaseApi().post(`cost-centers/assign-budget-heads`, formFields);
    setFormFields({
      cost_center_ids: [],
      head_ids: [],
      type: type == "receipt" ? 1 : 2,
    });
    dispatch(getCostCentersAsync());
  };

  const removePermission = async (cost_center_id, head_id) => {
    let payload = {
      cost_center_id: cost_center_id,
      head_id: head_id,
    };
    console.log(payload);
    await BaseApi().post(`cost-centers/remove-budget-heads`, payload);
    dispatch(getCostCentersAsync());
  };
  return (
    <>
      <PageTitle name={"Assign " + type + " Heads to Cost Center"} />
      <hr />
      <br />
      <div className="mt-1 sm:mt-0 shadow-2xl ">
        <div className="    ">
          <div className="shadow  overflow-hidden sm:rounded-md">
            <div className="shadow overflow-hidden px-10 py-5 min-h-screen border-b border-gray-200 sm:rounded-lg">
              <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
              >
                <Form>
                  <div className=" py-1 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 pt-6 gap-8">
                    <CustomSelect
                      options={budgetSuperMasters}
                      name="acc_budget_master_id"
                      label="Select Budget Master"
                      isSearchable={true}
                      getOptionValue={(option) => `${option}`}
                      getOptionLabel={(option) => `${option.name} `}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        formikRef.current?.setFieldValue(
                          "acc_budget_master_id",
                          selectedOption.id
                        );
                        setInitialValues({
                          acc_budget_master_id: selectedOption.id,
                        });
                      }}
                    />
                  </div>
                </Form>
              </Formik>

              {initialValues?.acc_budget_master_id && (
                <div className="grid grid-cols-2 gap-4">
                  <div className="">
                    <h3>Cost Centers</h3>

                    {costCenters?.length > 0 && (
                      <>
                        <input
                          className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-2
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                          placeholder="Search..."
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </>
                    )}

                    <div className="flex justify-center mt-5">
                      <ul className="bg-white rounded-lg border border-gray-200 w-full text-gray-900">
                        {costCenters
                          .filter((s) => {
                            if (searchInput.length > 0) {
                              return (
                                s.name
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(searchInput.toLowerCase()) > -1
                              );
                            } else {
                              return true;
                            }
                          })
                          .map((costcenter) => {
                            return (
                              <li
                                key={costcenter.id}
                                className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg"
                              >
                                <input
                                  className="form-check-input   h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                  type="checkbox"
                                  checked={formFields?.cost_center_ids?.includes(
                                    costcenter.id.toString()
                                  )}
                                  value={costcenter.id}
                                  onChange={(event) =>
                                    handleCostCenterInputChange(event)
                                  }
                                  id={"user-id" + costcenter.id}
                                />
                                <label
                                  className="form-check-label inline-block text-gray-800"
                                  htmlFor={"user-id" + costcenter.id}
                                >
                                  {costcenter.name}
                                </label>
                                <hr />
                                <div className="grid grid-cols-3 gap-2">
                                  {costcenter.acc_payment_heads
                                    .filter(
                                      (ah) =>
                                        ah.pivot.type ==
                                          (type == "receipt" ? 1 : 2) &&
                                        ah.acc_budget_master_id ==
                                          initialValues.acc_budget_master_id
                                    )
                                    ?.map((head) => {
                                      return (
                                        <div key={head.id}>
                                          <Badge
                                            status={
                                              head.name + " - " + head.code
                                            }
                                            icon="XIcon"
                                            iconClick={() => {
                                              removePermission(
                                                costcenter.id,
                                                head.id
                                              );
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  <div className="">
                    <h3>Budget Heads</h3>
                    {heads?.length > 0 && (
                      <>
                        <input
                          className="  form-control
                  block
                  w-1/3
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-2
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
               "
                          placeholder="Search..."
                          onChange={(e) => setSearchInputHead(e.target.value)}
                        />
                      </>
                    )}
                    <div className="flex justify-center mt-5">
                      <ul className="bg-white rounded-lg border border-gray-200 w-full text-gray-900">
                        {heads
                          .filter((p) => {
                            if (searchInputHead.length > 0) {
                              return (
                                p.name
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(searchInputHead.toLowerCase()) > -1
                              );
                            } else {
                              return true;
                            }
                          })
                          .filter(
                            (h) =>
                              h.acc_budget_master_id ==
                              initialValues.acc_budget_master_id
                          )
                          .map((head) => {
                            return (
                              <li
                                key={head.id}
                                className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg"
                              >
                                {head?.children?.length == 0 && (
                                  <input
                                    className="form-check-input   h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="checkbox"
                                    checked={formFields?.head_ids?.includes(
                                      head.id.toString()
                                    )}
                                    value={head.id}
                                    onChange={(event) =>
                                      handleHeadInputChange(event)
                                    }
                                    id={"head-id" + head.id}
                                  />
                                )}
                                <label
                                  className="form-check-label inline-block text-gray-800"
                                  htmlFor={"head-id" + head.id}
                                >
                                  {head.name} - {head.code}
                                  <ul>
                                    {head.children
                                      ?.filter((c) => {
                                        if (searchInputHead.length > 0) {
                                          return (
                                            c.name
                                              .toString()
                                              .toLowerCase()
                                              .indexOf(
                                                searchInputHead.toLowerCase()
                                              ) > -1
                                          );
                                        } else {
                                          return true;
                                        }
                                      })
                                      .map((child) => {
                                        return (
                                          <li
                                            key={child.id}
                                            className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg"
                                          >
                                            {child?.children?.length == 0 && (
                                              <input
                                                className="form-check-input   h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox"
                                                checked={formFields?.head_ids?.includes(
                                                  child.id.toString()
                                                )}
                                                value={child.id}
                                                onChange={(event) =>
                                                  handleHeadInputChange(event)
                                                }
                                                id={"head-id" + child.id}
                                              />
                                            )}
                                            <label
                                              className="form-check-label inline-block text-gray-800"
                                              htmlFor={"head-id" + child.id}
                                            >
                                              {child.name} -{child.code}
                                              <ul>
                                                {child.children
                                                  ?.filter((g) => {
                                                    if (
                                                      searchInputHead.length > 0
                                                    ) {
                                                      return (
                                                        g.name
                                                          .toString()
                                                          .toLowerCase()
                                                          .indexOf(
                                                            searchInputHead.toLowerCase()
                                                          ) > -1
                                                      );
                                                    } else {
                                                      return true;
                                                    }
                                                  })
                                                  .map((grandchild) => {
                                                    return (
                                                      <li
                                                        key={grandchild.id}
                                                        className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg"
                                                      >
                                                        <input
                                                          className="form-check-input   h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                          type="checkbox"
                                                          checked={formFields?.head_ids?.includes(
                                                            grandchild.id.toString()
                                                          )}
                                                          value={grandchild.id}
                                                          onChange={(event) =>
                                                            handleHeadInputChange(
                                                              event
                                                            )
                                                          }
                                                          id={
                                                            "head-id" +
                                                            grandchild.id
                                                          }
                                                        />

                                                        <label
                                                          className="form-check-label inline-block text-gray-800"
                                                          htmlFor={
                                                            "head-id" +
                                                            grandchild.id
                                                          }
                                                        >
                                                          {grandchild.name} --
                                                          {grandchild.code}
                                                          <ul></ul>
                                                        </label>
                                                      </li>
                                                    );
                                                  })}
                                              </ul>
                                            </label>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-span-2 ">
                    <Button value="Save" icon={"SaveIcon"} onClick={saveData} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
